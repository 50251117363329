import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitNTNFile } from '../../../services/NTNServices';
import {
  NtnAttachBtn,
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';
import NtnContainer from '../NtnContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AxiosCall from '../../../api/AxiosCall';

const initStat = {
  monthlySalary: '',
  noOfMonth: '',
};

const SalaryFile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const [data, setData] = useState(initStat);
  const [file, setFile] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setData(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Salary</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            if (Object.values(data).every((i) => i) && file != null) {
              data['TotalSalary'] =
                parseInt(data.monthlySalary) * parseInt(data.noOfMonth);
              dispatch(
                submitNTNFile(
                  data,
                  file,
                  setData,
                  setFile,
                  user,
                  initStat,
                  'Salary File',
                  false,
                  navigate
                )
              );
            } else {
              alert('Please fill all the information before submit');
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
              paddingBottom: '1em',
              borderBottom: '1px solid #373f68',
            }}
          >
            {/* <span>Generate your NTN within minutes</span> */}
            <NtnFieldContainer>
              <NtnFormLabel
                required
                style={{
                  width: '200px',
                }}
                htmlFor='name'
              >
                1. Monthly Salary
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                type={'number'}
                placeholder='Enter your monthly salary here'
                value={data.monthlySalary}
                onChange={(e) =>
                  setData({ ...data, monthlySalary: e.target.value })
                }
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel
                required
                style={{
                  width: '200px',
                }}
                htmlFor='name'
              >
                2. Number of Months
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                type={'number'}
                placeholder='Enter your month no here'
                value={data.noOfMonth}
                onChange={(e) =>
                  setData({ ...data, noOfMonth: e.target.value })
                }
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel
                required
                style={{
                  width: '200px',
                }}
                htmlFor='name'
              >
                3. Employee Tax Certificate
              </NtnFormLabel>
              <input
                required
                type='file'
                id='file'
                hidden
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  setFile(e.target.files[0]);
                }}
              />
              <NtnFormLabel htmlFor='file'>
                <NtnAttachBtn>Attach certificate</NtnAttachBtn>
              </NtnFormLabel>
              <p>{file?.name}</p>
            </NtnFieldContainer>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '60%',
              alignSelf: 'center',
            }}
          >
            <h2>Total Income</h2>
            <h2>
              {data.monthlySalary && data.noOfMonth
                ? `${parseInt(data.monthlySalary) * parseInt(data.noOfMonth)}`
                : '-----'}
            </h2>
          </div>
          <NtnSubmitBtn type='submit'>Proceed</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default SalaryFile;
