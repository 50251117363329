import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitNTNFile } from '../../../services/NTNServices';
import {
  NtnAttachBtn,
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';
import NtnContainer from '../NtnContainer';
import { useNavigate } from 'react-router-dom';
const initStat = {
  firmName: '',
  nameOfPartners: '',
  phoneNumber: '',
  businessEmail: '',
};
const PartnershipAndCompanySaleTax = () => {
  const user = useSelector((state) => state.users.user);
  const [data, setData] = useState(initStat);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState({
    paidBill: null,
  });
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Partnership & Company</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            console.log(data, file);
            if (
              Object.values(data).every((i) => i) &&
              Object.values(file).every((i) => i)
            ) {
              dispatch(
                submitNTNFile(
                  data,
                  file,
                  setData,
                  setFile,
                  user,
                  initStat,
                  'Partnership And Company Sale Tax',
                  true,
                  navigate
                )
              );
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
            }}
          >
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                1. Partnership firm name
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                value={data.firmName}
                onChange={(e) => {
                  setData({ ...data, firmName: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                2. Name of Partners <i>(separate by , comma)</i>
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                value={data.nameOfPartners}
                onChange={(e) => {
                  setData({ ...data, nameOfPartners: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                3. Phone number of principle office
              </NtnFormLabel>
              <NtnFormInput
                width='350px'
                required
                type='number'
                value={data.phoneNumber}
                onChange={(e) => {
                  setData({ ...data, phoneNumber: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                4. Business Email
              </NtnFormLabel>
              <NtnFormInput
                required
                type='email'
                width='350px'
                value={data.businessEmail}
                onChange={(e) => {
                  setData({ ...data, businessEmail: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                5. Latest paid electricity bill
              </NtnFormLabel>
              <input
                required
                type='file'
                id='file'
                hidden
                onChange={(e) => {
                  setFile({
                    paidBill: e.target.files[0],
                  });
                }}
              />
              <NtnFormLabel htmlFor='file'>
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{file?.paidBill?.name}</p>
              </NtnFormLabel>
            </NtnFieldContainer>
          </div>
          <NtnSubmitBtn type='submit'>Submit</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default PartnershipAndCompanySaleTax;
