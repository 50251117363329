import React from 'react';
import { MainContainer } from '../../../global/style/StyleComponent';
import {
  ReturnUsaContainer,
  ReturnUsaHead,
  ReturnUsaService,
} from '../overseas-styled';
import ReturnServiceCard from './ReturnServiceCard';
import Slider from 'react-slick';

const ReturnsInUsa = ({ setActiveCategory }) => {
  return (
    <MainContainer
      paddingVertical='90px'
      justifyContent='center'
      alignItem='center'
    >
      <ReturnUsaContainer>
        <ReturnUsaHead>
          <h1>Be a Compliant Taxpayer globally with Taxpress</h1>
          <hr />
          <span>
            Our aim is to serve people round the globe and enable them in filing
            of their income tax declarations and VAT Returns amicably.
          </span>
        </ReturnUsaHead>

        <div id='registrationServiceCard'>
          <ReturnUsaService>
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              type='USA SERVICES'
              url={require('../../../assets/certificate.png')}
              title='Direct Tax Filing'
              description='Allow our team to handle your direct tax declarations, whether it is State Tax Fling or Federal Tax filing, we have got you covered  '
            />
            <hr />
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              type='USA services'
              url={require('../../../assets/partnership.png')}
              title='VAT Returns'
              description='Our team possess the skills and expertise to make businesses compliant with VAT Obligations from the Gulf to the States. '
            />
          </ReturnUsaService>
        </div>

        <ReturnUsaService id='registrationServiceCardMobile'>
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            className='sliderTeam'
            responsive={[
              {
                breakpoint: 1100,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 830,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerMode: true,
                },
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerMode: false,
                },
              },
            ]}
          >
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/certificate.png')}
              title='Direct Tax Filing'
              description='Allow our team to handle your direct tax declarations, whether it is State Tax Fling or Federal Tax filing, we have got you covered.'
            />
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/partnership.png')}
              title='VAT Returns'
              description='Our team possess the skills and expertise to make businesses compliant with VAT Obligations from the Gulf to the States.'
            />
          </Slider>
        </ReturnUsaService>
      </ReturnUsaContainer>
    </MainContainer>
  );
};

export default ReturnsInUsa;
