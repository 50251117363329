import React, { useEffect, useState } from 'react';
import HeroSections from '../../global/components/hero-section/HeroSections';
import OverSeasPakistanis from './components/OverSeasPakistanis';
import ReturnsInUsa from './components/ReturnsInUsa';
import ServicesAndFee from '../../global/components/service-and-fee/ServicesAndFee';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOverSeaServices,
  getOverSeaServicesCategory,
} from '../../redux-store/services/actionCreator';

const OverseasPage = () => {
  const categories = useSelector((state) => state.services.overSeaCategories);
  const [activeCategory, setActiveCategory] = useState('All');

  const dispatch = useDispatch();
  const overSeaService = useSelector((state) => state.services.overSeaService);
  useEffect(() => {
    dispatch(getOverSeaServicesCategory());
    dispatch(getOverSeaServices(activeCategory));
  }, [activeCategory, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSections pageName={'Overseas Chapter'} />
      <ReturnsInUsa setActiveCategory={setActiveCategory} />
      <OverSeasPakistanis setActiveCategory={setActiveCategory} />
      <ServicesAndFee
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        categories={categories}
        data={overSeaService}
      />
    </>
  );
};

export default OverseasPage;
