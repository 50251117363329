import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AxiosCall from "../../../api/AxiosCall";
import { successToast } from "../../../global/toast";
import NtnContainer from "../NtnContainer";
import {
  NtnAttachBtn,
  NtnFormLabel,
  NtnMainContainer,
  NtnSubmitBtn,
} from "../components/style/styleNtn";
import "./FinalCalculator.scss";

const initData = {
  salary: 0,
  income_from_business: 0,
  income_from_property: 0,
  total_income: 0,
  zakat: {
    amount: 0,
    file: null,
  },
  education_expenses: {
    amount: 0,
    file: null,
  },
  total_deductible_allowance: 0,
  taxable_income: 0,
  tax_liability: 0,
  tax_on_donation_paid_amount: 0,
  tax_on_donation_paid_data: {
    title: "",
    amount: 0,
  },
  tax_on_approved_pension_amount: 0,
  tax_on_approved_pension_data: {
    title: "",
    amount: 0,
  },
  total_tax_credits: 0,
  balance_normal_tax: 0,
  tax_liability_final_tax: 0,
  total_tax_liability: 0,
  others: {
    amount: 0,
    file: null,
  },
  tax_deducted_on_bank_profits: {
    amount: 0,
    file: null,
  },
  tax_deducted_on_dividends: {
    amount: 0,
    file: null,
  },
  tax_deducted_on_mobile_internet: {
    amount: 0,
    file: null,
  },
  tax_paid_with_car: {
    amount: 0,
    file: null,
  },
  advance_tax_paid: {
    amount: 0,
    file: null,
  },
  total_tax_paid_during_year: 0,
  net_tax_payable: 0,
};

const FinalCalculator = () => {
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checks, setChecks] = useState({
    donation: false,
    pension: false,
  });
  const [data, setData] = useState({
    ...initData,
    uid: user.id,
  });

  useEffect(() => {
    AxiosCall.get(`/user/ntn/final/${user.id}`)
      .then(({ data }) => {
        const response = data?.data;
        if (response) {
          for (const key in initData) {
            if (typeof initData[key] === "object") {
              response[key] = JSON.parse(response[key]);
            }
          }
          console.log(response);
          setData(response);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    AxiosCall.get(`/user/ntn/${user.id}`)
      .then((res) => {
        const ntn = res.data.data;
        // setNtn(ntn);
        const salary = JSON.parse(
          ntn.find((item) => item.ntnTitle === "Salary File")?.ntnDetails ||
            JSON.stringify({ TotalSalary: 0 })
        )?.TotalSalary;
        const income_from_business = ntn.reduce((a, b) => {
          if (
            [
              "Trader File",
              "Service Provider and Self Employed Professional",
              "IT & IT Enabled Services",
            ].indexOf(b.ntnTitle) >= 0
          ) {
            const detail = JSON.parse(b.ntnDetails);
            a += parseInt(detail.netProfit);
          }
          return a;
        }, 0);
        const tax_liability_final_tax = ntn.reduce((a, b) => {
          if (
            [
              "Other Sources (Interest Income)",
              "Other Sources (Dividend Income)",
            ].indexOf(b.ntnTitle) >= 0
          ) {
            const detail = JSON.parse(b.ntnDetails);
            console.log(
              "1111111111111111",
              detail.taxDeducted,
              Object.values(detail).reduce(
                (c, d) => (c += parseInt(d.taxDeducted || 0)),
                0
              )
            );
            if (detail.taxDeducted) {
              a += parseInt(detail.taxDeducted);
            } else {
              a += Object.values(detail).reduce(
                (c, d) => (c += parseInt(d.taxDetected || 0)),
                0
              );
            }
          }
          return a;
        }, 0);
        const income_from_property = ntn.reduce((a, b) => {
          if (["Rental Income"].indexOf(b.ntnTitle) >= 0) {
            const detail = JSON.parse(b.ntnDetails);
            a += parseInt(detail.totalRentalIncome);
          }
          return a;
        }, 0);
        const total_income =
          salary + income_from_business + income_from_property;
        setData((prev) => ({
          ...prev,
          salary,
          income_from_business,
          income_from_property,
          total_income,
          tax_liability_final_tax,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.id]);

  const onSubmit = (isSave = false) => {
    const resData = {
      zakat: data.zakat,
      education_expenses: data.education_expenses,
      tax_on_donation_paid_data: data.tax_on_donation_paid_data,
      tax_on_donation_paid_amount: data.tax_on_donation_paid_amount,
      tax_on_approved_pension_data: data.tax_on_approved_pension_data,
      tax_on_approved_pension_amount: data.tax_on_approved_pension_amount,
      others: data.others,
      tax_deducted_on_bank_profits: data.tax_deducted_on_bank_profits,
      tax_deducted_on_dividends: data.tax_deducted_on_dividends,
      tax_deducted_on_mobile_internet: data.tax_deducted_on_mobile_internet,
      tax_paid_with_car: data.tax_paid_with_car,
      advance_tax_paid: data.advance_tax_paid,
      uid: data.uid,
    };

    AxiosCall.post("/user/ntn/final", resData).then((res) => {
      if (!isSave) {
        navigate("/wealth-form");
      } else {
        successToast("Successfully save record");
      }
    });
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    console.log(file.length);
    if (file.length > 1) {
      for (const iterator of file) {
        formData.append("file", iterator);
      }
    } else {
      formData.append("file", file);
    }
    return await AxiosCall.post(`/user/ntn/upload`, formData);
  };

  useEffect(() => {
    setData((prev) => {
      const total_deductible_allowance =
        parseInt(prev.zakat.amount || 0) +
        parseInt(prev.education_expenses.amount || 0);
      return {
        ...prev,
        total_deductible_allowance: total_deductible_allowance || 0,
      };
    });
  }, [data.zakat.amount, data.education_expenses.amount]);

  useEffect(() => {
    setData((prev) => {
      let taxOnApproved = Math.round(
        (prev.tax_liability / prev.taxable_income) *
          (parseInt(prev.tax_on_approved_pension_amount || 0) <=
          prev.taxable_income * 0.2
            ? parseInt(prev.tax_on_approved_pension_amount || 0)
            : prev.taxable_income * 0.2)
      );
      let taxOnDonation = Math.round(
        (prev.tax_liability / prev.taxable_income) *
          (parseInt(prev.tax_on_donation_paid_amount || 0) <=
          prev.taxable_income * 0.3
            ? parseInt(prev.tax_on_donation_paid_amount || 0)
            : prev.taxable_income * 0.3)
      );
      return {
        ...prev,
        total_tax_credits: taxOnApproved + taxOnDonation,
        balance_normal_tax:
          parseInt(prev.tax_liability || 0) -
          (parseInt(taxOnApproved || 0) + parseInt(taxOnDonation || 0)),
        total_tax_liability:
          parseInt(prev.tax_liability || 0) -
          (parseInt(taxOnApproved || 0) + parseInt(taxOnDonation || 0)) +
          parseInt(prev.tax_liability_final_tax || 0),
      };
    });
  }, [
    data.tax_on_approved_pension_amount,
    data.tax_on_donation_paid_amount,
    data.tax_liability,
    data.balance_normal_tax,
    data.taxable_income,
    data.tax_liability_final_tax,
  ]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      taxable_income:
        parseInt(prev.total_income || 0) -
        parseInt(prev.total_deductible_allowance || 0),
    }));
  }, [data.total_income, data.total_deductible_allowance]);

  useEffect(() => {
    setData((prev) => {
      const salary = parseInt(prev.salary || 0);
      const totalIncome = parseInt(prev.total_income || 0);
      const taxAbleIncome = parseInt(prev.taxable_income || 0);
      let total = 0;

      if ((salary / totalIncome) * 100 > 75) {
        // if (taxAbleIncome > 12000000) {
        //   total = 2955000 + (taxAbleIncome - 12000000) * 0.35;
        // } else
        if (taxAbleIncome >= 6000000) {
          total = 1095000 + (taxAbleIncome - 6000000) * 0.35;
        } else if (taxAbleIncome < 6000000 && taxAbleIncome >= 3600000) {
          total = 435000 + (taxAbleIncome - 3600000) * 0.275;
        } else if (taxAbleIncome < 3600000 && taxAbleIncome >= 2400000) {
          total = 165000 + (taxAbleIncome - 2400000) * 0.225;
        } else if (taxAbleIncome < 2400000 && taxAbleIncome >= 1200000) {
          total = 15000 + (taxAbleIncome - 1200000) * 0.125;
        } else if (taxAbleIncome < 1200000 && taxAbleIncome >= 600000) {
          total = (taxAbleIncome - 600000) * 0.025;
        } else {
          total = 0;
        }
      } else {
        if (taxAbleIncome >= 4000000) {
          total = 765000 + (taxAbleIncome - 4000000) * 0.35;
        } else if (taxAbleIncome < 4000000 && taxAbleIncome >= 3000000) {
          total = 465000 + (taxAbleIncome - 3000000) * 0.3;
        } else if (taxAbleIncome < 3000000 && taxAbleIncome >= 2400000) {
          total = 315000 + (taxAbleIncome - 2400000) * 0.25;
        } else if (taxAbleIncome < 2400000 && taxAbleIncome >= 1200000) {
          total = 75000 + (taxAbleIncome - 1200000) * 0.2;
        } else if (taxAbleIncome < 1200000 && taxAbleIncome >= 800000) {
          total = 15000 + (taxAbleIncome - 800000) * 0.15;
        } else if (taxAbleIncome < 800000 && taxAbleIncome >= 600000) {
          total = (taxAbleIncome - 600000) * 0.075;
        } else {
          total = 0;
        }
      }
      return {
        ...prev,
        tax_liability: total,
      };
    });
  }, [data.salary, data.taxable_income, data.total_income]);

  useEffect(() => {
    setData((prev) => {
      const sum =
        parseInt(data.tax_deducted_on_bank_profits.amount || 0) +
        parseInt(data.tax_deducted_on_mobile_internet.amount || 0) +
        parseInt(data.tax_deducted_on_dividends.amount || 0) +
        parseInt(data.others.amount || 0) +
        parseInt(data.advance_tax_paid.amount || 0) +
        parseInt(data.tax_paid_with_car.amount || 0);
      return {
        ...prev,
        total_tax_paid_during_year: sum,
        net_tax_payable: parseInt(prev.total_tax_liability || 0) - sum,
      };
    });
  }, [
    data.tax_deducted_on_bank_profits.amount,
    data.tax_deducted_on_mobile_internet.amount,
    data.tax_deducted_on_dividends.amount,
    data.others.amount,
    data.advance_tax_paid.amount,
    data.tax_paid_with_car.amount,
    data.total_tax_liability,
  ]);

  return (
    <NtnContainer>
      <NtnMainContainer id="finalCalculatorContainer">
        {/* <h3>Tax Calculator – Portion 1 (With dominant position of Salary)</h3> */}
        <table>
          <thead>
            <tr>
              <th>Income</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Salary</td>
              <td>{data.salary?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>Income From Business</td>
              <td>{data.income_from_business?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>Income From Property</td>
              <td>{data.income_from_property?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>
                <h3>Total Income</h3>
              </td>
              <td>
                <h3>{data.total_income?.toLocaleString()}</h3>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Deductible Allowances</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Zakat paid</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    name="zakatAmount"
                    id=""
                    value={data.zakat.amount}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        zakat: {
                          ...prev.zakat,
                          amount: parseInt(e.target.value),
                        },
                      }));
                    }}
                  />
                  <input
                    type="file"
                    name="zakaprove"
                    id="zakaprove"
                    // value={data.zakat.file}
                    hidden
                    onChange={async (e) => {
                      const { data } = await uploadFile(e.target.files[0]);
                      setData((prev) => ({
                        ...prev,
                        zakat: {
                          ...prev.zakat,
                          file: data.urls[0],
                        },
                      }));
                    }}
                  />

                  <NtnFormLabel htmlFor="zakaprove">
                    <NtnAttachBtn>Attach File</NtnAttachBtn>
                  </NtnFormLabel>
                </div>
              </td>
            </tr>
            {parseInt(data.total_income || 0) -
              parseInt(data.zakat.amount || 0) <
              1500000 && (
              <tr>
                <td>
                  <span>Education Expenses</span>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="number"
                      name="eduExpense"
                      id=""
                      max={
                        (parseInt(data.total_income || 0) -
                          parseInt(data.zakat.amount || 0)) *
                        0.25
                      }
                      value={data.education_expenses.amount}
                      onChange={async (e) => {
                        if (
                          (parseInt(data.total_income || 0) -
                            parseInt(data.zakat.amount || 0)) *
                            0.25 >=
                          e.target.value
                        ) {
                          setData((prev) => ({
                            ...prev,
                            education_expenses: {
                              ...prev.education_expenses,
                              amount: parseInt(e.target.value),
                            },
                          }));
                        } else {
                          setData((prev) => ({
                            ...prev,
                            education_expenses: {
                              ...prev.education_expenses,
                              amount:
                                (parseInt(data.total_income || 0) -
                                  parseInt(data.zakat.amount || 0)) *
                                0.25,
                            },
                          }));
                        }
                      }}
                    />
                    <input
                      type="file"
                      name="eduExpense"
                      id="eduExpense"
                      // value={data.zakat.file}
                      hidden
                      onChange={async (e) => {
                        const { data } = await uploadFile(e.target.files[0]);
                        setData((prev) => ({
                          ...prev,
                          education_expenses: {
                            ...prev.education_expenses,
                            file: data.urls[0],
                          },
                        }));
                      }}
                    />

                    <NtnFormLabel htmlFor="eduExpense">
                      <NtnAttachBtn>Attach File</NtnAttachBtn>
                    </NtnFormLabel>
                  </div>
                </td>
              </tr>
            )}

            <tr>
              <td>
                <h3
                  style={{
                    textAlign: "end",
                  }}
                  className="totalAmount"
                >
                  Total Deductible allowance
                </h3>
              </td>
              <td>{data.total_deductible_allowance?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>
                <h3
                  style={{
                    textAlign: "end",
                  }}
                  className="totalAmount"
                >
                  Taxable Income
                </h3>
              </td>
              <td>{data.taxable_income?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="donations">
                    Tax Credit on Donations paid to approved institutions
                  </label>

                  <input
                    type="checkbox"
                    name="donations"
                    id="donations"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    value={checks.donation}
                    onChange={(e) => {
                      setChecks((prev) => ({
                        ...prev,
                        donation: e.target.checked,
                      }));
                    }}
                  />
                </div>
              </td>
              {/* <td>{data.tax_on_donation_paid_amount}</td> */}
              <td>
                <table>
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Institution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="number"
                          disabled={!checks.donation}
                          value={data.tax_on_donation_paid_data.amount}
                          onChange={(e) => {
                            const value = parseInt(e.target.value || 0);
                            setData((prev) => ({
                              ...prev,
                              tax_on_donation_paid_data: {
                                ...prev.tax_on_donation_paid_data,
                                amount: e.target.value,
                              },
                              tax_on_donation_paid_amount: value,
                            }));
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={!checks.donation}
                          value={data.tax_on_donation_paid_data.title}
                          onChange={(e) => {
                            setData((prev) => ({
                              ...prev,
                              tax_on_donation_paid_data: {
                                ...prev.tax_on_donation_paid_data,
                                title: e.target.value,
                              },
                            }));
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="pension">
                    Tax Credit on Approved Pension Fund
                  </label>

                  <input
                    type="checkbox"
                    name="pension"
                    id="pension"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    value={checks.pension}
                    onChange={(e) => {
                      setChecks((prev) => ({
                        ...prev,
                        pension: e.target.checked,
                      }));
                    }}
                  />
                </div>
              </td>
              {/* <td>{data.tax_on_approved_pension_amount}</td> */}
              <td>
                <table>
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Institution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="number"
                          value={data.tax_on_approved_pension_amount}
                          disabled={!checks.pension}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            console.log(value);
                            setData((prev) => ({
                              ...prev,
                              tax_on_approved_pension_data: {
                                ...prev.tax_on_approved_pension_data,
                                amount: e.target.value,
                              },
                              tax_on_approved_pension_amount: value,
                            }));
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={!checks.pension}
                          value={data.tax_on_approved_pension_data.title}
                          onChange={(e) => {
                            setData((prev) => ({
                              ...prev,
                              tax_on_approved_pension_data: {
                                ...prev.tax_on_approved_pension_data,
                                title: e.target.value,
                              },
                            }));
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <h3
                  style={{
                    textAlign: "end",
                  }}
                >
                  Tax credits
                </h3>
              </td>
              <td>
                <h3>{data.total_tax_credits?.toLocaleString()}</h3>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "end",
                }}
              >
                Balance Normal Tax Liability
              </td>
              <td>{data.balance_normal_tax?.toLocaleString()}</td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "end",
                }}
              >
                Tax liability on account of Final Tax
              </td>
              <td>
                {parseInt(data.tax_liability_final_tax || 0)?.toLocaleString()}
              </td>
            </tr>

            <tr>
              <td
                style={{
                  textAlign: "end",
                }}
              >
                <h3>Total Tax Liability</h3>
              </td>
              <td>
                <h3>{data.total_tax_liability?.toLocaleString()}</h3>
                {/* <h3>{0}</h3> */}
              </td>
            </tr>
            <tr>
              <th colSpan={2}>
                <h3
                  style={{
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  Taxes already deducted & paid
                </h3>
              </th>
            </tr>
            <tr>
              <td>Tax deducted on bank profits</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    name=""
                    id=""
                    value={data.tax_deducted_on_bank_profits.amount}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        tax_deducted_on_bank_profits: {
                          ...prev.tax_deducted_on_bank_profits,
                          amount: parseInt(e.target.value),
                        },
                      }));
                    }}
                  />
                  <input
                    type="file"
                    name="onBank"
                    hidden
                    id="onBank"
                    multiple="multiple"
                    onClick={async (e) => {
                      const { data } = await uploadFile(e.target.files);
                      setData((prev) => ({
                        ...prev,
                        tax_deducted_on_bank_profits: {
                          ...prev.tax_deducted_on_bank_profits,
                          files: data.urls,
                        },
                      }));
                    }}
                  />
                  <NtnFormLabel htmlFor="onBank">
                    <NtnAttachBtn>Attach File</NtnAttachBtn>
                  </NtnFormLabel>
                </div>
              </td>
            </tr>
            <tr>
              <td>Tax deducted on dividends</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    name=""
                    id=""
                    value={data.tax_deducted_on_dividends.amount}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        tax_deducted_on_dividends: {
                          ...prev.tax_deducted_on_dividends,
                          amount: parseInt(e.target.value),
                        },
                      }));
                    }}
                  />
                  <input
                    type="file"
                    name="onDividends"
                    hidden
                    id="onDividends"
                    multiple="multiple"
                    onClick={async (e) => {
                      const { data } = await uploadFile(e.target.files);
                      setData((prev) => ({
                        ...prev,
                        tax_deducted_on_dividends: {
                          ...prev.tax_deducted_on_dividends,
                          files: data.urls,
                        },
                      }));
                    }}
                  />

                  <NtnFormLabel htmlFor="onDividends">
                    <NtnAttachBtn>Attach File</NtnAttachBtn>
                  </NtnFormLabel>
                </div>
              </td>
            </tr>
            <tr>
              <td>Tax deducted on mobile and internet bills</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    name=""
                    id=""
                    value={data.tax_deducted_on_mobile_internet.amount}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        tax_deducted_on_mobile_internet: {
                          ...prev.tax_deducted_on_mobile_internet,
                          amount: parseInt(e.target.value),
                        },
                      }));
                    }}
                  />
                  <input
                    type="file"
                    name="onMobile"
                    id="onMobile"
                    hidden
                    multiple="multiple"
                    onClick={async (e) => {
                      const { data } = await uploadFile(e.target.files);
                      setData((prev) => ({
                        ...prev,
                        tax_deducted_on_mobile_internet: {
                          ...prev.tax_deducted_on_mobile_internet,
                          files: data.urls,
                        },
                      }));
                    }}
                  />

                  <NtnFormLabel htmlFor="onMobile">
                    <NtnAttachBtn>Attach File</NtnAttachBtn>
                  </NtnFormLabel>
                </div>
              </td>
            </tr>
            <tr>
              <td>Tax paid with Car purchases & Token</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    name=""
                    id=""
                    value={data.tax_paid_with_car.amount}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        tax_paid_with_car: {
                          ...prev.tax_paid_with_car,
                          amount: parseInt(e.target.value),
                        },
                      }));
                    }}
                  />
                  <input
                    type="file"
                    name="onCar"
                    id="onCar"
                    hidden
                    multiple="multiple"
                    onClick={async (e) => {
                      const { data } = await uploadFile(e.target.files);
                      setData((prev) => ({
                        ...prev,
                        tax_paid_with_car: {
                          ...prev.tax_paid_with_car,
                          files: data.urls,
                        },
                      }));
                    }}
                  />

                  <NtnFormLabel htmlFor="onCar">
                    <NtnAttachBtn>Attach File</NtnAttachBtn>
                  </NtnFormLabel>
                </div>
              </td>
            </tr>
            <tr>
              <td>Advance tax paid under section 147</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    name=""
                    id=""
                    value={data.advance_tax_paid.amount}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        advance_tax_paid: {
                          ...prev.advance_tax_paid,
                          amount: parseInt(e.target.value),
                        },
                      }));
                    }}
                  />
                  <input
                    type="file"
                    name="on147"
                    id="on147"
                    hidden
                    multiple="multiple"
                    onClick={async (e) => {
                      const { data } = await uploadFile(e.target.files);
                      setData((prev) => ({
                        ...prev,
                        advance_tax_paid: {
                          ...prev.advance_tax_paid,
                          files: data.urls,
                        },
                      }));
                    }}
                  />
                  <NtnFormLabel htmlFor="on147">
                    <NtnAttachBtn>Attach File</NtnAttachBtn>
                  </NtnFormLabel>
                </div>
              </td>
            </tr>
            <tr>
              <td>Others</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="number"
                    name=""
                    id=""
                    value={data.others.amount}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        others: {
                          ...prev.others,
                          amount: parseInt(e.target.value),
                        },
                      }));
                    }}
                  />
                  <input
                    type="file"
                    name="onSalary"
                    id="onSalary"
                    hidden
                    multiple="multiple"
                    onClick={async (e) => {
                      const { data } = await uploadFile(e.target.files);
                      setData((prev) => ({
                        ...prev,
                        others: {
                          ...prev.others,
                          files: data.urls,
                        },
                      }));
                    }}
                  />
                  <NtnFormLabel htmlFor="onSalary">
                    <NtnAttachBtn>Attach File</NtnAttachBtn>
                  </NtnFormLabel>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h3
                  style={{
                    textAlign: "end",
                  }}
                >
                  Total taxes paid during the year
                </h3>
              </td>
              <td>
                <h3>{data.total_tax_paid_during_year?.toLocaleString()}</h3>
              </td>
            </tr>
            <tr>
              <td>
                <h3
                  style={{
                    textAlign: "end",
                  }}
                >
                  Net Tax Payable / (Refundable)
                </h3>
              </td>
              <td>
                <h3>{data.net_tax_payable?.toLocaleString()}</h3>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <NtnSubmitBtn
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </NtnSubmitBtn>
          <NtnSubmitBtn
            onClick={() => {
              // dispatch(setThankYou(true));
              onSubmit(true);
            }}
          >
            Save
          </NtnSubmitBtn>
          <NtnSubmitBtn
            onClick={() => {
              onSubmit();
            }}
          >
            Proceed
          </NtnSubmitBtn>
        </div>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default FinalCalculator;
