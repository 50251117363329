import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AxiosCall from '../../../api/AxiosCall';
import { submitNTNFile } from '../../../services/NTNServices';
import NtnContainer from '../NtnContainer';
import {
  NtnFieldContainer,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';

const initState = {
  monthlySalary: '',
  months: '',
  yearlyPension: '',
};

const Pension = () => {
  const [dataPension, setDataPension] = useState(initState);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setDataPension((prev) => ({
      ...prev,
      yearlyPension:
        parseInt(prev.monthlySalary || 0) * parseInt(prev.months || 0),
    }));
  }, [dataPension.monthlySalary, dataPension.months]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setDataPension(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);

  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <div
          id='pension'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignCenter: 'center',
            gap: '2em',
            paddingBottom: '1em',
          }}
        >
          <NtnSubHeader>Pension - Income</NtnSubHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <NtnFieldContainer style={{ flexDirection: 'column' }}>
              <NtnFormLabel required htmlFor='name'>
                Monthly pension
              </NtnFormLabel>
              <NtnFormInput
                required
                type='number'
                value={dataPension.monthlySalary}
                onChange={(e) =>
                  setDataPension({
                    ...dataPension,
                    monthlySalary: e.target.value,
                  })
                }
              />
            </NtnFieldContainer>
            <NtnFieldContainer style={{ flexDirection: 'column' }}>
              <NtnFormLabel required htmlFor='name'>
                Number of Months
              </NtnFormLabel>
              <NtnFormInput
                required
                type='number'
                value={dataPension.months}
                onChange={(e) =>
                  setDataPension({
                    ...dataPension,
                    months: e.target.value,
                  })
                }
              />
            </NtnFieldContainer>
            <NtnFieldContainer
              style={{
                flexDirection: 'column',
                width: '60%',
                justifyContent: 'space-between',
              }}
            >
              <strong>Yearly Pension</strong>
              <h3>{dataPension.yearlyPension || '-----'}</h3>
            </NtnFieldContainer>
          </div>
        </div>
        <NtnSubmitBtn
          onClick={() => {
            if (Object.values(dataPension).every((i) => i)) {
              dispatch(
                submitNTNFile(
                  dataPension,
                  null,
                  setDataPension,
                  () => {},
                  user,
                  initState,
                  'Other Sources (Pension)',
                  true,
                  navigate
                )
              );
            }
          }}
        >
          Proceed
        </NtnSubmitBtn>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default Pension;
