import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopCase } from '../../../../redux-store/library/actionCreator';
import TaxCard from './components/TaxCard';
import './TaxAndLegal.css';

const TaxAndLegal = () => {
  const dispatch = useDispatch();
  const topCase = useSelector((state) => state.library.topCase);
  useEffect(() => {
    dispatch(getTopCase());
  }, []);
  return (
    <div className='taxAndLegalContainer'>
      <strong>Tax & legal citations</strong>
      <hr />
      <p>
        Our case law library comprises a vast database of updated tax and legal
        citations with an additional feature of on demand citations with our
        team out there for your live support.
      </p>
      <div className='taxAndLegalDataGrid'>
        {[
          {
            court: 'Case Laws',
            shortDescription: '',
          },
          {
            court: 'Statutes',
            shortDescription: '',
          },
          {
            court: 'Dictionary',
            shortDescription: '',
          },
          {
            court: 'Circulars & Notifications',
            shortDescription: '',
          },
        ]
          .slice(0, 4)
          .map((item, index) => (
            <TaxCard
              key={index}
              title={item.court}
              shortDescription={item.shortDescription}
            />
          ))}
      </div>
    </div>
  );
};

export default TaxAndLegal;
