import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { MainContainer } from '../../../../global/style/StyleComponent';
import './CompanyLogo.css';

const CompanyLogo = () => {
  const images = [
    require('../../../../assets/company_icon/01.png'),
    require('../../../../assets/company_icon/02.png'),
    require('../../../../assets/company_icon/03.png'),
    require('../../../../assets/company_icon/04.png'),
    require('../../../../assets/company_icon/05.png'),
    require('../../../../assets/company_icon/06.png'),
    require('../../../../assets/company_icon/07.png'),
    require('../../../../assets/company_icon/08.png'),
    require('../../../../assets/company_icon/09.png'),
    require('../../../../assets/company_icon/10.png'),
    require('../../../../assets/company_icon/11.png'),
    require('../../../../assets/company_icon/12.png'),
  ];
  return (
    <MainContainer
      style={{
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        marginBottom: '10px',
      }}
    >
      <Slide
        arrows={false}
        canSwipe={false}
        slidesToScroll={1}
        slidesToShow={4}
        responsive={[
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 300,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
        ]}
      >
        {images.map((img) => (
          <img className='companyIcon' src={img} alt='' />
        ))}
      </Slide>
    </MainContainer>
  );
};

export default CompanyLogo;
