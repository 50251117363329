import React from "react";
import { useSelector } from "react-redux";
import CustomPaginationActionsTable from "../../components/table/Table";

const CaseLawList = () => {
  const searchedCaseLaw = useSelector((state) => state.library.searchedCaseLaw);
  console.log("Done ho gya hy", searchedCaseLaw);
  return (
    <div>
      <h3>Searched Case Law</h3>
      <CustomPaginationActionsTable
        rows={searchedCaseLaw}
        head={[
          { title: "Taxpress#", width: "10%", key: "id" },
          { title: "Year", width: "10%", key: "year_or_vol" },
          { title: "Judge", width: "10%", key: "judge" },
          { title: "Page No", width: "10%", key: "pageNo" },
          // { title: "Law", width: "10%", key: "law_or_statute" },
          { title: "Section", width: "10%", key: "section" },
          { title: "Court", width: "10%", key: "court" },
          { title: "Case", width: "20%", key: "caseNo" },
          { title: "Download", width: "10%" },
        ]}
      />
    </div>
  );
};

export default CaseLawList;
