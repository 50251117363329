import React from 'react';
import './ClientReview.css';

const ClientReview = ({ data }) => {
  console.log({ data });
  return (
    <div className='clientReviewContainer'>
      <p>{'⭐'.repeat(data.review)}</p>
      <span>{data.reviewTitle}</span>
      <p className='reviewComments'>{data.comment}</p>
      <div className='reviewClient'>
        <img
          src={
            data.file !== '' && data.file !== 'undefined'
              ? `https://api.taxpress.com.pk/${data.file}`
              : 'https://via.placeholder.com/70'
          }
          alt='client'
        />
        <div className='revieClientDetail'>
          <span className='name'>{data.clientName}</span>
          <span className='desination'>{data.clientDesignation}</span>
        </div>
      </div>
    </div>
  );
};

export default ClientReview;
