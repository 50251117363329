import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import AxiosCall from '../../../../api/AxiosCall';
import {
  Btn,
  Input,
  LoginRegistrationContainer,
} from '../../../style/StyleComponent';
import { errorToast } from '../../../toast';

const ServicesContentPopup = ({ setState, setThanks, title = '' }) => {
  const [data, setData] = useState({
    name: '',
    phone: '',
    email: '',
    callingTime: '',
  });
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <LoginRegistrationContainer width={'30%'}>
        <div
          style={{
            display: 'inherit',
            flexDirection: 'inherit',
          }}
        >
          <IconButton
            style={{
              alignSelf: 'flex-end',
            }}
            onClick={() => setState(false)}
          >
            <Close
              style={{
                color: '#fff',
              }}
            />
          </IconButton>
          <h2
            style={{
              fontSize: '35px',
              textAlign: 'center',
            }}
          >
            {title}
          </h2>
          <Input
            id='name'
            label='Name'
            placeholder='Name'
            value={data.name}
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
          />
          <br />
          <Input
            id='phone'
            label='Phone'
            placeholder='+92**********'
            type='number'
            value={data.phone}
            onChange={(e) => {
              setData({
                ...data,
                phone: e.target.value,
              });
            }}
          />
          <br />
          <Input
            id='email'
            label='Email'
            placeholder='email'
            value={data.email}
            onChange={(e) => {
              setData({
                ...data,
                email: e.target.value,
              });
            }}
          />
          <br />
          <Input
            id='calling_time'
            label='Calling Time'
            placeholder='Calling Time'
            value={data.callingTime}
            onChange={(e) => {
              setData({
                ...data,
                callingTime: e.target.value,
              });
            }}
          />
          <br />
          <Btn
            marginTop={'20px'}
            id='login-btn'
            onClick={() => {
              if (
                data.phone.length >= 11 &&
                data.email !== '' &&
                data.callingTime !== '' &&
                data.name !== ''
              ) {
                AxiosCall.post('/query/create', {
                  ...data,
                  message: data.callingTime,
                  title: title,
                  type: window.location.pathname.replace('/', ''),
                  isService: 1,
                })
                  .then(() => {
                    setState(false);
                    setThanks(true);
                    setData({
                      name: '',
                      phone: '',
                      email: '',
                      callingTime: '',
                    });
                  })
                  .catch(() => {
                    setState(false);
                    setData({
                      name: '',
                      phone: '',
                      email: '',
                      callingTime: '',
                    });
                    errorToast('Unable to perform your action at this moment ');
                  });
              } else {
                alert('all field are required');
              }
            }}
            fontSize='18px'
          >
            Submit
          </Btn>
          <br />
        </div>
      </LoginRegistrationContainer>
    </div>
  );
};

export default ServicesContentPopup;
