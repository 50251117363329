import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitNTNFile } from '../../../services/NTNServices';
import {
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';
import NtnContainer from '../NtnContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AxiosCall from '../../../api/AxiosCall';

const initStat = {
  grossReceipts: '',
  rent: '',
  salaries: '',
  electricityWaterGas: '',
  communication: '',
  repairAndMaintains: '',
  otherExpense: '',
  totalExpense: '',
  netProfit: '',
};

const ServiceProviderAndSelfEmployedProfessional = () => {
  const user = useSelector((state) => state.users.user);
  const [data, setData] = useState(initStat);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setData(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);
  useEffect(() => {
    setData({
      ...data,
      totalExpense:
        parseInt(data.rent) +
        parseInt(data.salaries) +
        parseInt(data.electricityWaterGas) +
        parseInt(data.communication) +
        parseInt(data.repairAndMaintains) +
        parseInt(data.otherExpense),
      netProfit: parseInt(data.grossReceipts) - parseInt(data.totalExpense),
    });
  }, [
    data.rent,
    data.electricityWaterGas,
    data.salaries,
    data.communication,
    data.repairAndMaintains,
    data.otherExpense,
    data.totalExpense,
    data.grossReceipts,
  ]);
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>
          Service Provider & Self Employed Professional
        </NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            if (Object.values(data).every((i) => i.toString().trim() !== '')) {
              dispatch(
                submitNTNFile(
                  data,
                  null,
                  setData,
                  () => {},
                  user,
                  initStat,
                  'Service Provider and Self Employed Professional',
                  false,
                  navigate
                )
              );
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
            }}
          >
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                1. Gross Receipts
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                value={data.grossReceipts}
                onChange={(e) => {
                  setData({ ...data, grossReceipts: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <h3
              style={{
                textAlign: 'center',
              }}
            >
              Management / Admin & Selling Expenses
            </h3>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                2. Rent{' '}
              </NtnFormLabel>
              <NtnFormInput
                type='number'
                required
                width='350px'
                value={data.rent}
                onChange={(e) => {
                  setData({ ...data, rent: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                3. Salaries
              </NtnFormLabel>
              <NtnFormInput
                type='number'
                required
                width='350px'
                value={data.salaries}
                onChange={(e) => {
                  setData({ ...data, salaries: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                4. Electricity/ water / gas
              </NtnFormLabel>
              <NtnFormInput
                type='number'
                required
                width='350px'
                value={data.electricityWaterGas}
                onChange={(e) => {
                  setData({ ...data, electricityWaterGas: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                5. Communication
              </NtnFormLabel>
              <NtnFormInput
                type='number'
                required
                width='350px'
                value={data.communication}
                onChange={(e) => {
                  setData({ ...data, communication: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                6. Repair & maintenance
              </NtnFormLabel>
              <NtnFormInput
                type='number'
                required
                width='350px'
                value={data.repairAndMaintains}
                onChange={(e) => {
                  setData({ ...data, repairAndMaintains: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                7. Other Expenses
              </NtnFormLabel>
              <NtnFormInput
                type='number'
                required
                width='350px'
                value={data.otherExpense}
                onChange={(e) => {
                  setData({ ...data, otherExpense: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <h3
                style={{
                  width: '200px',
                  margin: 0,
                }}
              >
                Total Expense
              </h3>
              <h3>{data.totalExpense || '------'}</h3>
            </NtnFieldContainer>
            <NtnFieldContainer>
              <h3
                style={{
                  width: '200px',
                  margin: 0,
                }}
              >
                Net Profit
              </h3>
              <h3>{data.netProfit || '------'}</h3>
            </NtnFieldContainer>
          </div>
          <NtnSubmitBtn type='submit'>Submit</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default ServiceProviderAndSelfEmployedProfessional;
