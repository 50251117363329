import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Btn } from "../../../global/style/StyleComponent";

const Calculator = () => {
  const [data, setData] = useState("");
  const [month, setMonth] = useState(null);
  const [calculatedTax, setCalculatedTax] = useState({
    monthlySalary: 0.0,
    monthlyTax: 0.0,
    monthSalaryAfterTax: 0.0,
    yearlySalary: 0.0,
    yearlyTax: 0.0,
    yearSalaryAfterTax: 0.0,
  });

  const onClickHandler = ({ month }) => {
    if (data !== "") {
      let yearlySalary = month != null ? data * month : data * 12;
      let yearlyTax = 0;
      let monthlyTax = 0;
      if (yearlySalary > 600000 && yearlySalary <= 1200000) {
        yearlyTax = ((yearlySalary - 600000) * 5) / 100;
        monthlyTax = (yearlyTax / 12).toFixed(2);
      } else if (yearlySalary > 1200000 && yearlySalary <= 2200000) {
        yearlyTax = ((yearlySalary - 1200000) * 15) / 100 + 30000;
        monthlyTax = (yearlyTax / 12).toFixed(2);
      } else if (yearlySalary > 2200000 && yearlySalary <= 3200000) {
        yearlyTax = ((yearlySalary - 2200000) * 25) / 100 + 180000;
        monthlyTax = (yearlyTax / 12).toFixed(2);
      } else if (yearlySalary > 3200000 && yearlySalary <= 4100000) {
        yearlyTax = ((yearlySalary - 3200000) * 30) / 100 + 430000;
        monthlyTax = (yearlyTax / 12).toFixed(2);
      } else if (yearlySalary > 4100000) {
        yearlyTax = ((yearlySalary - 4100000) * 35) / 100 + 700000;
        monthlyTax = (yearlyTax / 12).toFixed(2);
      }
      if (month !== null) {
        setCalculatedTax({
          monthlySalary: data,
          monthlyTax: monthlyTax,
          monthSalaryAfterTax: data - monthlyTax,
          yearlySalary: yearlySalary,
          yearlyTax: monthlyTax * month,
          yearSalaryAfterTax: yearlySalary - monthlyTax * month,
        });
      } else {
        setCalculatedTax({
          monthlySalary: data,
          monthlyTax: monthlyTax,
          monthSalaryAfterTax: data - monthlyTax,
          yearlySalary: yearlySalary,
          yearlyTax: yearlyTax,
          yearSalaryAfterTax: yearlySalary - yearlyTax,
        });
      }
    }
  };
  return (
    <div className="calculatorFormContainer">
      <Grid container gap={"5px"}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              id="monthlySalary"
              label="Monthly Salary"
              variant="outlined"
              value={data}
              onChange={(e) => setData(e.target.value)}
              type={"number"}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">No of Months</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month}
              label="No of Months"
              onChange={(e) => {
                setMonth(e.target.value);
                onClickHandler({ month: e.target.value });
              }}
            >
              {Array(12)
                .fill()
                .map((_, i) => (
                  <MenuItem key={i} value={i + 1}>
                    {i + 1} Months
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={2.8}
          sx={{
            display: "flex",
          }}
        >
          <Btn
            marginLeft={"0px"}
            marginRight={"0px"}
            onClick={() => onClickHandler({ month })}
            width={"100%"}
          >
            Calculate
          </Btn>
        </Grid>
      </Grid>
      <div className="calculatedDetail">
        <div>
          <span>Monthly Salary</span>
          <span>Monthly Tax</span>
          <span>Salary After Tax</span>
        </div>
        <div>
          <h2>{parseFloat(calculatedTax.monthlySalary).toFixed(2)}</h2>
          <h2>{parseFloat(calculatedTax.monthlyTax).toFixed(2)}</h2>
          <h2>{calculatedTax.monthSalaryAfterTax.toFixed(2)}</h2>
        </div>
        <div>
          <span>
            {month === null ? "Yearly Salary" : `${month} Months Salary`}
          </span>
          <span>{month === null ? "Yearly Tax" : `${month} Months Tax`}</span>
          <span>Salary After Tax</span>
        </div>
        <div>
          <h2>{calculatedTax.yearlySalary.toFixed(2)}</h2>
          <h2>{calculatedTax.yearlyTax.toFixed(2)}</h2>
          <h2>{calculatedTax.yearSalaryAfterTax.toFixed(2)}</h2>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
