import styled from 'styled-components';

export const SideBarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 18px;
    & > a {
      color: black;
      text-decoration: none;
      text-transform: capitalize;
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    background-color: #373f68;
    & > div,
    & > .expandable {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 18px;
      color: white;
      & > a {
        color: white;
        text-decoration: none;
        text-transform: capitalize;
      }
    }
    & > .expandable {
      background: #475288 !important;
      display: flex;
      flex-direction: column;
      & > div {
        padding: 10px 18px;
        & > a {
          color: white;
          text-decoration: none;
          text-transform: capitalize;
        }
      }
    }
  }
`;

export const NtnMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 150px 2em 75px;
  @media print {
    width: 100%;
  }
  @media (max-width: 800px) {
    padding: 2em 50px 2em 50px;
  }
`;
export const NtnMainHeader = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 42px;
`;
export const NtnSubHeader = styled.h2`
  margin: 0;
  font-weight: 400;
  font-size: 36px;
  padding-bottom: 0.4em;
  text-transform: capitalize;
  margin-top: 1em;
  border-bottom: 1px solid #ff7200;
`;
export const NtnPageDescription = styled.span`
  margin: 0;
  font-size: 20px;
`;

export const NtnSubmitBtn = styled.button`
  align-self: center;
  border: none;
  background-color: #373f68;
  color: #fff;
  min-width: 150px;
  font-weight: 600;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #373f68;
  cursor: pointer;
  &:hover {
    background-color: #081558;
  }
`;

export const NtnAttachBtn = styled.div`
  background-color: #fcc028;
  padding: 10px 15px;
  width: fit-content;
  border: none;
  border-radius: 6px;
  user-select: none;
  font-size: 13px;
  box-shadow: 2px 2px 2px #e4a400;
  cursor: pointer;
  &:hover {
    background-color: #e4a400;
  }
`;

export const NtnFieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 2em;
  & > label {
    font-weight: 700;
    text-align: start;
    margin: 0;
    padding: 0;
  }
  & input {
    width: 60%;
  }
  & .select-react {
    flex: 1 !important;
  }
`;

export const NtnForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const NtnFormInput = styled.input`
  padding: 1em;
  width: ${({ width }) => width};
  border: 1px solid ${({ error }) => (error ? 'red' : '#737373')};
  box-shadow: 0 0 7px #737373;
  border-radius: 5px;
`;

export const NtnFormLabel = styled.label`
  width: 300px;
  &::after {
    content: ${({ required }) => (required ? '"*"' : '""')};
    margin-left: 5px;
    /* content: ${({ required }) => '*'}; */
    color: ${({ required }) => (required ? 'red' : '')};
  }
`;

export const NtnFormCheckBox = styled.div`
  padding: 1em;
  min-width: 10px;
  height: 10px;
  border: 1px solid #737373;
  box-shadow: 0 0 7px #737373;
`;

export const NtnContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: ${({ padding }) => padding};
`;
