import AxiosCall from '../api/AxiosCall';
import { errorToast, successToast } from '../global/toast';
import { getAddToCart } from '../redux-store/user/actionCreator';
import { setThankYou } from '../redux-store/user/userReducer';
import { NtnList } from '../utils/NtnList';

export const submitNTNFile =
  (
    data,
    file,
    setData,
    setFile,
    user,
    initStat,
    ntnTitle,
    isMultiFile = false,
    navigate
  ) =>
  (dispatch) => {
    const formData = new FormData();
    formData.append('ntnTitle', ntnTitle);
    formData.append('ntnDetail', JSON.stringify(data));
    if (file) {
      if (isMultiFile) {
        Object.keys(file).forEach((item) => {
          if (item) {
            formData.append(
              `${item.replaceAll(' ', '')}-files-${user.id}`,
              file[item]
            );
          }
        });
      } else {
        formData.append(
          `${ntnTitle.replaceAll(' ', '')}-files-${user.id}`,
          file
        );
      }
    }
    formData.append('userId', user.id);
    formData.append('route', window.location.pathname);
    AxiosCall.post('user/ntn/create', formData)
      .then((res) => {
        if (NtnList.includes(window.location.pathname)) {
          navigate('/add-to-cart');
        } else {
          dispatch(setThankYou(true));
        }
        dispatch(getAddToCart(user));
        successToast(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        errorToast(err.response.data.data);
      });
    setData(initStat);
    // setFile();
  };

export const removeNtn = (id, user) => (dispatch) => {
  AxiosCall.delete(`user/ntn/${id}`)
    .then((res) => {
      dispatch(getAddToCart(user));
      successToast(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      errorToast(err.response.data.data);
    });
};

export const submitNTN = (data, setData, user, initStat, ntnTitle) => {
  const formData = new FormData();
  formData.append('ntnTitle', ntnTitle);
  formData.append('ntnDetail', JSON.stringify(data));
  formData.append('userId', user.id);
  AxiosCall.post('user/ntn/create', formData)
    .then((res) => {
      successToast(res.data.data);
    })
    .catch((err) => {
      errorToast(err.response.data.data);
    });
  setData(initStat);
  // setFile();
};
