import React from 'react';
import './FeaturesCards.css';

const FeaturesCards = ({ Icon, head, body }) => {
  return (
    <div className='featuresCardContainer'>
      <Icon />
      <strong>{head}</strong>
      <p>{body}</p>
    </div>
  );
};

export default FeaturesCards;
