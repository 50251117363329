import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Document,
  PDFViewer,
  StyleSheet,
  Page,
  Text,
  View,
  Image,
  Link,
} from "@react-pdf/renderer";
import Logo from "../../../../assets/logo.png";
import BlackLogo from "../../../../assets/BlackLogo.png";
import { getAllStatutes } from "../../../../redux-store/library/actionCreator";
import DownloadIcon from "@mui/icons-material/Download";

// BlackLogo.png
import html2pdf from "html2pdf.js";
import { useDispatch, useSelector } from "react-redux";

// const convertHtmlToPdf = (htmlContent) => {
//   return new Promise((resolve, reject) => {
//     htmlToPdf.create(htmlContent).toBuffer((err, buffer) => {
//       if (err) {
//         reject(err);
//       } else {
//         resolve(buffer);
//       }
//     });
//   });
// };

export default function PdfGenerater() {
  const location = useLocation();
  const { fileData } = location.state;
  const data = fileData;
  const statueId = fileData?.law_or_statute_id;
  console.log("File data from state:", fileData);
  const allStatutes = useSelector((state) => state.library.allStatutes);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllStatutes());
  }, []);
  const [matchedStatute, setMatchedStatute] = useState("");
  useEffect(() => {
    // Find statute based on law_or_statute_id
    if (statueId && allStatutes?.length > 0) {
      const matched = allStatutes.find((statute) => statute.id === statueId);
      setMatchedStatute(matched);
      console.log("chla");
    } else {
      setMatchedStatute(null);
      console.log("ni chla", allStatutes);
    }
  }, [allStatutes]);

  console.log("sary statutes", allStatutes);
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      // padding: 32,
      //   color: "white",
    },
    // section: {
    //   margin: 5,
    //   padding: 10,
    // },
    viewer: {
      width: window.innerWidth,
      //the pdf viewer will take up all of the width and height
      height: "1000px",
      width: "100%",
    },
    smallText: {
      fontSize: 10,
      marginBottom: 8,
      fontFamily: "Times New Roman",
    },
    normalText: {
      fontSize: 12,
      fontFamily: "Times New Roman",
      marginBottom: "8px",
      // marginBottom: 10,
    },
    largeText: {
      fontSize: 18,
      fontFamily: "Times New Roman",
      // marginBottom: 10,
    },
    container: {
      flexDirection: "row",
    },
    imageColumn: {
      flex: 1,
      maxWidth: "30%", // Adjust the width as desired
    },
    innerViewColumn: {
      flex: 1,
    },
    image: {
      // maxHeight: "80px",
      maxWidth: "100px",
      marginLeft: "10px",
      padding: "2px",
      // marginLeft: 25
      // padding:20
      // backgroundColor: 'black',
    },
    innerView: {
      flexDirection: "row",
      marginLeft: 10, // Add any desired margin
    },
    line: {
      marginTop: 10,
      marginBottom: 0,
      borderBottomColor: "black",
      borderBottomWidth: 1,
    },
    head: {
      width: "100%",
      backgroundColor: "#373f68",
      height: "40px",
      padding: "5px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    bottom: {
      width: "100%",
      backgroundColor: "#373f68",
      height: "40px",
      padding: "5px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "absolute",
      bottom: 0,
    },
    section: {
      margin: 10,
      padding: 10,
      // flexGrow: 1,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
    },
    leftColumn: {
      width: "20%",
      display: "table-cell",
    },
    rightColumn: {
      width: "80%",
      marginLeft: "30px",
      display: "table-cell",
    },
    tableLeftCell: {
      marginTop: "10px",
      fontSize: 10,
      fontWeight: "bold",
    },
    tableRightCell: {
      marginTop: "10px",
      fontSize: 10,
    },
    verticalLine: {
      borderRight: 1,
      borderColor: "black",
      height: "100%",
    },
  });
  const extractTextFromHTML = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const extractedText = extractTextFromHTML(fileData?.file);

  const contentRef = useRef(null);

  const generatePDF = () => {
    const element = contentRef.current;
    // const opt = {
    //   margin: [0.4, 0, 0.4, 0],
    //   // padding: [5, 5, 5, 5],
    //   filename: "taxpress.pdf",
    //   image: { type: "jpeg", quality: 0.98 },
    //   html2canvas: { scale: 2 },
    //   // pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1", after: "1cm" },
    //   pagebreak: { before: ".newPage", avoid: ["h2", "h3", "h4", ".field"] },

    //   jsPDF: {
    //     unit: "in",
    //     format: "a4",
    //     orientation: "portrait",
    //     putTotalPages: true,
    //   },
    //   // Define header content
    //   header: () => {
    //     return (
    //       <div style={{ textAlign: "center", fontSize: "12px" }}>
    //         <span>Header Content</span>
    //       </div>
    //     );
    //   },
    //   pagebreak: { mode: "css", avoid: [".avoidPageBreak"] },
    // };
    var opt = {
      margin: [15, 15],
      filename: "taxpress.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };
    // Clone the content to manipulate its style
    const clonedContent = element.cloneNode(true);

    const avoidElements = clonedContent.querySelectorAll(".avoidPageBreak");
    avoidElements.forEach((el) => {
      const pageBreak = document.createElement("div");
      pageBreak.style.pageBreakBefore = "always";
      el.parentNode.insertBefore(pageBreak, el);
    });

    // clonedContent.style.height = "10in";
    clonedContent.style.display = "block"; // Display for PDF generation
    document.body.appendChild(clonedContent); // Append to body for PDF generation

    html2pdf().from(clonedContent).set(opt).save();

    // Remove the cloned content after PDF generation
    document.body.removeChild(clonedContent);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        {/* <h1>Generate PDF</h1> */}

        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            margin: "20px",
            cursor: "pointer",
            zIndex: 10000000,
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          onClick={generatePDF}
        >
          <DownloadIcon style={{ fontSize: "50px" }} />
        </div>

        <div ref={contentRef} style={{ display: "none" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#373f68",
              height: "40px",
              padding: "5px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ maxWidth: "100px", marginLeft: "10px", padding: "2px" }}
              src={Logo}
              alt="logo"
            />
            <p
              style={{
                color: "white",
                textAlign: "right",
                marginLeft: "500px",
                fontSize: "20px",
              }}
            >
              Case ID:{fileData?.id}
            </p>
          </div>

          <div>
            <div style={{ margin: "10px", padding: "10px" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Judge
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.judge}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Lawyer
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.lawyer}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Court
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.court}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Case Number
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.year_or_vol} {fileData?.month} {fileData?.pageNo}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Journal
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.journals}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Law / Statutes
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  {matchedStatute && (
                    <p style={{ marginTop: "10px", fontSize: "14px" }}>
                      {matchedStatute?.law_or_statute}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Dated
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.dated}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Sections
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.section}, {fileData?.section2}
                  </p>
                </div>
              </div>

              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Applicant/Opponent
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.appellant_or_opponent}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div style={{ padding: "20px", paddingBottom: "40px" }}>
              <p>{fileData?.principleOfCaseLaws}</p>
              {/* <p>{fileData?.shortParagraph}</p> */}
              <div
                // style={{ pageBreakBefore: "10in", pageBreakInside: "avoid" }}
                dangerouslySetInnerHTML={{ __html: fileData?.shortParagraph }}
              ></div>
              {/* Additional HTML content */}
              <div
                // style={{ pageBreakBefore: "10in", pageBreakInside: "avoid" }}
                dangerouslySetInnerHTML={{ __html: fileData?.file }}
              ></div>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "200px",
            paddingRight: "200px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#373f68",
              height: "40px",
              padding: "5px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ maxWidth: "100px", marginLeft: "10px", padding: "2px" }}
              src={Logo}
              alt="logo"
            />
            <p
              style={{
                color: "white",
                textAlign: "right",
                marginLeft: "500px",
                fontSize: "20px",
              }}
            >
              Case ID:{fileData?.id}
            </p>
          </div>

          <div>
            <div style={{ margin: "10px", padding: "10px" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Judge
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.judge}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Lawyer
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.lawyer}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Court
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.court}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Case Number
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.year_or_vol} {fileData?.month} {fileData?.pageNo}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Jounral
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.journals}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Law / Statutes
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  {matchedStatute && (
                    <p style={{ marginTop: "10px", fontSize: "14px" }}>
                      {matchedStatute?.law_or_statute}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Dated
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.dated}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Sections
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.section}, {fileData?.section2}
                  </p>
                </div>
              </div>

              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20%" }}>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Applicant/Opponent
                  </p>
                </div>
                <div style={{ borderRight: "1px solid black" }}></div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "30px",
                  }}
                >
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {fileData?.appellant_or_opponent}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div style={{ padding: "20px", paddingBottom: "40px" }}>
              <p>{fileData?.principleOfCaseLaws}</p>
              {/* <p>{fileData?.shortParagraph}</p> */}
              <div
                // style={{ pageBreakBefore: "10in", pageBreakInside: "avoid" }}
                dangerouslySetInnerHTML={{ __html: fileData?.shortParagraph }}
              ></div>
              {/* Additional HTML content */}
              <div
                // style={{ pageBreakBefore: "10in", pageBreakInside: "avoid" }}
                dangerouslySetInnerHTML={{ __html: fileData?.file }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <h1>Generate PDF</h1>
        <button onClick={generatePDF}>Generate PDF</button>
        <button onClick={viewPDF}>View PDF</button>
        <div style={{ display: "none" }} id="content-to-pdf">
          <h2>PDF Content</h2>
          <p>This content will be included in the PDF.</p>
          <p>You can add any HTML content here.</p>
          <div dangerouslySetInnerHTML={{ __html: fileData?.file }}></div>
        </div>
      </div> */}

      {/* <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.head}>
              
              <Image style={styles.image} src={Logo} alt="Logo" />
              <Text
                style={{
                  color: "white",
                  textAlign: "right",
                  marginLeft: "400px",
                  fontSize: "12px",
                }}
              >
                Case ID:{fileData?.id}
              </Text>
            </View>
            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.tableLeftCell}>Judge</Text>
                  </View>
                  <View style={styles.verticalLine}></View>
                  <View style={styles.rightColumn}>
                    <Text style={styles.tableRightCell}>{fileData?.judge}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.tableLeftCell}>Lawyer</Text>
                  </View>
                  <View style={styles.verticalLine}></View>
                  <View style={styles.rightColumn}>
                    <Text style={styles.tableRightCell}>
                      {fileData?.lawyer}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.tableLeftCell}>Court</Text>
                  </View>
                  <View style={styles.verticalLine}></View>
                  <View style={styles.rightColumn}>
                    <Text style={styles.tableRightCell}>{fileData?.court}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.tableLeftCell}>Case Number</Text>
                  </View>
                  <View style={styles.verticalLine}></View>
                  <View style={styles.rightColumn}>
                    <Text style={styles.tableRightCell}>
                      {fileData?.caseNo}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.tableLeftCell}>Jounral</Text>
                  </View>
                  <View style={styles.verticalLine}></View>
                  <View style={styles.rightColumn}>
                    <Text style={styles.tableRightCell}>
                      {fileData?.journals}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.tableLeftCell}>Law/Statutes</Text>
                  </View>
                  <View style={styles.verticalLine}></View>
                  <View style={styles.rightColumn}>
                    <Text style={styles.tableRightCell}>ok</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.tableLeftCell}>Applicant/Opponent</Text>
                  </View>
                  <View style={styles.verticalLine}></View>
                  <View style={styles.rightColumn}>
                    <Text style={styles.tableRightCell}>
                      {fileData?.appellant_or_opponent}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <hr />

            <View style={styles.line} />

            <Text
              style={{
                textAlign: "justify",
                padding: "10px",
                fontSize: "14px",
                marginBottom: "50px",
              }}
            >
              {fileData?.shortParagraph}
            </Text>

            <Text
              style={{
                textAlign: "justify",
                padding: "10px",
                fontSize: "14px",
                marginBottom: "50px",
              }}
            >
              {fileData?.principleOfCaseLaws}
            </Text>

            <Text
              style={{
                textAlign: "justify",
                padding: "10px",
                fontSize: "14px",
              }}
              
            >
              
              {fileData?.file}
            </Text>

            <View
              style={{
                position: "absolute",
                top: "120mm",
                left: "16%",
                right: "20%",
                // fontSize: "35px",
                color: "rgba(0, 0, 0, 0.3)",
                transform: "rotate(315deg)",
                zIndex: "-1",
              }}
            >
              <Image
                style={{ height: "150px", width: "400px" }}
                src={BlackLogo}
                alt="Logo"
              />
            </View>
            <View style={styles.bottom}>
              <Link
                style={{ textDecoration: "none" }}
                src="https://taxpress.com.pk/"
              >
                <Text style={{ color: "white", fontSize: "12px" }}>
                  www.taxpress.com.pk
                </Text>
              </Link>
              <Text
                style={{
                  color: "white",
                  textAlign: "right",
                  marginLeft: "350px",
                  fontSize: "12px",
                }}
              >
                info@taxpress.com.pk
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer> */}
    </>
  );
}
