import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { Download } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CustomTableRow = ({ row, head }) => {
  const navigate = useNavigate();
  function DownloadFile(fileData) {
    // console.log("on click py mil rha hoon", fileData);
    // const encodedFileData = encodeURIComponent(fileData);
    // console.log(encodedFileData);
    navigate("/library/pdf-generater", {
      state: {
        // ...values
        fileData: fileData,
      },
    });

    // fetch(`${fileName}`)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = "taxpress.pdf";
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   });
  }

  
  // function DownloadFile(fileName) {
  //   fetch(`${process.env.REACT_APP_FILE_DOMAIN}${fileName}`)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'taxpress.pdf';
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //       window.URL.revokeObjectURL(url);
  //     });
  // }
 
  // const onCheckLogin = (link) => {
  //   if (!isLoggedIn || user.user_role !== "library") {
  //     navigate("/login");
  //   } else {
  //     if (typeof link === "string") DownloadFile(link);
  //   }
  // };
  return (
    <TableRow key={row.id}>
      {head.map(({ key, title, width }, index) => (
        <TableCell align="center" width={width} key={index}>
          {head.length === index + 1 ? (
            <IconButton
              onClick={() => {
                console.log("Mil to rhi hy file", row);
                DownloadFile(row);
              }}
            >
              <Download />
            </IconButton>
          ) : (
            row[key]
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default CustomTableRow;
