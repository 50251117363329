import { Delete } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AxiosCall from '../../../api/AxiosCall';
import { errorToast } from '../../../global/toast';
import { submitNTNFile } from '../../../services/NTNServices';
import NtnContainer from '../NtnContainer';
import {
  NtnAttachBtn,
  NtnFieldContainer,
  NtnFormInput,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';

const initState = {
  capitalGain: {
    properties: [
      {
        propertiesParticular: '',
        saleProceeds: '',
        costAsPerWealthStatement: '',
        dateOfSale: '',
        dateOfPurchase: '',
      },
    ],
    soldSharesOfListedCompanies: 'no',
    unitOfMutualFunds: 'no',
    pensionFundManager: 'no',
  },
};
const initStateFileCapitalGain = {
  soldSharesOfListedCompanies: null,
  unitOfMutualFunds: null,
  pensionFundManager: null,
};

const CapitalGain = () => {
  const [dataCapitalGain, setDataCapitalGain] = useState(initState.capitalGain);
  const [fileCapitalGain, setFileCapitalGain] = useState(
    initStateFileCapitalGain
  );
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setDataCapitalGain({
              ...initState.capitalGain,
              ...JSON.parse(res.data.data.ntnDetails),
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);

  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <div
          id='capital_gains'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignCenter: 'center',
            gap: '2em',
            paddingBottom: '1em',
          }}
        >
          <NtnSubHeader>Capital Gains</NtnSubHeader>
          <Grid container spacing={'1em'}>
            <Grid item xs={12}>
              <h3 style={{ textAlign: 'center' }}>
                Capital Gain on Immovable property (Land, Buildings and Houses)
              </h3>
            </Grid>
            {dataCapitalGain?.properties.map((item, index) => (
              <>
                <Grid
                  key={index}
                  item
                  xs={3}
                  display='flex'
                  flexDirection={'column'}
                >
                  <NtnFieldContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <label style={{ textAlign: 'center' }}>
                      Property Particulars as per wealth Statement
                    </label>
                    <NtnFormInput
                      width={'100%'}
                      value={
                        dataCapitalGain.properties[index].propertiesParticular
                      }
                      onChange={(e) => {
                        const tempData = dataCapitalGain.properties;
                        console.log(index);
                        tempData[index].propertiesParticular = e.target.value;
                        setDataCapitalGain({
                          ...dataCapitalGain,
                          properties: tempData,
                        });
                      }}
                    />
                  </NtnFieldContainer>
                </Grid>
                <Grid item xs={2} display='flex' flexDirection={'column'}>
                  <NtnFieldContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <label style={{ textAlign: 'center' }}>Sale proceeds</label>
                    <NtnFormInput
                      width={'auto'}
                      value={item.saleProceeds}
                      onChange={(e) => {
                        const tempData = dataCapitalGain.properties;
                        tempData[index].saleProceeds = e.target.value;
                        setDataCapitalGain({
                          ...dataCapitalGain,
                          properties: tempData,
                        });
                      }}
                    />
                  </NtnFieldContainer>
                </Grid>
                <Grid item xs={2} display='flex' flexDirection={'column'}>
                  <NtnFieldContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <label style={{ textAlign: 'center' }}>
                      Cost as per wealth statement
                    </label>
                    <NtnFormInput
                      width={'auto'}
                      value={item.costAsPerWealthStatement}
                      onChange={(e) => {
                        const tempData = dataCapitalGain.properties;
                        tempData[index].costAsPerWealthStatement =
                          e.target.value;
                        setDataCapitalGain({
                          ...dataCapitalGain,
                          properties: tempData,
                        });
                      }}
                    />
                  </NtnFieldContainer>
                </Grid>
                <Grid item xs={2} display='flex' flexDirection={'column'}>
                  <NtnFieldContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <label style={{ textAlign: 'center' }}>Date of Sale</label>
                    <NtnFormInput
                      width={'auto'}
                      value={item.dateOfSale}
                      onChange={(e) => {
                        const tempData = dataCapitalGain.properties;
                        tempData[index].dateOfSale = e.target.value;
                        setDataCapitalGain({
                          ...dataCapitalGain,
                          properties: tempData,
                        });
                      }}
                    />
                  </NtnFieldContainer>
                </Grid>
                <Grid item xs={2} display='flex' flexDirection={'column'}>
                  <NtnFieldContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <label htmlFor='name'>Date of purchase</label>
                    <NtnFormInput
                      width={'auto'}
                      value={item.dateOfPurchase}
                      onChange={(e) => {
                        const tempData = dataCapitalGain.properties;
                        tempData[index].dateOfPurchase = e.target.value;
                        setDataCapitalGain({
                          ...dataCapitalGain,
                          properties: tempData,
                        });
                      }}
                    />
                  </NtnFieldContainer>
                </Grid>
                <Grid item xs={1} display={'flex'} alignItems={'end'}>
                  <IconButton
                    onClick={() => {
                      const temp = dataCapitalGain.properties.filter(
                        (_, idx) => idx !== index
                      );
                      console.log(temp);
                      setDataCapitalGain((prev) => ({
                        ...prev,
                        properties: temp,
                      }));
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid
            container
            xs={12}
            alignItems='center'
            flexDirection={'column'}
            margin={'20px 0'}
          >
            <b
              onClick={() => {
                setDataCapitalGain({
                  ...dataCapitalGain,
                  properties: [
                    ...dataCapitalGain.properties,
                    { ...initState.capitalGain.properties[0] },
                  ],
                });
              }}
              style={{
                padding: '10px 20px',
                border: '1px solid #737373',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              Add more property
            </b>
            <hr style={{ width: '40%' }} />
            <h3 style={{ textAlign: 'center', margin: 0 }}>
              Capital Gains on disposal of Shares / Securities
            </h3>
          </Grid>
          <Grid container xs={8} alignSelf='center'>
            <Grid item xs={4}>
              <label
                style={{
                  width: '100%',
                }}
                htmlFor='name'
              >
                Have you sold shares of Listed Companies
              </label>
            </Grid>
            <Grid item xs={3}>
              <input
                onChange={(e) =>
                  setDataCapitalGain({
                    ...dataCapitalGain,
                    soldSharesOfListedCompanies: e.target.value,
                  })
                }
                checked={dataCapitalGain.soldSharesOfListedCompanies === 'yes'}
                value='yes'
                type='radio'
                name='account1'
                id='accountYes1'
              />
              <label htmlFor='accountYes1'>Yes</label>
            </Grid>
            <Grid item xs={3}>
              <input
                onChange={(e) =>
                  setDataCapitalGain({
                    ...dataCapitalGain,
                    soldSharesOfListedCompanies: e.target.value,
                  })
                }
                checked={dataCapitalGain.soldSharesOfListedCompanies === 'no'}
                value='no'
                type='radio'
                name='account1'
                id='accountNo1'
              />
              <label htmlFor='accountNo1'>No</label>
            </Grid>
            <Grid item xs={2}>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'center',
                }}
                htmlFor='file5'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{fileCapitalGain?.soldSharesOfListedCompanies?.name}</p>
              </label>
              <input
                type='file'
                id='file5'
                hidden
                onChange={(e) => {
                  setFileCapitalGain({
                    ...fileCapitalGain,
                    soldSharesOfListedCompanies: e.target.files[0],
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <label
                style={{
                  width: '100%',
                }}
                htmlFor='name'
              >
                Have you disposed of units of mutual funds
              </label>
            </Grid>
            <Grid item xs={3}>
              <input
                onChange={(e) =>
                  setDataCapitalGain({
                    ...dataCapitalGain,
                    unitOfMutualFunds: e.target.value,
                  })
                }
                checked={dataCapitalGain.unitOfMutualFunds === 'yes'}
                value='yes'
                type='radio'
                name='account2'
                id='accountYes2'
              />
              <label htmlFor='accountYes2'>Yes</label>
            </Grid>
            <Grid item xs={3}>
              <input
                onChange={(e) =>
                  setDataCapitalGain({
                    ...dataCapitalGain,
                    unitOfMutualFunds: e.target.value,
                  })
                }
                checked={dataCapitalGain.unitOfMutualFunds === 'no'}
                value='no'
                type='radio'
                name='account2'
                id='accountNo2'
              />
              <label htmlFor='accountNo2'>No</label>
            </Grid>
            <Grid item xs={2}>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'center',
                }}
                htmlFor='file6'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{fileCapitalGain?.unitOfMutualFunds?.name}</p>
              </label>
              <input
                type='file'
                id='file6'
                hidden
                onChange={(e) => {
                  setFileCapitalGain({
                    ...fileCapitalGain,
                    unitOfMutualFunds: e.target.files[0],
                  });
                }}
              />
            </Grid>
            <Grid item xs='4'>
              <label
                style={{
                  width: '100%',
                }}
                htmlFor='name'
              >
                Have you withdrawn balance from Pension Fund Manager
              </label>
            </Grid>
            <Grid item xs={3}>
              <input
                onChange={(e) =>
                  setDataCapitalGain({
                    ...dataCapitalGain,
                    pensionFundManager: e.target.value,
                  })
                }
                checked={dataCapitalGain.pensionFundManager === 'yes'}
                value='yes'
                type='radio'
                name='account3'
                id='accountYes3'
              />
              <label htmlFor='accountYes3'>Yes</label>
            </Grid>
            <Grid item xs={3}>
              <input
                onChange={(e) =>
                  setDataCapitalGain({
                    ...dataCapitalGain,
                    pensionFundManager: e.target.value,
                  })
                }
                checked={dataCapitalGain.pensionFundManager === 'no'}
                value='no'
                type='radio'
                name='account3'
                id='accountNo3'
              />
              <label htmlFor='accountNo3'>No</label>
            </Grid>
            <Grid item xs={2}>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'center',
                }}
                htmlFor='file7'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{fileCapitalGain?.pensionFundManager?.name}</p>
              </label>
              <input
                type='file'
                id='file7'
                hidden
                onChange={(e) => {
                  setFileCapitalGain({
                    ...fileCapitalGain,
                    pensionFundManager: e.target.files[0],
                  });
                }}
              />
            </Grid>
          </Grid>
        </div>
        <NtnSubmitBtn
          onClick={() => {
            if (
              dataCapitalGain.pensionFundManager === 'yes' &&
              fileCapitalGain.pensionFundManager == null
            ) {
              errorToast(
                'Kindly upload file in Have you withdrawn balance from Pension Fund Manager'
              );
              return;
            }
            if (
              dataCapitalGain.soldSharesOfListedCompanies === 'yes' &&
              fileCapitalGain.soldSharesOfListedCompanies == null
            ) {
              errorToast(
                'Kindly upload file in Have you sold shares of Listed Companies'
              );
              return;
            }
            if (
              dataCapitalGain.unitOfMutualFunds === 'yes' &&
              fileCapitalGain.unitOfMutualFunds == null
            ) {
              errorToast(
                'Kindly upload file in Have you disposed of units of mutual funds'
              );
              return;
            }

            if (
              Object.values(dataCapitalGain.properties).every((i) =>
                Object.values(i).every((i) => i)
              )
            ) {
              const data = {
                properties: dataCapitalGain.properties,
              };
              dispatch(
                submitNTNFile(
                  data,
                  fileCapitalGain,
                  setDataCapitalGain,
                  setFileCapitalGain,
                  user,
                  initState.capitalGain,
                  'Other Sources (Capital Gain)',
                  true,
                  navigate
                )
              );
            } else {
              errorToast('Fill all property value');
            }
          }}
        >
          Proceed
        </NtnSubmitBtn>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default CapitalGain;
