import styled from 'styled-components';

export const ContactFromContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 450px;
  background-color: #fac201;

  & > strong {
    font-size: 30px;
    text-align: center;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    height: 100%;
    align-items: center;
    & > textarea {
      height: 150px;
      width: 80%;
      border: none;
      padding: 8px 20px;
      border-radius: 3px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    & > input {
      padding: 8px 20px;
      border: none;
      width: 80%;
      border-radius: 3px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 990px) {
    padding: 20px;
    height: fit-content;
    & > strong {
      font-size: 20px;
    }
    & > form {
      margin-top: 20px;
      height: 100%;

      & > textarea {
        margin-bottom: 10px;
      }

      & > input {
        margin-bottom: 10px;
      }
    }
    #contact-us-btn {
      width: 80%;
    }
  }
  @media (max-width: 720px) {
    display: none;
  }
`;
