import { Add, Remove } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setThankYou } from '../../../redux-store/user/userReducer';
import NtnContainer from '../NtnContainer';
import {
  NtnFormInput,
  NtnMainContainer,
  NtnSubmitBtn,
} from '../components/style/styleNtn';
import './FinalCalculator.scss';

const initValueNonBusiness = {
  property: '',
  unit: '',
  propertyType: '',
  city: '',
  area: '',
  share: '',
  amount: 0,
};

const initValueLand = {
  type: '',
  chakNo: '',
  tehsil: '',
  district: '',
  area: '',
  share: '',
  amount: 0,
};

const businessCapital = {
  description: '',
  share: '',
  amount: 0,
};

const equipmentCapital = {
  description: '',
  amount: 0,
};

const investments = {
  insitutionName: '',
  amount: 0,
};

const motor = {
  carType: '',
  registrationNo: '',
  maker: '',
  capacity: '',
  amount: 0,
};

const preciousPossession = {
  description: '',
  amount: 0,
};

const householdEffect = {
  description: '',
  amount: 0,
};

const notesAndCoins = {
  selectForm: '',
  openingDate: '',
  accountNo: '',
  bankName: '',
  branch: '',
  share: '',
  amount: 0,
};

const otherAssets = {
  description: '',
  amount: 0,
};
const otherAssetsOnOtherName = {
  description: '',
  amount: 0,
};
const creditors = {
  form: '',
  creditorCNIC: '',
  creditorName: '',
  amount: 0,
};

const AmountForm = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    nonBusiness: [],
    totalNonBusiness: 0,
    land: [],
    totalLand: 0,
    business: [],
    totalBusiness: 0,
    equipment: [],
    totalEquipment: 0,
    investments: [],
    totalInvestment: 0,
    ownMotors: [],
    totalOwnMotor: 0,
    previousPossession: [],
    totalPreviousPossess: 0,
    houseHoldEffect: [],
    totalHouseHoldEffect: 0,
    notesAndCoinsAmount: 0,
    notesAndCoins: [],
    cashInHandAndBank: 0,
    otherAssets: [],
    totalOtherAsset: 0,
    assetsOnOtherName: [],
    totalAssetsOnOtherNames: 0,
    totalAssets: 0,
    creditors: [],
    totalCreditors: 0,
    netAssetsPrevious: 0,
    currentNetAssets: 0,
    totalInflow: 0,
    salaryIncome: 0,
    businessIncome: 0,
    rentalIncome: 0,
    otherIncomeFinalFixedTax: 0,
    otherIncomePensionAndRentalIncome: 0,
    foreignRemittance: 0,
    inheritance: 0,
    gift: 0,
    immovableProperty: 0,
    others: 0,
    totalOutflow: 0,
    totalPersonalExpenses: 0,
    rent: 0,
    ratesTaxesCharges: 0,
    motorExpenses: 0,
    foreignTravel: 0,
    electricity: 0,
    water: 0,
    gas: 0,
    telephone: 0,
    mobile: 0,
    insuranceSecurity: 0,
    medical: 0,
    childrenEducation: 0,
    functionGathering: 0,
    donationZakatCharityAnnuity: 0,
    houseHoldServantsFunctionsFoodingClothing: 0,
    outflowGift: 0,
    lossDisposalAssets: 0,
    outflowOthers: 0,
    unReconciledAmount: 0,
  });

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        totalAssets:
          parseInt(prev.totalAssetsOnOtherNames || 0) +
          parseInt(prev.totalBusiness || 0) +
          parseInt(prev.cashInHandAndBank || 0) +
          parseInt(prev.totalEquipment || 0) +
          parseInt(prev.totalInvestment || 0) +
          parseInt(prev.totalOtherAsset || 0) +
          parseInt(prev.totalPreviousPossess || 0) +
          parseInt(prev.totalLand || 0) +
          parseInt(prev.totalNonBusiness || 0) +
          parseInt(prev.totalOwnMotor || 0) +
          parseInt(prev.totalHouseHoldEffect || 0),
      };
    });
  }, [
    state.totalAssetsOnOtherNames,
    state.totalBusiness,
    state.cashInHandAndBank,
    state.totalEquipment,
    state.totalPreviousPossess,
    state.totalOtherAsset,
    state.totalInvestment,
    state.totalLand,
    state.totalNonBusiness,
    state.totalOwnMotor,
    state.totalHouseHoldEffect,
  ]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      currentNetAssets: prev.totalAssets - prev.netAssetsPrevious,
    }));
  }, [state.totalAssets, state.netAssetsPrevious]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      totalInflow:
        parseInt(prev.salaryIncome) +
        parseInt(prev.businessIncome) +
        parseInt(prev.rentalIncome) +
        parseInt(prev.otherIncomeFinalFixedTax) +
        parseInt(prev.otherIncomePensionAndRentalIncome) +
        parseInt(prev.foreignRemittance) +
        parseInt(prev.inheritance) +
        parseInt(prev.gift) +
        parseInt(prev.immovableProperty) +
        parseInt(prev.others),
    }));
  }, [
    state.salaryIncome,
    state.businessIncome,
    state.rentalIncome,
    state.otherIncomeFinalFixedTax,
    state.otherIncomePensionAndRentalIncome,
    state.foreignRemittance,
    state.inheritance,
    state.gift,
    state.immovableProperty,
    state.others,
  ]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      totalPersonalExpenses:
        parseInt(prev.rent) +
        parseInt(prev.ratesTaxesCharges) +
        parseInt(prev.motorExpenses) +
        parseInt(prev.foreignTravel) +
        parseInt(prev.electricity) +
        parseInt(prev.water) +
        parseInt(prev.gas) +
        parseInt(prev.telephone) +
        parseInt(prev.mobile) +
        parseInt(prev.insuranceSecurity) +
        parseInt(prev.medical) +
        parseInt(prev.childrenEducation) +
        parseInt(prev.functionGathering) +
        parseInt(prev.donationZakatCharityAnnuity) +
        parseInt(prev.houseHoldServantsFunctionsFoodingClothing),
    }));
  }, [
    state.rent,
    state.ratesTaxesCharges,
    state.motorExpenses,
    state.foreignTravel,
    state.electricity,
    state.water,
    state.gas,
    state.telephone,
    state.mobile,
    state.insuranceSecurity,
    state.medical,
    state.childrenEducation,
    state.functionGathering,
    state.donationZakatCharityAnnuity,
    state.houseHoldServantsFunctionsFoodingClothing,
  ]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      totalOutflow:
        parseInt(prev.totalPersonalExpenses || 0) +
        parseInt(prev.outflowGift || 0) +
        parseInt(prev.lossDisposalAssets || 0) +
        parseInt(prev.outflowOthers || 0),
    }));
  }, [
    state.totalPersonalExpenses,
    state.outflowGift,
    state.lossDisposalAssets,
    state.outflowOthers,
  ]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unReconciledAmount:
        parseInt(prev.totalInflow || 0) + parseInt(prev.totalOutflow || 0),
    }));
  }, [state.totalOutflow, state.totalInflow]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      cashInHandAndBank:
        parseInt(prev.notesAndCoinsAmount || 0) +
        prev.notesAndCoins.reduce((prev, curr) => {
          return prev + parseInt(curr.amount || 0);
        }, 0),
    }));
  }, [JSON.stringify(state.notesAndCoins), state.notesAndCoinsAmount]);

  return (
    <NtnContainer>
      <NtnMainContainer id='finalCalculatorContainer'>
        <h2>
          1. Residential, Commercial, Industrial Property (Non-Business){' '}
          {state.totalNonBusiness}
        </h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                (House, Flat, Shop, Plaza, Factory, Workshop, etc.)
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Unit No. / Complex / Street / Block / Sector
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Area / Locality / Road
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                City
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Area (Marla / sq. yd.)
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Share %
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      nonBusiness: [
                        ...prev.nonBusiness,
                        { ...initValueNonBusiness },
                      ],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.nonBusiness.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.nonBusiness;
                      tempList[idx].property = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        nonBusiness: tempList,
                      }));
                    }}
                    value={item.property}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.nonBusiness;
                      tempList[idx].unit = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        nonBusiness: tempList,
                      }));
                    }}
                    value={item.unit}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    value={item.propertyType}
                    onChange={(e) => {
                      const tempList = state.nonBusiness;
                      tempList[idx].propertyType = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        nonBusiness: tempList,
                      }));
                    }}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    value={item.city}
                    onChange={(e) => {
                      const tempList = state.nonBusiness;
                      tempList[idx].city = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        nonBusiness: tempList,
                      }));
                    }}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    value={item.area}
                    onChange={(e) => {
                      const tempList = state.nonBusiness;
                      tempList[idx].area = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        nonBusiness: tempList,
                      }));
                    }}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    value={item.share}
                    type='number'
                    onChange={(e) => {
                      const tempList = state.nonBusiness;
                      tempList[idx].share = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        nonBusiness: tempList,
                      }));
                    }}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    value={item.amount}
                    type='number'
                    onChange={(e) => {
                      const tempList = state.nonBusiness;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        nonBusiness: tempList,
                        totalNonBusiness: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.nonBusiness.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          nonBusiness: tempList,
                          totalNonBusiness: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>2. AGRICULTURAL PROPERTY - LAND {state.totalLand}</h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                (Irrigated / Unirrigated / Uncultivable)
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Mauza / Village / Chak No.{' '}
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Tehsil
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                District
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Area (Acre)
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Share %
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      land: [...prev.land, { ...initValueLand }],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.land.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.land;
                      tempList[idx].type = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        land: tempList,
                      }));
                    }}
                    value={item.type}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.land;
                      tempList[idx].chakNo = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        land: tempList,
                      }));
                    }}
                    value={item.chakNo}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.land;
                      tempList[idx].tehsil = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        land: tempList,
                      }));
                    }}
                    value={item.tehsil}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.land;
                      tempList[idx].district = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        land: tempList,
                      }));
                    }}
                    value={item.district}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.land;
                      tempList[idx].area = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        land: tempList,
                      }));
                    }}
                    value={item.area}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.land;
                      tempList[idx].share = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        land: tempList,
                      }));
                    }}
                    value={item.share}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.land;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        land: tempList,
                        totalLand: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.land.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          land: tempList,
                          totalLand: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>3. BUSINESS CAPITAL {state.totalBusiness}</h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Enter name, share percentage & capital amount in each AOP{' '}
              </th>

              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Share %
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      business: [...prev.business, { ...businessCapital }],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.business.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.business;
                      tempList[idx].description = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        business: tempList,
                      }));
                    }}
                    value={item.description}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.business;
                      tempList[idx].share = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        business: tempList,
                      }));
                    }}
                    value={item.share}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.business;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        business: tempList,
                        totalBusiness: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.business.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          business: tempList,
                          totalBusiness: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>4. Equipment {state.totalEquipment}</h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Description
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      equipment: [...prev.equipment, { ...equipmentCapital }],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.equipment.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.equipment;
                      tempList[idx].description = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        equipment: tempList,
                      }));
                    }}
                    value={item.description}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.equipment;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        equipment: tempList,
                        totalEquipment: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.equipment.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          equipment: tempList,
                          totalEquipment: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>
          5. Investments (Shares, Debentures, Receivables Etc.){' '}
          {state.totalInvestment}
        </h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Institution Name
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      investments: [...prev.investments, { ...investments }],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.investments.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.investments;
                      tempList[idx].description = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        investments: tempList,
                      }));
                    }}
                    value={item.description}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.investments;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        investments: tempList,
                        totalInvestment: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.investments.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          investments: tempList,
                          totalInvestment: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>6. Motor Vehicle in Own Name {state.totalOwnMotor}</h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                (Car,Jeep,Motor Cycle,Scooter,Van)
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                E&TD Registration No.
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Maker
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Capacity
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      ownMotors: [...prev.ownMotors, { ...motor }],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.ownMotors.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.ownMotors;
                      tempList[idx].carType = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        ownMotors: tempList,
                      }));
                    }}
                    value={item.carType}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.ownMotors;
                      tempList[idx].registrationNo = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        ownMotors: tempList,
                      }));
                    }}
                    value={item.registrationNo}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.ownMotors;
                      tempList[idx].maker = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        ownMotors: tempList,
                      }));
                    }}
                    value={item.maker}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.ownMotors;
                      tempList[idx].capacity = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        ownMotors: tempList,
                      }));
                    }}
                    value={item.capacity}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.ownMotors;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        ownMotors: tempList,
                        totalOwnMotor: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.ownMotors.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          ownMotors: tempList,
                          totalOwnMotor: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>7. Precious Possession {state.totalPreviousPossess}</h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Description
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      previousPossession: [
                        ...prev.previousPossession,
                        { ...preciousPossession },
                      ],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.previousPossession.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.previousPossession;
                      tempList[idx].description = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        previousPossession: tempList,
                      }));
                    }}
                    value={item.description}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.previousPossession;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        previousPossession: tempList,
                        totalPreviousPossess: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.previousPossession.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          previousPossession: tempList,
                          totalPreviousPossess: tempList.reduce(
                            (prev, curr) => {
                              return prev + parseInt(curr.amount || 0);
                            },
                            0
                          ),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>8. Household Effect {state.totalHouseHoldEffect}</h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Description
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      houseHoldEffect: [
                        ...prev.houseHoldEffect,
                        { ...householdEffect },
                      ],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.houseHoldEffect.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.houseHoldEffect;
                      tempList[idx].description = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        houseHoldEffect: tempList,
                      }));
                    }}
                    value={item.description}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.houseHoldEffect;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        houseHoldEffect: tempList,
                        totalHouseHoldEffect: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.houseHoldEffect.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          houseHoldEffect: tempList,
                          totalHouseHoldEffect: tempList.reduce(
                            (prev, curr) => {
                              return prev + parseInt(curr.amount || 0);
                            },
                            0
                          ),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>9. Cash in Hand / Cash at Bank {state.cashInHandAndBank}</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2>Notes & Coins</h2>
          <NtnFormInput
            width={'100px !important'}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                notesAndCoinsAmount: parseInt(e.target.value || 0),
              }));
            }}
            value={state.notesAndCoinsAmount}
          />
        </div>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Select FORM{' '}
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Opening Date
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Account no
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Bank Name
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Branch
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Share %
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      notesAndCoins: [
                        ...prev.notesAndCoins,
                        { ...notesAndCoins },
                      ],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.notesAndCoins.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.notesAndCoins;
                      tempList[idx].selectForm = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        notesAndCoins: tempList,
                      }));
                    }}
                    value={item.selectForm}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.notesAndCoins;
                      tempList[idx].openingDate = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        notesAndCoins: tempList,
                      }));
                    }}
                    value={item.openingDate}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.notesAndCoins;
                      tempList[idx].accountNo = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        notesAndCoins: tempList,
                      }));
                    }}
                    value={item.accountNo}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.notesAndCoins;
                      tempList[idx].bankName = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        notesAndCoins: tempList,
                      }));
                    }}
                    value={item.bankName}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.notesAndCoins;
                      tempList[idx].branch = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        notesAndCoins: tempList,
                      }));
                    }}
                    value={item.branch}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.notesAndCoins;
                      tempList[idx].share = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        notesAndCoins: tempList,
                      }));
                    }}
                    value={item.share}
                  />
                </td>
                <td>
                  <NtnFormInput
                    width={'100px !important'}
                    onChange={(e) => {
                      const tempList = state.notesAndCoins;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        notesAndCoins: tempList,
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.notesAndCoins.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          notesAndCoins: tempList,
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>
          10. Any Other Asset (Not specified above) {state.totalOtherAsset}
        </h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Description
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      otherAssets: [...prev.otherAssets, { ...otherAssets }],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.otherAssets.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.otherAssets;
                      tempList[idx].description = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        otherAssets: tempList,
                      }));
                    }}
                    value={item.description}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.otherAssets;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        otherAssets: tempList,
                        totalOtherAsset: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.land.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          land: tempList,
                          totalLand: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>
          11. Assets in Others' Name (Write here assets in name of your
          dependants) {state.totalAssetsOnOtherNames}
        </h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Description
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      assetsOnOtherName: [
                        ...prev.assetsOnOtherName,
                        { ...otherAssetsOnOtherName },
                      ],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.assetsOnOtherName.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.assetsOnOtherName;
                      tempList[idx].description = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        assetsOnOtherName: tempList,
                      }));
                    }}
                    value={item.description}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.assetsOnOtherName;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        assetsOnOtherName: tempList,
                        totalAssetsOnOtherNames: tempList.reduce(
                          (prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          },
                          0
                        ),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.assetsOnOtherName.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          assetsOnOtherName: tempList,
                          totalAssetsOnOtherNames: tempList.reduce(
                            (prev, curr) => {
                              return prev + parseInt(curr.amount || 0);
                            },
                            0
                          ),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>Total Assets {state.totalAssets}</h2>
        <h2>
          12. Creditors (Payable to banks, Credit Card payables etc.){' '}
          {state.totalCreditors}
        </h2>
        <table
          style={{
            maxWidth: '90vw',
            overflowY: 'auto',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Select FORM
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Type of payable
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Creditor's Name
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Amount
              </th>
              <th>
                <IconButton
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      creditors: [...prev.creditors, { ...creditors }],
                    }));
                  }}
                >
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.creditors.map((item, idx) => (
              <tr>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.creditors;
                      tempList[idx].form = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        creditors: tempList,
                      }));
                    }}
                    value={item.form}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.creditors;
                      tempList[idx].creditorCNIC = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        creditors: tempList,
                      }));
                    }}
                    value={item.creditorCNIC}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.creditors;
                      tempList[idx].creditorName = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        creditors: tempList,
                      }));
                    }}
                    value={item.creditorName}
                  />
                </td>
                <td>
                  <NtnFormInput
                    onChange={(e) => {
                      const tempList = state.creditors;
                      tempList[idx].amount = e.target.value;
                      setState((prev) => ({
                        ...prev,
                        creditors: tempList,
                        totalCreditors: tempList.reduce((prev, curr) => {
                          return prev + parseInt(curr.amount || 0);
                        }, 0),
                      }));
                    }}
                    value={item.amount}
                    type='number'
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setState((prev) => {
                        const tempList = prev.creditors.filter(
                          (_, index) => index !== idx
                        );
                        return {
                          ...prev,
                          creditors: tempList,
                          totalCreditors: tempList.reduce((prev, curr) => {
                            return prev + parseInt(curr.amount || 0);
                          }, 0),
                        };
                      });
                    }}
                  >
                    <Remove />
                  </IconButton>
                </td>
              </tr>
            ))}
            <br />
            <tr>
              <td colSpan='3'>
                Net Assets as on 30-06-{new Date().getFullYear()}
              </td>
              <td colSpan='2'>{state.totalAssets}</td>
            </tr>
            <tr>
              <td colSpan='3'>
                Net Assets as on 30-06-{new Date().getFullYear() - 1}
              </td>
              <td colSpan='2'>
                <NtnFormInput
                  type='number'
                  value={state?.netAssetsPrevious}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        netAssetsPrevious: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan='3'>Increase / Decrease in Assets</td>
              <td colSpan='2'>{state.currentNetAssets}</td>
            </tr>
          </tbody>
        </table>
        <h2>Wealth Reconciliation Statement</h2>
        <h2>Inflows {state.totalInflow}</h2>
        <table>
          <thead>
            <tr>
              <th>Particular</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Salary Income</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.salaryIncome}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        salaryIncome: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Business Income</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.businessIncome}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        businessIncome: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Rental Income</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.rentalIncome}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        rentalIncome: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Other Source Income - Final / Fixed Tax</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.otherIncomeFinalFixedTax}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        otherIncomeFinalFixedTax: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Other Source Income - Pension and Rental Income</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.otherIncomePensionAndRentalIncome}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        otherIncomePensionAndRentalIncome: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Foreign Remittance</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.foreignRemittance}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        foreignRemittance: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Inheritance</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.inheritance}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        inheritance: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Gift</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.gift}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        gift: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Gain on Disposal of Assets, excluding Capital Gain on Immovable
                Property
              </td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.immovableProperty}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        immovableProperty: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Others</td>
              <td>
                {' '}
                <NtnFormInput
                  type='number'
                  value={state?.others}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        others: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <h2>Personal Expenses {state.totalPersonalExpenses}</h2>
        <table>
          <thead>
            <tr>
              <th>Particular</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Rent</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.rent}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        rent: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Rates / Taxes / Charges</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.ratesTaxesCharges}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        ratesTaxesCharges: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Motor Expenses</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.motorExpenses}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        motorExpenses: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Foreign Travel</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.foreignTravel}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        foreignTravel: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Electricity</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.electricity}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        electricity: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Water</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.water}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        water: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Gas</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.gas}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        gas: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Telephone</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.telephone}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        telephone: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.mobile}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        mobile: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Insurance / Security</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.insuranceSecurity}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        insuranceSecurity: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Medical</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.medical}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        medical: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Children Education</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.childrenEducation}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        childrenEducation: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Function / Gathering</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.functionGathering}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        functionGathering: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Donation / Zakat / Charity / Annuity</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.donationZakatCharityAnnuity}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        donationZakatCharityAnnuity: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>House Hold / Servants / Functions / Fooding & Clothing</td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.houseHoldServantsFunctionsFoodingClothing}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        houseHoldServantsFunctionsFoodingClothing:
                          e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <h2>OutFlows {state.totalOutflow}</h2>
        <table>
          <thead>
            <tr>
              <th>Particular</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Personal Expenses </td>
              <td>
                <NtnFormInput
                  type='number'
                  disable
                  value={state?.totalPersonalExpenses}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        totalPersonalExpenses: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Gift </td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.outflowGift}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        outflowGift: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Loss on Disposal of Assets </td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.lossDisposalAssets}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        lossDisposalAssets: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Others </td>
              <td>
                <NtnFormInput
                  type='number'
                  value={state?.outflowOthers}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        outflowOthers: e.target.value,
                      };
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <h2>
          Increase/ Decrease in Assets (From Above) {state.currentNetAssets}
        </h2>
        <h2>Unreconciled Amount {state.unReconciledAmount}</h2>

        <NtnSubmitBtn
          onClick={() => {
            // PrintElem('finalCalculatorContainer');
            dispatch(setThankYou(true));
            // onSubmit();
          }}
        >
          Save
        </NtnSubmitBtn>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default AmountForm;
