import Dashboard from '../pages/dashboard/Dashboard';
import {
  Folder,
  HomeOutlined,
  FolderOutlined,
  Notifications,
  DocumentScanner,
} from '@mui/icons-material';
import CaseLawSearch from '../pages/caselaw/CaseLawSearch';
import StatutesSearch from '../pages/statutes/StatutesSearch';
import NotificationSearch from '../pages/notification/NotificationSearch';
import DictionarySearch from '../pages/lawDictionary/DictionarySearch';
import PdfGenerater from '../components/table/pdfGenerater';

export const LibraryRoutes = [
  {
    title: 'Dashboard',
    path: '/library/dashboard',
    component: <Dashboard />,
    isMain: true,
    icon: <HomeOutlined />,
  },
  {
    title: 'Case Law',
    path: '/library/case-law',
    component: <CaseLawSearch />,
    isMain: true,
    icon: <Folder />,
  },
  {
    title: 'PDF Generater',
    path: '/library/pdf-generater',
    component: <PdfGenerater />,
    isMain: true,
    icon: <Folder />,
  },
  {
    title: 'Statutes',
    path: '/library/statutes',
    component: <StatutesSearch />,
    isMain: true,
    icon: <FolderOutlined />,
  },
  {
    title: 'Notification',
    path: '/library/notification',
    component: <NotificationSearch />,
    isMain: true,
    icon: <Notifications />,
  },
  {
    title: 'Dictionary',
    path: '/library/dictionary',
    component: <DictionarySearch />,
    isMain: true,
    icon: <DocumentScanner />,
  },
];
