import React from 'react';
import { useNavigate } from 'react-router-dom';
import arrowBtn from '../../../../../assets/arrowBtn.svg';
import { Card } from '../../../../../global/style/StyleComponent';

const Cards = ({ ImageComponent, title, description }) => {
  const navigate = useNavigate();
  return (
    <Card>
      <div className='imgCardFile'>
        <ImageComponent />
      </div>
      <span>{title}</span>
      <p>{description}</p>
      <div className='cardReadMoreBtn'>
        <span
          onClick={() => {
            navigate('/services');
          }}
          style={{
            fontWeight: 'bolder',
            cursor: 'pointer',
          }}
        >
          Read More
        </span>
        <img
          style={{
            marginLeft: '10px',
          }}
          src={arrowBtn}
          alt=''
        />
      </div>
    </Card>
  );
};

export default Cards;
