import React from 'react';
import { MainContainer, Btn } from '../../../../global/style/StyleComponent';

import guarantee from '../../../../assets/garentee.png';
import { ReactComponent as CompanyRegistration } from '../../../../assets/New folder/Company Registration.svg';
import { ReactComponent as CorporateFiling } from '../../../../assets/New folder/Corporate Filing.svg';
import { ReactComponent as IncomeTaxFiling } from '../../../../assets/New folder/Income tax Filing.svg';
import { ReactComponent as NtnRegistration } from '../../../../assets/New folder/NTN Registration.svg';
import { ReactComponent as OverseasChapter } from '../../../../assets/New folder/Overseas chapter.svg';
import { ReactComponent as SaleTaxFiling } from '../../../../assets/New folder/Sale tax Filing.svg';
import { ReactComponent as TaxAndLegalCitation } from '../../../../assets/New folder/Tax and Legal Citation.svg';
import { ReactComponent as TrademarkRegistration } from '../../../../assets/New folder/Trademark Registration.svg';
import './AboutUs.css';
import Cards from './components/Cards';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <MainContainer bgColor='#f3f3f3'>
      <div className='aboutUsContainer'>
        <div className='left'>
          <h2 className='aboutUsHead'>About Us</h2>
          <span className='aboutUsSubHead'>
            Try Taxpress and <strong>Forget the Rest!</strong>
          </span>

          <div className='aboutUsGuaranteeItem'>
            <div>
              <img
                src={guarantee}
                alt='guarantee'
                className='aboutUsGuarantee'
              />
              <span>Get your Businesses registered and regularized</span>
            </div>
            <div>
              <img
                src={guarantee}
                alt='guarantee'
                className='aboutUsGuarantee'
              />
              <span>File your income tax returns with us</span>
            </div>
          </div>
          <p>
            Our aim is to make the process of business registration simple and
            quick, freeing our clients from every hassle.
            <br />
            <br />
            At Taxpress, we strive hard to make your return filing convenient
            and quick. Come and be an active taxpayer within minutes with the
            most efficient and reliable online filing portal.
          </p>

          <Btn
            onClick={() => navigate('/salary-tax-calculator')}
            color='#194173'
            hoverColor='#fcc028'
            textColor='#fff'
            hoverTextColor='#373f68'
          >
            Try Free
          </Btn>
        </div>
        <div className='right'>
          <div className='aboutCategoryCard'>
            <Cards
              ImageComponent={NtnRegistration}
              text='NTN Registration'
              route={'services'}
            />
            <Cards
              ImageComponent={IncomeTaxFiling}
              text='Income Tax filing'
              route={'services'}
            />
            <Cards
              ImageComponent={SaleTaxFiling}
              text='Sale Tax filing'
              route={'services'}
            />
          </div>
          <div className='aboutCategoryCard'>
            <Cards
              ImageComponent={CompanyRegistration}
              text='company registration'
              route={'services'}
            />
            <Cards
              ImageComponent={CorporateFiling}
              text='Corporate Filing'
              route={'services'}
            />
            <Cards
              ImageComponent={TrademarkRegistration}
              text='Trademark Registration'
              route={'services'}
            />
          </div>
          <div className='aboutCategoryCard'>
            <Cards
              ImageComponent={OverseasChapter}
              text='overseas services'
              route={'/overseas-chapter'}
            />
            <Cards
              ImageComponent={TaxAndLegalCitation}
              text='Tax & Legal Citation'
              route={'/services'}
            />
            <div
              style={{
                width: '150px',
                height: '150px',
              }}
            ></div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default AboutUs;
