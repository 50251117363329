import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAppointment } from '../../../redux-store/home/homeReducer';
import { MainContainer } from '../../style/StyleComponent';
import './Footer.css';

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className='footerContainer'>
      <MainContainer bgColor='transparent'>
        <div className='footer'>
          <div className='footerDetail'>
            <img src={require('../../../assets/TP Logo footer.png')} alt='' />
            <span>
              Our aim is to make the process of business registration simple and
              quick, freeing our clients from every hassle. Come and be an
              active taxpayer within minutes with the most efficient and
              reliable online filing portal.
            </span>
            <span>
              Address: Office 112, South Tower, Stock Exchange Building, Near
              Aiwan e Iqbal, Lahore
            </span>
            <span>Phone: +923328626415</span>
            <span>Email: info@taxpress.com.pk</span>
          </div>
          <div
            onClick={() => window.scrollTo(0, 0)}
            id='firstFooterLinks'
            className='footerLinks'
          >
            <strong className='footerHeader'>TaxPress Profile</strong>
            <Link to='/about-us'>About Us</Link>
            <Link to='/privacy-policy'>Privacy Policy</Link>
            <Link to='/terms-and-conditions'>Terms & Conditions</Link>
            <Link to='/faq'>Help & FAQs</Link>
            <Link to='/contact'>Contact us</Link>
          </div>
          <div onClick={() => window.scrollTo(0, 0)} className='footerLinks'>
            <strong className='footerHeader'>TaxPress links</strong>
            <a onClick={() => dispatch(setAppointment())}>Appointment</a>
            <Link to='/overseas-chapter'>Overseas chapter</Link>
            <Link to='/salary-tax-calculator'>Salary Calculator</Link>
            <Link to='/registration'>NTN Registration</Link>
            <Link to='/registration'>Tax Submission</Link>
          </div>
          <div onClick={() => window.scrollTo(0, 0)} className='footerLinks'>
            <strong className='footerHeader'>TaxPress Services</strong>
            <Link to='/services'>Trademark Registration</Link>
            <Link to='/services'>Tax and Legal Citation</Link>
            <Link to='/services'>Company Registration</Link>
            <Link to='/services'>Corporate Filing</Link>
            <Link to='/services'>Sale tax Filing</Link>
          </div>
        </div>
      </MainContainer>
      <div
        onClick={() => {
          navigate('/fileYourTaxReturn');
        }}
        className='footerCopyWrite'
      >
        <strong>© Copyright - Taxpress 2022</strong>
      </div>
    </div>
  );
};

export default Footer;
