import React from 'react';
import Footer from '../global/components/footer/Footer';
import NavHeader from '../global/components/header/NavHeader';
import TopLevelHeader from '../global/components/header/TopLevelHeader';
import NavBarLibrary from '../panels/library/components/navbar/NavBarLibrary';
import Features from './Home/components/bottom/Features';

const HeaderFooter = ({ children, isLibrary }) => {
  return (
    <>
      <TopLevelHeader />
      {isLibrary ? <NavBarLibrary /> : <NavHeader />}
      {children}
      <Features />
      <Footer />
    </>
  );
};

export default HeaderFooter;
