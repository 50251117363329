import AxiosCall from '../../api/AxiosCall';
import { errorToast, successToast } from '../../global/toast';
import {
  setAppointmentSlot,
  setBackground,
  setClients,
  setTeam,
  setTopResearch,
} from './homeReducer';

export const getTopResearchAndInsight = () => (dispatch) => {
  AxiosCall.get('/insights/getTopInsights')
    .then((res) => {
      dispatch(setTopResearch(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTeam = () => (dispatch) => {
  AxiosCall.get('/team/getAllMembers')
    .then((res) => {
      dispatch(setTeam(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBackground = () => (dispatch) => {
  AxiosCall.get(`/backgrounds/getAllBGs`)
    .then((res) => {
      dispatch(setBackground(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAppointmentSlot = () => (dispatch) => {
  AxiosCall.get('/appointments/getAppointmentSlots')
    .then((res) => {
      dispatch(setAppointmentSlot(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createAppointment = (data, cb) => (dispatch) => {
  AxiosCall.post('/appointments/bookeAppointmentSlot', data)
    .then((res) => {
      cb();
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};

export const getClients = () => (dispatch) => {
  AxiosCall.get('/clients/getClients')
    .then((res) => {
      dispatch(setClients(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};
