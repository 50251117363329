import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, MainContainer } from '../../../../global/style/StyleComponent';
import {
  getAllCourt,
  getAllPrinciples,
  getAllStatutes,
  getSearchCaseLaw,
} from '../../../../redux-store/library/actionCreator';
import {
  SearchButtonContainer,
  SearchContainer,
} from '../../styles/LibraryStyled';
import CaseLawList from './CaseLawList';

const CaseLawSearch = () => {
  let monthFull = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const allStatutes = useSelector((state) => state.library.allStatutes);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    principles: [],
    courts: [],
  });
  const [search, setSearch] = useState({
    year_or_vol: '',
    pageNo: '',
    month: '',
    law_or_statute: '',
    section: '',
    section2: '',
    court: '',
    caseNo: '',
    dated: '',
    textSearch1: '',
    textSearch2: '',
    phraseSearch: '',
    judge: '',
    lawyer: '',
    appellant_or_opponent: '',
    principleOfCaseLaws: '',
    journals: '',
  });
  useEffect(() => {
    dispatch(getAllStatutes());
    dispatch(getAllPrinciples(setState));
    dispatch(getAllCourt(setState));
  }, []);

  const handleChange = (e) => {
    dispatch(getSearchCaseLaw(search));
  };

  return (
    <MainContainer paddingVertical={'90px'}>
      <h1>Case Law</h1>
      <SearchContainer row={4}>
        <Input
          type='text'
          placeholder='Year / vol'
          onChange={(e) =>
            setSearch({
              ...search,
              year_or_vol: e.target.value.trim(),
            })
          }
        />
        <Input
          type='text'
          placeholder='Journals'
          onChange={(e) =>
            setSearch({
              ...search,
              journals: e.target.value.trim(),
            })
          }
        />
        <Input
          placeholder='Page No'
          type={'number'}
          onChange={(e) =>
            setSearch({
              ...search,
              pageNo: e.target.value.trim(),
            })
          }
        />
        <select
          name='month'
          id='month'
          onChange={(e) =>
            setSearch({
              ...search,
              month: e.target.value.trim(),
            })
          }
        >
          <option value=''>Month</option>
          {monthFull.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>
        <select
          name='law'
          id='law'
          style={{
            gridColumn: '1 / span 2',
          }}
          onChange={(e) => {
            setSearch({ ...search, law_or_statute: e.target.value });
          }}
        >
          <option value=''>Law/Statute</option>
          {allStatutes.map((statute, index) => (
            <option key={index} value={statute.law_or_statute.trim()}>
              {statute.law_or_statute}
            </option>
          ))}
        </select>
        <Input
          type='text'
          placeholder='Section'
          onChange={(e) =>
            setSearch({
              ...search,
              section: e.target.value.trim(),
            })
          }
        />
        <Input
          type='text'
          placeholder='Section2'
          onChange={(e) =>
            setSearch({
              ...search,
              section2: e.target.value.trim(),
            })
          }
        />
        <select
          name='court'
          id='court'
          onChange={(e) =>
            setSearch({
              ...search,
              court: e.target.value.trim(),
            })
          }
        >
          <option value=''>Court</option>
          {state.courts.map((item) => (
            <option value={item?.court}>{item?.court}</option>
          ))}
        </select>
        <Input
          type='text'
          placeholder='Case No'
          onClick={(e) =>
            setSearch({
              ...search,
              caseNo: e.target.value.trim(),
            })
          }
        />
        <Input
          type='date'
          placeholder='Dated'
          onChange={(e) =>
            setSearch({
              ...search,
              dated: e.target.value.trim(),
            })
          }
        />
        <Input
          type='text'
          placeholder='Judge'
          onChange={(e) =>
            setSearch({
              ...search,
              judge: e.target.value.trim(),
            })
          }
        />
        <Input
          type='text'
          placeholder='Lawyer'
          onChange={(e) =>
            setSearch({
              ...search,
              lawyer: e.target.value.trim(),
            })
          }
        />
        <Input
          type='text'
          placeholder='Appellant / Opponent'
          onChange={(e) =>
            setSearch({
              ...search,
              appellant_or_opponent: e.target.value,
            })
          }
        />
        <select
          style={{
            gridColumn: '3 / span 2',
          }}
          name=' Principle Of Case Laws '
          id='principle-of-case-laws'
          onChange={(e) =>
            setSearch({
              ...search,
              principleOfCaseLaws: e.target.value,
            })
          }
        >
          <option value=''>Principle Of Case Laws</option>
          {state.principles.map((item) => (
            <option value={item?.principleOfCaseLaws}>
              {item?.principleOfCaseLaws}
            </option>
          ))}
        </select>
        <SearchButtonContainer gridColumn={4}>
          <button onClick={handleChange}>Search</button>
        </SearchButtonContainer>
      </SearchContainer>
      <CaseLawList key={'item'} />
    </MainContainer>
  );
};

export default CaseLawSearch;
