import React from 'react';
import { MainContainer } from '../../../../global/style/StyleComponent';
import { ReactComponent as SecureData } from '../../../../assets/footer/01.svg';
import { ReactComponent as TrustedBy } from '../../../../assets/footer/02.svg';
import { ReactComponent as SecuredPayment } from '../../../../assets/footer/03.svg';
import { ReactComponent as OnTimeSubmission } from '../../../../assets/footer/04.svg';
import Facebook from '../../../../assets/facebook-f-brands.svg';
import Twitter from '../../../../assets/twitter-brands.svg';
import Insta from '../../../../assets/instagram-brands.svg';
import LinkedIn from '../../../../assets/linkedin-brands.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeaturesCards from './components/FeaturesCards';
import './Features.css';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function Features() {
  return (
    <>
      <MainContainer id='featureContainer' bgColor='#fff'>
        <div className='features'>
          <FeaturesCards
            Icon={SecureData}
            head='secure data'
            body={'Data security is a top concern  of Taxpress.'}
          />
          <FeaturesCards
            Icon={SecuredPayment}
            head='QUICK FILING'
            body={'100% Payment Protection & Easy Return'}
          />
          <FeaturesCards
            Icon={TrustedBy}
            head='EXPERIENCED TEAM'
            body={'We engage highly experienced  Team in our Ecosystem'}
          />
          <FeaturesCards
            Icon={OnTimeSubmission}
            head='24/7 AVAILABLE'
            body={'Don’t hesitate to ask question!  we are here for you!'}
          />
        </div>
      </MainContainer>
      <MainContainer bgColor='#fcc028'>
        <div className='socialContainer'>
          <div className='socialLeft'>
            <FontAwesomeIcon icon={faPaperPlane} color={'#fff'} size={'2x'} />
            <strong className='newsText'>SIGNUP to NEWSLETTER</strong>
          </div>
          <div className='newsLetterInputContainer'>
            <input
              type='text'
              placeholder='Enter your email'
              className='newsLetterInput'
            />
            <button className='newsLetterButton'>Submit</button>
          </div>
          <div className='socailLinkContainer'>
            <strong className='socialText'>Keep in touch</strong>
            <a
              href='https://www.facebook.com/taxpresspk'
              target='blank'
              className='socialLink'
            >
              <img src={Facebook} alt='' />
            </a>
            <a
              href='https://www.instagram.com/taxpresspk/'
              target='blank'
              className='socialLink'
            >
              <img src={Insta} alt='' />
            </a>
            <a
              href='https://twitter.com/Tax_press_pk'
              target='blank'
              className='socialLink'
            >
              <img src={Twitter} alt='' />
            </a>
            <a
              href='https://www.linkedin.com/in/tax-press-725982238'
              target='blank'
              className='socialLink'
            >
              <img src={LinkedIn} alt='' />
            </a>
          </div>
        </div>
      </MainContainer>
    </>
  );
}

export default Features;
