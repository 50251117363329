import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { submitNTNFile } from '../../../services/NTNServices';
import NtnContainer from '../NtnContainer';
import {
  NtnAttachBtn,
  NtnForm,
  NtnFormInput,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';

const initStat = {
  annualWithHolding: '',
  annualEmployer: '',
  monthlySaleTaxReturnFBR: '',
  quarterlyTaxStatementFRB: '',
  monthlySaleTaxReturnService: {
    check: '',
    option: '',
  },
};
const options2 = [
  { value: '', label: 'Select Options' },
  { value: 'PRA', label: 'PRA' },
  { value: 'SRB', label: 'SRB' },
  { value: 'KPRA', label: 'KPRA' },
  { value: 'BRA', label: 'BRA' },
];

const PeriodicFiling = () => {
  const user = useSelector((state) => state.users.user);
  const [data, setData] = useState(initStat);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState({
    annualWithHolding: null,
    annualEmployer: null,
    monthlySaleTaxReturnFBR: null,
    quarterlyTaxStatementFRB: null,
    monthlySaleTaxReturnService: null,
  });
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Periodic Filing</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();

            if (
              Object.keys(data)
                .filter(
                  (i) =>
                    data[i] === 'yes' ||
                    (typeof data[i] === 'object' &&
                      data[i]['check'] === 'yes' &&
                      data[i]['option'])
                )
                .every((i) => file[i])
            )
              dispatch(
                submitNTNFile(
                  data,
                  file,
                  setData,
                  setFile,
                  user,
                  initStat,
                  'Periodic Filling',
                  true,
                  navigate
                )
              );
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
            }}
          >
            <span>
              Making Periodic Filing of VAT and Withholding statements available
              on a single click
            </span>
            <Grid container spacing={'1em'}>
              <Grid item xs='7'>
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes1'
                >
                  Annual Withholding Statement – FBR{' '}
                </label>
              </Grid>
              <Grid item xs='2'>
                <NtnFormInput
                  type='checkbox'
                  id='yes1'
                  checked={data.annualWithHolding === 'yes'}
                  onChange={(e) => {
                    setData({
                      ...data,
                      annualWithHolding: e.target.checked ? 'yes' : '',
                    });
                  }}
                />{' '}
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes1'
                >
                  Yes
                </label>
              </Grid>
              <Grid item xs='2'>
                {data.annualWithHolding === 'yes' && (
                  <>
                    <input
                      type='file'
                      accept='.xlsx, .xls'
                      id='file1'
                      name='file1'
                      hidden
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          const extension = e.target.files[0].name
                            .split('.')
                            .slice(-1)[0];
                          console.log(extension);
                          if (extension === 'xlsx' || extension === 'xls') {
                            setFile({
                              ...file,
                              annualWithHolding: e.target.files[0],
                            });
                          }
                        }
                      }}
                    />
                    <label htmlFor='file1'>
                      <NtnAttachBtn>Attach Doc</NtnAttachBtn>
                      <p>{file?.annualWithHolding?.name}</p>
                    </label>
                  </>
                )}
              </Grid>
              <Grid item xs='7'>
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes2'
                >
                  Annual Employer Statement – FBR
                </label>
              </Grid>
              <Grid item xs='2'>
                <NtnFormInput
                  type='checkbox'
                  id='yes2'
                  checked={data.annualEmployer === 'yes'}
                  onChange={(e) => {
                    setData({
                      ...data,
                      annualEmployer: e.target.checked ? 'yes' : '',
                    });
                  }}
                />
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes2'
                >
                  Yes
                </label>
              </Grid>
              <Grid item xs='2'>
                {data.annualEmployer === 'yes' && (
                  <>
                    <input
                      type='file'
                      accept='.xlsx, .xls'
                      id='file2'
                      name='file2'
                      hidden
                      onChange={(e) => {
                        const extension =
                          e.target.files[0].name.split('.').slice(-1)[0] || '';
                        if (extension === 'xlsx' || extension === 'xls') {
                          setFile({
                            ...file,
                            annualEmployer: e.target.files[0],
                          });
                        }
                      }}
                    />
                    <label htmlFor='file2'>
                      <NtnAttachBtn>Attach Doc</NtnAttachBtn>
                      <p>{file?.annualEmployer?.name}</p>
                    </label>
                  </>
                )}
              </Grid>
              <Grid item xs='7'>
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes3'
                >
                  Monthly Sales Tax Return - FBR
                </label>
              </Grid>
              <Grid item xs='2'>
                <NtnFormInput
                  type='checkbox'
                  id='yes3'
                  checked={data.monthlySaleTaxReturnFBR === 'yes'}
                  onChange={(e) => {
                    setData({
                      ...data,
                      monthlySaleTaxReturnFBR: e.target.checked ? 'yes' : '',
                    });
                  }}
                />{' '}
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes3'
                >
                  Yes
                </label>
              </Grid>
              <Grid item xs='2'>
                {data.monthlySaleTaxReturnFBR === 'yes' && (
                  <>
                    <input
                      type='file'
                      accept='.xlsx, .xls'
                      id='file3'
                      hidden
                      onChange={(e) => {
                        const extension = e.target.files[0].name
                          .split('.')
                          .slice(-1)[0];
                        if (extension === 'xlsx' || extension === 'xls') {
                          setFile({
                            ...file,
                            monthlySaleTaxReturnFBR: e.target.files[0],
                          });
                        }
                      }}
                    />
                    <label htmlFor='file3'>
                      <NtnAttachBtn>Attach Doc</NtnAttachBtn>
                      <p>{file?.monthlySaleTaxReturnFBR?.name}</p>
                    </label>
                  </>
                )}
              </Grid>{' '}
              <Grid item xs='7'>
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes4'
                >
                  Quarterly withholding tax statement - FBR
                </label>
              </Grid>
              <Grid item xs='2'>
                <NtnFormInput
                  type='checkbox'
                  id='yes4'
                  checked={data.quarterlyTaxStatementFRB === 'yes'}
                  onChange={(e) => {
                    setData({
                      ...data,
                      quarterlyTaxStatementFRB: e.target.checked ? 'yes' : '',
                    });
                  }}
                />{' '}
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes4'
                >
                  Yes
                </label>
              </Grid>
              <Grid item xs='2'>
                {data.quarterlyTaxStatementFRB === 'yes' && (
                  <>
                    {' '}
                    <input
                      type='file'
                      accept='.xlsx, .xls'
                      id='file4'
                      hidden
                      onChange={(e) => {
                        const extension = e.target.files[0].name
                          .split('.')
                          .slice(-1)[0];
                        if (extension === 'xlsx' || extension === 'xls') {
                          setFile({
                            ...file,
                            quarterlyTaxStatementFRB: e.target.files[0],
                          });
                        }
                      }}
                    />
                    <label htmlFor='file4'>
                      <NtnAttachBtn>Attach Doc</NtnAttachBtn>
                      <p>{file?.quarterlyTaxStatementFRB?.name}</p>
                    </label>
                  </>
                )}
              </Grid>
              <Grid item xs='3'>
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes5'
                >
                  Monthly Sales Tax Return for services
                </label>
              </Grid>
              <Grid item xs='4'>
                <div style={{ position: 'relative', zIndex: '1' }}>
                  <Select
                    // className='select-react'
                    options={options2}
                    // menuIsOpen={true}
                    onChange={(e) => {
                      setData({
                        ...data,
                        monthlySaleTaxReturnService: {
                          ...data.monthlySaleTaxReturnService,
                          option: e.value,
                        },
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs='2'>
                <NtnFormInput
                  type='checkbox'
                  id='yes5'
                  name='yes5'
                  checked={data.monthlySaleTaxReturnService.check === 'yes'}
                  onChange={(e) => {
                    setData({
                      ...data,
                      monthlySaleTaxReturnService: {
                        ...data.monthlySaleTaxReturnService,
                        check: e.target.checked ? 'yes' : '',
                      },
                    });
                  }}
                />{' '}
                <label
                  style={{
                    width: '100%',
                  }}
                  htmlFor='yes5'
                >
                  Yes
                </label>
              </Grid>
              <Grid item xs='2'>
                {data.monthlySaleTaxReturnService.check === 'yes' &&
                  data.monthlySaleTaxReturnService.option !== '' && (
                    <>
                      <input
                        type='file'
                        accept='.xlsx, .xls'
                        id='file5'
                        hidden
                        onChange={(e) => {
                          const extension = e.target.files[0]?.name
                            .split('.')
                            .slice(-1)[0];
                          if (extension === 'xlsx' || extension === 'xls') {
                            setFile({
                              ...file,
                              monthlySaleTaxReturnService: e.target.files[0],
                            });
                          }
                        }}
                      />
                      <label htmlFor='file5'>
                        <NtnAttachBtn>Attach Doc</NtnAttachBtn>
                        <p>{file?.monthlySaleTaxReturnService?.name}</p>
                      </label>
                    </>
                  )}
              </Grid>
            </Grid>
          </div>
          <NtnSubmitBtn type='submit'>Proceed</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default PeriodicFiling;
