export const FileYourTaxReturnList = {
  business: [
    {
      title: 'Traders',
      icon: require('../assets/trader.png'),
      route: '/traderFile',
    },
    {
      title: 'Service Providers',
      icon: require('../assets/serviceProvider.png'),
      route: '/serviceProviderAndSelfEmployedProfessional',
    },
    {
      title: 'Self Employed Professionals',
      icon: require('../assets/selfEmployee.png'),
      route: '/serviceProviderAndSelfEmployedProfessional',
    },
    {
      title: 'IT Service Providers',
      icon: require('../assets/itServiceProvider.png'),
      route: '/itServiceProvider',
    },
    {
      title: 'Partnership Firms',
      icon: require('../assets/partnershipFirms.png'),
      route: '/business/partnership-firms',
    },
    {
      title: 'Companies',
      icon: require('../assets/company.png'),
      route: '/business/company',
    },
  ],
  other_source: [
    {
      title: 'Pension',
      icon: require('../assets/pension.png'),
      route: '/pension',
    },
    {
      title: 'Dividend',
      icon: require('../assets/dividend.png'),
      route: '/dividend',
    },
    {
      title: 'Capital Gains',
      icon: require('../assets/capitalGains.png'),
      route: '/capital-gain',
    },
    {
      title: 'Profit on Debt (Interest Income)',
      icon: require('../assets/profitOnDebt.png'),
      route: '/profit-debt',
    },
    {
      title: 'Income from Property (Rent)',
      icon: require('../assets/company.png'),
      route: '/rentalIncome',
    },
  ],
};
