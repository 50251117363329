import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from '../../../global/style/StyleComponent';
import { dashboardRoutes } from '../routes';
import SidebarItem from './SidebarItem';
import './style.css';
import AxiosCall from '../../../api/AxiosCall';
import { setUser } from '../../../redux-store/user/userReducer';

const Sidebar = () => {
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  return (
    <Container
      justifyContent='auto'
      className='sidebarContainer'
      direction='column'
      alignItem
      height='95%'
    >
      <input
        type='file'
        name='user'
        id='user'
        hidden
        onChangeCapture={(e) => {
          console.log(e.target.files[0]);
          var formData = new FormData();
          formData.append('file', e.target.files[0]);
          AxiosCall.post('/upload', formData).then((res) => {
            AxiosCall.put(`/users/${user.id}`, {
              avatar: res.data.urls[0],
            }).then((res) => {
              AxiosCall.get(`/users/${user.id}`).then((res) => {
                dispatch(setUser(res.data.data[0]));
              });
            });
          });
        }}
      />
      <label htmlFor='user'>
        <img
          id='sideBarProfileImage'
          src={user.avatar || require('../assets/user.png')}
          alt=''
        />
      </label>
      <span>
        <strong>Welcome,</strong>{' '}
        <b
          style={{
            fontWeight: '900',
            color: '#1f2545',
          }}
        >
          {user?.username.toUpperCase()}
        </b>
      </span>
      <Link to='/fileYourTaxReturn' className='dashboardLabel'>
        Dashboard
      </Link>
      {dashboardRoutes
        .filter((i) => i.isShow)
        .map((item, index) => (
          <SidebarItem
            key={index}
            path={item.path}
            title={item.title}
            subMenu={item.children}
          />
        ))}
    </Container>
  );
};

export default Sidebar;
