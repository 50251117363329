import { ShoppingBag } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import { MainContainer } from '../../../global/style/StyleComponent';
import { useState } from 'react';
import { useEffect } from 'react';
import AxiosCall from '../../../api/AxiosCall';
import { useSelector } from 'react-redux';

const Header = () => {
  const addToCart = useSelector((state) => state.users.addToCart);
  return (
    <MainContainer
      id='navHeaderContainer'
      bgColor='#373f68'
      flexDirection='row'
      paddingVertical='15px'
      justifyContent='space-between'
      alignItem='center'
    >
      <Link id='logoHead' to='/'>
        <img src={Logo} alt='Logo' />
      </Link>
      <Link to='/add-to-cart'>
        <div
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: -10,
              right: -10,
              background: '#960000',
              fontWeight: '700',
              borderRadius: '100%',
              margin: 0,
              height: '20px',
              width: '20px',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            {addToCart.length}
          </div>
          <ShoppingBag
            sx={{
              fill: 'white',
            }}
          />
        </div>
      </Link>
    </MainContainer>
  );
};

export default Header;
