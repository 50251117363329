import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../../assets/logo.png';
import { MainContainer } from '../../../../global/style/StyleComponent';

const NavBarLibrary = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  return (
    <MainContainer
      id='navHeaderContainer'
      bgColor='#373f68'
      flexDirection='row'
      paddingVertical='15px'
      justifyContent='space-between'
      alignItem='center'
    >
      <div id='menuIcon' onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={faBars} color='#fcc028' />
      </div>
      <Link id='logoHead' to='/'>
        <img src={Logo} alt='Logo' />
      </Link>
      {width > 550 || isOpen ? (
        <div className='navItemsContainer'>
          <Link to={'/library/dashboard'} className='navBtn'>
            Dashboard
          </Link>
          <Link to={'/library/case-law'} className='navBtn'>
            Case Law
          </Link>
          <Link to='/library/statutes' className='navBtn'>
            Statutes
          </Link>
          <Link to='/library/notification' className='navBtn'>
            Notifications
          </Link>
          <Link to='/library/dictionary' className='navBtn'>
            Dictionary
          </Link>
        </div>
      ) : null}
    </MainContainer>
  );
};

export default NavBarLibrary;
