import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './Faq.css';
export default function FaqsAccordine() {
  const navigator = useNavigate();
  return (
    <div className='container'>
      <div className='faqs-container' style={{ border: '1px solid black' }}>
        <Accordion sx={{ borderBottom: '1px solid black' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h6'>1. How does taxpress work?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle1'>
              Taxpress operates as an interactive platform where simple
              questions are designed to be answered by you. You have to provide
              details regarding your salary, utility bills, investments, assets
              and liabilities etc. Our team assembles all the required data,
              analyze it, request any missing information and file your tax
              returns after your validation and go ahead. Similarly, we provide
              you with business registration services and overall corporate
              compliance services to make sure you can enjoy the perks of being
              a separate legal entity and your business remain compliant with
              all the applicable rules and regulations. You can enjoy our expert
              services from the comfort of your home and office. Our
              representative contacts you at your convenient time and place Try
              our services and find out more!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ borderBottom: '1px solid black' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography variant='h6'>
              2. Why should I use taxpress when my existing tax consultant is
              doing the same work for me?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle1'>
              Taxpress operates as an interactive platform where you can prepare
              your return from the comfort of your home and office. Our
              representative contacts you at your convenient time and place We
              keep a proper record of your data. Our analysts and experts not
              only help you in being compliant with the laws and regulations but
              also suggest best tax & business practices to make you more aware.
              We don’t complicate things so that you are intimidated. We welcome
              questions, suggest proper solutions and help you understand your
              status regarding various compliances you or your business may
              require from time to time. We aim to provide top notch services at
              affordable rates that bring in more value for your money.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ borderBottom: '1px solid black' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography variant='h6'>
              3. What do I need to get started?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle1'>
              All you need is to provide your name, cell number and email
              address to sign up for accessing the simplified portal of
              Taxpress. You can chooses your desired option from Salary, Rental
              and / or Business Incomes and get going.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ borderBottom: '1px solid black' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography variant='h6'>
              4. What information do I need to provide to complete the process?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle1'>
              You have to provide details regarding your salary, other incomes,
              utility bills, investments, assets and liabilities etc. Our team
              assembles all the required data, analyze it, request any missing
              information and file your tax returns after your validation and go
              ahead.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ borderBottom: '1px solid black' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography variant='h6'>
              5. My employer already deducts tax on my salary and I don't have{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle1'>
              By filing of your return, you could claim the advance taxes paid
              with utility bills, mobile bills, car token, property sale /
              purchases, credit card transactions etc.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography variant='h6'>
              6. How data confidentiality is maintained at Taxpress?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle1'>
              Taxpress comprise of a team of highly skilled IT Experts who work
              continuously to protect tax portal from any possible hack. We take
              clients privacy and confidentiality of data very seriously. Our
              team works under a non disclosure agreement (NDA) which legally
              precludes them from sharing any details regarding our esteemed
              clients with anyone. Moreover, Taxpress also signs an NDA with
              clients for their entire satisfaction regarding security and
              confidentiality of their information.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <button
          className='view-more-button'
          onClick={() => {
            navigator('#');
          }}
        >
          View More
        </button>
      </Box> */}
    </div>
  );
}
