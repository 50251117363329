import React from 'react';
import { useNavigate } from 'react-router-dom';
import arrowBtn from '../../../../../assets/arrowBtn.svg';
import { useDispatch, useSelector } from 'react-redux';
import './TaxCard.css';

const TaxCard = ({ title, shortDescription }) => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const navigation = useNavigate();
  return (
    <div className='taxCardContainer'>
      <strong>{title}</strong>
      <p>{shortDescription}</p>
      <div className='taxCardReadMoreBtn'>
        <span
          onClick={() => {
            user?.user_role === 'library'
              ? navigate('/case-law-library')
              : navigate('/login');
          }}
        >
          Read More
        </span>
        <img
          style={{
            marginLeft: '10px',
          }}
          src={arrowBtn}
          alt=''
        />
      </div>
    </div>
  );
};

export default TaxCard;
