import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AxiosCall from '../../../api/AxiosCall';
import { submitNTNFile } from '../../../services/NTNServices';
import NtnContainer from '../NtnContainer';
import {
  NtnAttachBtn,
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';

const initState = {
  interestIncome: {
    bankName: '',
    valueOfInterest: '',
    taxDeducted: '',
  },
};
const InterestIncome = () => {
  const [dataInterestIncome, setDataInterestIncome] = useState(
    initState.interestIncome
  );
  const [file, setFile] = useState(null);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setDataInterestIncome(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);

  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            if (
              Object.values(dataInterestIncome).every((i) => i) &&
              file != null
            ) {
              dispatch(
                submitNTNFile(
                  dataInterestIncome,
                  file,
                  setDataInterestIncome,
                  setFile,
                  user,
                  initState,
                  'Other Sources (Interest Income)',
                  true,
                  navigate
                )
              );
            }
          }}
        >
          <div
            id='interest_income'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
              paddingBottom: '1em',
            }}
          >
            <NtnSubHeader>Interest Income</NtnSubHeader>

            <NtnFieldContainer
              style={{
                flexDirection: 'column',
              }}
            >
              <NtnFormLabel required htmlFor='name'>
                Bank Name
              </NtnFormLabel>
              <NtnFormInput
                required
                name='bankName'
                value={dataInterestIncome.bankName}
                onChange={(e) => {
                  setDataInterestIncome((prev) => ({
                    ...prev,
                    bankName: e.target.value,
                  }));
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer
              style={{
                flexDirection: 'column',
              }}
            >
              <NtnFormLabel required htmlFor='name'>
                Value of interest
              </NtnFormLabel>
              <NtnFormInput
                required
                name='valueOfInterest'
                value={dataInterestIncome.valueOfInterest}
                onChange={(e) => {
                  setDataInterestIncome((prev) => ({
                    ...prev,
                    valueOfInterest: e.target.value,
                  }));
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer
              style={{
                flexDirection: 'column',
              }}
            >
              <NtnFormLabel required htmlFor='name'>
                Tax Deducted
              </NtnFormLabel>
              <NtnFormInput
                required
                name='taxDeducted'
                value={dataInterestIncome.taxDeducted}
                onChange={(e) => {
                  setDataInterestIncome((prev) => ({
                    ...prev,
                    taxDeducted: e.target.value,
                  }));
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer
              style={{
                flexDirection: 'column',
              }}
            >
              <NtnFormLabel required htmlFor='name'>
                Attach file
              </NtnFormLabel>
              <NtnFormLabel htmlFor='file'>
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{file?.name}</p>
              </NtnFormLabel>
              <input
                type='file'
                name='file'
                hidden
                id='file'
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </NtnFieldContainer>
          </div>
          <NtnSubmitBtn type='submit'>Proceed</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default InterestIncome;
