import React from 'react';
import BlogCardImg from '../../../../../assets/blog-card-img.png';
import './BlogCard.css';
import SideBar from './SideBar';

const BlogsCard = ({ onClick, img, title, date, description }) => {
  return (
    <div onClick={onClick} className='blogCardContainer'>
      <div className='blogCardHeader'>
        <SideBar right={true} />
        <div />
        <img
          src={img != null ? 'https://api.taxpress.com.pk/' + img : BlogCardImg}
          alt=''
        />
      </div>
      <div className='blogCardBody'>
        <SideBar left={true} top={true} />
        <span className='blogCardHeadText'>{title}</span>
        <span className='blogCardDate'>{date}</span>
        {/* <p>{description}</p> */}
      </div>
    </div>
  );
};

export default BlogsCard;
