import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Btn, MainContainer } from '../../../../global/style/StyleComponent';
import Cards from './components/Cards';
import './WhatWeProvide.css';
import { ReactComponent as Business } from '../../../../assets/Services/Business Registration.svg';
import { ReactComponent as Corporate } from '../../../../assets/Services/Corporate Services.svg';
import { ReactComponent as Tax } from '../../../../assets/Services/Tax Compliances.svg';
import Slider from 'react-slick';

const WhatWeProvide = () => {
  const navigator = useNavigate();
  return (
    <div className='provideContainer'>
      <MainContainer bgColor='#373f68'>
        <div className='topBanner'>
          <strong>Acts/Ordinance/Rules</strong>
          <Btn
            onClick={() => {
              navigator('/act-ordinance-rules');
            }}
          >
            Explore
          </Btn>
        </div>
      </MainContainer>
      <MainContainer bgColor='#fcc028'>
        <div className='privideInnerContainer'>
          <strong>What We Provide</strong>
          <p>
            From enabling our clients in seeking of National Tax Number(s) to
            managing their tax compliances, from getting businesses registered
            with regulatory bodies to securing our client’s intellectual
            capital, we at Taxpress, do it all
          </p>
          <div className='provideCardContainer'>
            <Slider
              dots={true}
              infinite={true}
              speed={500}
              slidesToShow={3}
              slidesToScroll={1}
              className='sliderTeam'
              responsive={[
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                  },
                },
                {
                  breakpoint: 425,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                  },
                },
              ]}
            >
              <Cards
                ImageComponent={Business}
                title={'Business Registration'}
                description={`We facilitate you to get your business get a separate legal identity from incorporating your business to seeking registration with different regulatory bodies, we are just a click away.`}
              />
              <Cards
                ImageComponent={Corporate}
                title={'Corporate Services'}
                description={
                  'Taxpress aims to partner with your business to keep it compliant with all the laws and regulations, thus freeing you from the burden of facing any regulatory non-compliance.'
                }
              />
              <Cards
                ImageComponent={Tax}
                title={'Tax Compliances'}
                description={`Filing of Tax Return is now more important than ever. Let our experts help you in filing of your tax return accurately and efficiently and manage all your tax compliances `}
              />
            </Slider>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default WhatWeProvide;
