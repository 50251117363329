import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AxiosCall from '../../../api/AxiosCall';
import { errorToast } from '../../../global/toast';
import { submitNTNFile } from '../../../services/NTNServices';
import NtnContainer from '../NtnContainer';
import {
  NtnAttachBtn,
  NtnFormInput,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';

const initState = {
  dividendIncome: {
    independentPowerProducerCompanies: {
      value: '',
      taxDeducted: '',
    },
    realEstateInvestment: {
      value: '',
      taxDeducted: '',
    },
    otherCompanies: {
      value: '',
      taxDeducted: '',
    },
  },
};
const initStateFileDividend = {
  independentPowerProducerCompanies: null,
  realEstateInvestment: null,
  otherCompanies: null,
};
const DividendIncome = () => {
  const [dataDividendIncome, setDataDividendIncome] = useState(
    initState.dividendIncome
  );

  const [fileDividend, setFileDividend] = useState(initStateFileDividend);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setDataDividendIncome(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);

  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <div
          id='dividend'
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            paddingBottom: '1em',
          }}
        >
          <NtnSubHeader>Dividend Income</NtnSubHeader>
          <Grid container spacing={'1em'} xs={10} alignSelf='center'>
            <Grid item xs='4'></Grid>
            <Grid item xs='3'>
              <b>Value of Dividend</b>
            </Grid>
            <Grid item xs='3'>
              <b>Tax deducted</b>
            </Grid>
            <Grid item xs='2'>
              <b>Attach Doc</b>
            </Grid>
            <Grid item xs='4'>
              <label
                style={{
                  width: '100%',
                }}
                htmlFor='name'
              >
                Dividend from Independent power producer companies
              </label>
            </Grid>
            <Grid item xs='3'>
              <NtnFormInput
                width='90%'
                value={
                  dataDividendIncome.independentPowerProducerCompanies.value
                }
                onChange={(e) => {
                  setDataDividendIncome({
                    ...dataDividendIncome,
                    independentPowerProducerCompanies: {
                      ...dataDividendIncome.independentPowerProducerCompanies,
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs='3'>
              <NtnFormInput
                width='90%'
                value={
                  dataDividendIncome.independentPowerProducerCompanies
                    .taxDeducted
                }
                onChange={(e) => {
                  setDataDividendIncome({
                    ...dataDividendIncome,
                    independentPowerProducerCompanies: {
                      ...dataDividendIncome.independentPowerProducerCompanies,
                      taxDeducted: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs='2'>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'center',
                }}
                htmlFor='file1'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{fileDividend?.independentPowerProducerCompanies?.name}</p>
              </label>
              <input
                type='file'
                id='file1'
                hidden
                onChange={(e) => {
                  setFileDividend({
                    ...fileDividend,
                    independentPowerProducerCompanies: e.target.files[0],
                  });
                }}
              />
            </Grid>
            <Grid item xs='4'>
              <label
                style={{
                  width: '100%',
                }}
                htmlFor='name'
              >
                Dividend from Mutual funds and Real Estate Investment Trusts
              </label>
            </Grid>
            <Grid item xs='3'>
              <NtnFormInput
                width='90%'
                value={dataDividendIncome.realEstateInvestment.value}
                onChange={(e) => {
                  setDataDividendIncome({
                    ...dataDividendIncome,
                    realEstateInvestment: {
                      ...dataDividendIncome.realEstateInvestment,
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs='3'>
              <NtnFormInput
                width='90%'
                value={dataDividendIncome.realEstateInvestment.taxDeducted}
                onChange={(e) => {
                  setDataDividendIncome({
                    ...dataDividendIncome,
                    realEstateInvestment: {
                      ...dataDividendIncome.realEstateInvestment,
                      taxDeducted: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs='2'>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'center',
                }}
                htmlFor='file2'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{fileDividend?.realEstateInvestment?.name}</p>
              </label>
              <input
                type='file'
                id='file2'
                hidden
                onChange={(e) => {
                  setFileDividend({
                    ...fileDividend,
                    realEstateInvestment: e.target.files[0],
                  });
                }}
              />
            </Grid>
            <Grid item xs='4'>
              <label
                style={{
                  width: '100%',
                }}
                htmlFor='name'
              >
                Dividend from Other Companies
              </label>
            </Grid>
            <Grid item xs='3'>
              <NtnFormInput
                width='90%'
                value={dataDividendIncome.otherCompanies.value}
                onChange={(e) => {
                  setDataDividendIncome({
                    ...dataDividendIncome,
                    otherCompanies: {
                      ...dataDividendIncome.otherCompanies,
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs='3'>
              <NtnFormInput
                width='90%'
                value={dataDividendIncome.otherCompanies.taxDeducted}
                onChange={(e) => {
                  setDataDividendIncome({
                    ...dataDividendIncome,
                    otherCompanies: {
                      ...dataDividendIncome.otherCompanies,
                      taxDeducted: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs='2'>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'center',
                }}
                htmlFor='file3'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{fileDividend?.otherCompanies?.name}</p>
              </label>
              <input
                type='file'
                id='file3'
                hidden
                onChange={(e) => {
                  setFileDividend({
                    ...fileDividend,
                    otherCompanies: e.target.files[0],
                  });
                }}
              />
            </Grid>
          </Grid>
        </div>
        <NtnSubmitBtn
          onClick={() => {
            if (
              dataDividendIncome.independentPowerProducerCompanies
                .taxDeducted ||
              dataDividendIncome.independentPowerProducerCompanies.value ||
              fileDividend.independentPowerProducerCompanies
            ) {
              if (
                !(
                  dataDividendIncome.independentPowerProducerCompanies
                    .taxDeducted &&
                  dataDividendIncome.independentPowerProducerCompanies.value &&
                  fileDividend.independentPowerProducerCompanies
                )
              ) {
                errorToast(
                  'Kindly provide all detail of Dividend from Independent power producer companies'
                );
                return;
              }
            }
            if (
              dataDividendIncome.otherCompanies.taxDeducted ||
              dataDividendIncome.otherCompanies.value ||
              fileDividend.otherCompanies
            ) {
              if (
                !(
                  dataDividendIncome.otherCompanies.taxDeducted &&
                  dataDividendIncome.otherCompanies.value &&
                  fileDividend.otherCompanies
                )
              ) {
                errorToast(
                  'Kindly provide all detail of Dividend from Other Companies'
                );
                return;
              }
            }
            if (
              dataDividendIncome.realEstateInvestment.taxDeducted ||
              dataDividendIncome.realEstateInvestment.value ||
              fileDividend.realEstateInvestment
            ) {
              if (
                !(
                  dataDividendIncome.realEstateInvestment.taxDeducted &&
                  dataDividendIncome.realEstateInvestment.value &&
                  fileDividend.realEstateInvestment
                )
              ) {
                errorToast(
                  'Kindly provide all detail of Dividend from Mutual funds and Real Estate Investment Trusts'
                );
                return;
              }
            }
            if (
              (dataDividendIncome.independentPowerProducerCompanies
                .taxDeducted &&
                dataDividendIncome.independentPowerProducerCompanies.value &&
                fileDividend.independentPowerProducerCompanies) ||
              (dataDividendIncome.otherCompanies.taxDeducted &&
                dataDividendIncome.otherCompanies.value &&
                fileDividend.otherCompanies) ||
              (dataDividendIncome.realEstateInvestment.taxDeducted &&
                dataDividendIncome.realEstateInvestment.value &&
                fileDividend.realEstateInvestment)
            ) {
              dispatch(
                submitNTNFile(
                  dataDividendIncome,
                  fileDividend,
                  setDataDividendIncome,
                  setFileDividend,
                  user,
                  initState.dividendIncome,
                  'Other Sources (Dividend Income)',
                  true,
                  navigate
                )
              );
            }
          }}
        >
          Proceed
        </NtnSubmitBtn>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default DividendIncome;
