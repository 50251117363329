import { Modal } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Container } from '../../../style/StyleComponent';
import { ServicesContentPopup, ThanksPopup } from '../../popup/services';
import { PriceCardContainer } from '../services-and-fee-styled';

const PriceCard = ({ title, servicesList, Fee, completionTime }) => {
  const [servicesContent, setServicesContent] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  return (
    <>
      <Modal open={servicesContent}>
        <ServicesContentPopup
          setState={setServicesContent}
          setThanks={setThankYou}
          title={title}
        />
      </Modal>
      <Modal open={thankYou}>
        <ThanksPopup setThanks={setThankYou} />
      </Modal>
      <PriceCardContainer
        onClick={() => {
          setServicesContent(true);
        }}
      >
        <div>
          <strong>{title}</strong>
          {servicesList.map((service, index) => (
            <Container
              justifyContent='flex-start'
              padding='0px 20px'
              marginTop='10px'
              alignItem='center'
              key={index}
            >
              <img
                src={require('../../../../assets/about-us-icon3.png')}
                alt=''
                style={{
                  marginRight: '10px',
                }}
              />
              <span>{service.name}</span>
            </Container>
          ))}
        </div>
        <div>
          <div>Fee: {Fee}</div>
          <span>Completion Time: {completionTime}</span>
        </div>
      </PriceCardContainer>
    </>
  );
};

export default PriceCard;
