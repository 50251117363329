export const NtnList = [
  '/salaryFile',
  '/traderFile',
  '/serviceProviderAndSelfEmployedProfessional',
  '/itServiceProvider',
  '/partnership-firms',
  '/business/company',
  '/pension',
  '/dividend',
  '/capital-gain',
  '/profit-debt',
  '/agricultural',
  '/rentalIncome',
];
