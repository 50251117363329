import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitNTNFile } from '../../../services/NTNServices';
import {
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';
import NtnContainer from '../NtnContainer';
import { useNavigate } from 'react-router-dom';

const initStat = {
  firmName: '',
  nameOfPartners: '',
  phoneNumber: '',
  businessEmail: '',
};

const Company = () => {
  const user = useSelector((state) => state.users.user);
  const [data, setData] = useState(initStat);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Company</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            if (Object.values(data).every((i) => i)) {
              dispatch(
                submitNTNFile(
                  data,
                  null,
                  setData,
                  () => {},
                  user,
                  initStat,
                  'Partnership',
                  true,
                  navigate
                )
              );
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
            }}
          >
            <span>Get your NTN</span>

            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                1. Name of company
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                value={data.firmName}
                onChange={(e) => {
                  setData({ ...data, firmName: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                2. Number of Directors
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                value={data.nameOfPartners}
                onChange={(e) => {
                  setData({ ...data, nameOfPartners: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                3. Phone number of Principle officer
              </NtnFormLabel>
              <NtnFormInput
                required
                type='number'
                width='350px'
                value={data.phoneNumber}
                onChange={(e) => {
                  setData({ ...data, phoneNumber: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                4. Business Email
              </NtnFormLabel>
              <NtnFormInput
                required
                type='email'
                width='350px'
                value={data.businessEmail}
                onChange={(e) => {
                  setData({ ...data, businessEmail: e.target.value });
                }}
              />
            </NtnFieldContainer>
          </div>
          <NtnSubmitBtn type='submit'>Submit</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default Company;
