import styled from 'styled-components';

export const DashboardCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2em;
  justify-content: space-between;
  & > div:nth-child(n + 2) {
    margin-left: 1em;
  }
`;

export const DashboardCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #ec9d1b;
  box-shadow: 0px 0px 10px #ec9d1b;
  border-radius: 10px;
  position: relative;
  padding: 1.5rem;
  flex: 1;
  & > div {
    position: absolute;
    top: -20px;
    left: 15px;
    height: 70px;
    width: 70px;
    color: #373f68;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ec9d1b;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 25px;
    }
  }
  & > strong {
    font-size: 1.2rem;
  }
  & > span {
    font-size: 1rem;
  }
  @media (max-width: 1050px) {
    & > div {
      height: 60px;
      width: 60px;
      svg {
        width: 25px;
      }
    }
    & > strong {
      font-size: 1rem;
    }
    & > span {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 900px) {
    & > div {
      height: 50px;
      width: 40px;
      svg {
        width: 17px;
      }
    }
    & > strong {
      font-size: 0.8rem;
    }
    & > span {
      font-size: 0.6rem;
    }
  }
`;

export const HorizantalCardListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2em;
  justify-content: space-between;
  height: 100%;
  & > div:nth-child(n + 2) {
    margin-left: 2em;
  }
`;

export const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  color: #373f68;
  padding: 0 2em;
  overflow-y: auto;
  overflow-x: hidden;
  & > strong {
    font-size: 1.2em;
    border-bottom: 2px solid #373f68;
    padding-bottom: 1em;
    margin-bottom: 1em;
    margin-top: 2em;
  }
  & > a {
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0.5em;
    text-decoration: none;
    border-bottom: 1px solid #373f68;
    padding-bottom: 0.5em;
    &:hover {
      color: #60637e;
      text-decoration: underline;
    }
  }
`;
