import React, { useEffect } from 'react';
import AboutUs from './components/aboutus/AboutUs';
import Blogs from './components/Blogs/Blogs';
import ClientSatisfaction from './components/client-satisfaction/ClientSatisfaction';
import CompanyLogo from './components/company-logo/CompanyLogo';
import CustomerSays from './components/customer-says/CustomerSays';
import HeroSection from './components/Hero/HeroSection';
import OverSeas from './components/OverSea/OverSeas';
import TaxAndLegal from './components/TaxAndLegal/TaxAndLegal';
import WhatWeProvide from './components/what-we-provide/WhatWeProvide';

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeroSection />
      <AboutUs />
      <WhatWeProvide />
      <OverSeas />
      <TaxAndLegal />
      <Blogs />
      <CustomerSays />
      <ClientSatisfaction />
      <CompanyLogo />
    </div>
  );
};

export default HomePage;
