import { Grid } from '@mui/material';
import React from 'react';
import Sidebar from './Sidebar';

const ContentSection = ({ children }) => {
  return (
    <Grid sx={{}} container>
      <Grid className='sideBar' item xs='2'>
        <Sidebar />
      </Grid>
      <Grid className='content' item xs='10'>
        {children}
      </Grid>
    </Grid>
  );
};

export default ContentSection;
