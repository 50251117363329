import React from 'react';
import { CountersContainer } from '../about-us-styled';
import Counter from './Counter';

const AboutUsCounter = () => {
  return (
    <CountersContainer>
      <Counter title={'Businesses registered '} end={1000} />
      <Counter title={'NTN’s Registered'} end={5000} />
      <Counter title={'Returns filed till date'} end={6000} />
      <Counter title={'Case Studies in Library'} end={9999} />
    </CountersContainer>
  );
};

export default AboutUsCounter;
