import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';

const SubMenu = ({ item }) => {
  const [isExpandable, setIsExpandable] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setIsExpandable(!isExpandable);
        }}
        className='sidebarItem'
      >
        {item.children ? (
          <span>{item.title}</span>
        ) : (
          <Link to={`${item.path}`}>{item.title}</Link>
        )}
        {item.children && <KeyboardArrowDownIcon />}
      </div>
      {isExpandable && item?.children && (
        <div className='expandable'>
          {item?.children?.map((item, index) => (
            <div className='sidebarItem'>
              <Link to={`${item.path}`}>{item.title}</Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SubMenu;
