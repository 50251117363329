import React, { useEffect } from 'react';
import { MainContainer } from '../../global/style/StyleComponent';
import Calculator from './components/Calculator';
import './SaleTaxCalculator.css';

const SaleTaxCalculator = ({ isNtnTax = false }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer
      id={isNtnTax ? 'saleTaxCalculator' : ''}
      paddingVertical={'70px'}
    >
      <div className='calculatorContainer'>
        <div>
          <h2>Salary Tax Calculator</h2>
          <p>
            Registering with our online tax portal allows access to an easy to
            use tax calculator that lays out all calculations for you, in a
            comprehensible manner. Our salary tax calculator is regularly
            updated with the latest regulations and applicable tax rates in
            Pakistan.
          </p>
        </div>
        <Calculator />
      </div>
    </MainContainer>
  );
};

export default SaleTaxCalculator;
