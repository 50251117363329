import { Dashboard } from '@mui/icons-material';
import React from 'react';
import SaleTaxCalculator from '../../pages/sale-tax-calculator/SaleTaxCalculator';
import NtnContainer from './NtnContainer';
import { NtnMainContainer } from './components/style/styleNtn';
import AddToCart from './pages/AddToCart';
import Agricultural from './pages/Agricultural';
import BusinessCompany from './pages/BusinessCompany';
import Company from './pages/Company';
import FileYourTaxReturn from './pages/FileYourTaxReturn';
import FinalCalculator from './pages/FinalCalculator';
import ITServiceProvider from './pages/ITServiceProvider';
import IndividualSaleTax from './pages/IndividualSaleTax';
import NtnRecord from './pages/NtnRecord';
import OtherResources from './pages/Pension';
import Partnership from './pages/Partnership';
import PartnershipAndCompanySaleTax from './pages/PartnershipAndCompanySaleTax';
import PartnershipFirms from './pages/PartnershipFirms';
import PaymentPage from './pages/PaymentPage';
import PeriodicFiling from './pages/PeriodicFiling';
import PersonalNTN from './pages/PersonalNTN';
import SalaryFile from './pages/SalaryFile';
import ServiceProviderAndSelfEmployedProfessional from './pages/ServiceProviderAndSelfEmployedProfessional';
import SoleProprietor from './pages/SoleProprietor';
import TraderFile from './pages/TraderFile';
import WealthForm from './pages/WealthForm';
import RentalIncome from './pages/RentalIncome';
import Pension from './pages/Pension';
import DividendIncome from './pages/DividendIncome';
import CapitalGain from './pages/CapitalGain';
import InterestIncome from './pages/InterestIncome';

export const dashboardRoutes = [
  {
    title: 'File your tax return',
    icon: <Dashboard />,
    path: '/fileYourTaxReturn',
    Component: <FileYourTaxReturn />,
    isShow: true,
    children: [
      {
        title: 'Salary',
        icon: <Dashboard />,
        path: '/salaryFile',
        Component: <SalaryFile />,
        isShow: false,
      },
      {
        title: 'Business',
        icon: <Dashboard />,
        path: '/',
        Component: <SalaryFile />,
        isShow: false,
        children: [
          {
            title: 'trader',
            icon: <Dashboard />,
            path: '/traderFile',
            Component: <TraderFile />,
            isShow: false,
          },
          {
            title: 'Service Provider',
            icon: <Dashboard />,
            path: '/serviceProviderAndSelfEmployedProfessional',
            Component: <ServiceProviderAndSelfEmployedProfessional />,
            isShow: false,
          },

          {
            title: 'Self Employed Professional',
            icon: <Dashboard />,
            path: '/serviceProviderAndSelfEmployedProfessional',
            Component: <ServiceProviderAndSelfEmployedProfessional />,
            isShow: false,
          },

          {
            title: 'IT Services Provider',
            icon: <Dashboard />,
            path: '/itServiceProvider',
            Component: <ITServiceProvider />,
            isShow: false,
          },

          {
            title: 'Partnership Firms',
            icon: <Dashboard />,
            path: '/business/partnership-firms',
            Component: <PartnershipFirms />,
            isShow: false,
          },
          {
            title: 'Company',
            icon: <Dashboard />,
            path: '/business/company',
            Component: <BusinessCompany />,
            isShow: false,
          },
        ],
      },
      {
        title: 'Agricultural',
        icon: <Dashboard />,
        path: '/agricultural',
        Component: <Agricultural />,
        isShow: false,
      },
      {
        title: 'Other Sources',
        icon: <Dashboard />,
        path: '/otherResources',
        Component: <OtherResources />,
        isShow: false,
        children: [
          {
            title: 'Pension',
            icon: <Dashboard />,
            path: '/pension',
            Component: <Pension />,
          },
          {
            title: 'Dividend',
            icon: <Dashboard />,
            path: '/dividend',
            Component: <DividendIncome />,
          },
          {
            title: 'Capital Gain',
            icon: <Dashboard />,
            path: '/capital-gain',
            Component: <CapitalGain />,
          },
          {
            title: 'Profit on Debt',
            icon: <Dashboard />,
            path: '/profit-debt',
            Component: <InterestIncome />,
          },
          {
            title: 'rental Income',
            icon: <Dashboard />,
            path: '/rentalIncome',
            Component: <RentalIncome />,
          },
        ],
      },
    ],
  },
  {
    title: 'File your tax return',
    icon: <Dashboard />,
    path: '/fileYourTaxReturn',
    Component: <FileYourTaxReturn />,
    isShow: false,
  },

  {
    title: 'Other Sources',
    icon: <Dashboard />,
    path: '/otherResources',
    Component: <OtherResources />,
    isShow: false,
  },
  // {
  //   title: 'Agricultural',
  //   icon: <Dashboard />,
  //   path: '/agricultural',
  //   Component: <Agricultural />,
  //   isShow: false,
  // },
  // {
  //   title: 'Business Company',
  //   icon: <Dashboard />,
  //   path: '/business/company',
  //   Component: <BusinessCompany />,
  //   isShow: false,
  // },
  // {
  //   title: 'Partnership Firms',
  //   icon: <Dashboard />,
  //   path: '/business/partnership-firms',
  //   Component: <PartnershipFirms />,
  //   isShow: false,
  // },
  // {
  //   title: 'IT & IT Enabled Services',
  //   icon: <Dashboard />,
  //   path: '/itServiceProvider',
  //   Component: <ITServiceProvider />,
  //   isShow: false,
  // },
  // {
  //   title: 'Other Sources',
  //   icon: <Dashboard />,
  //   path: '/otherResources',
  //   Component: <OtherResources />,
  //   isShow: false,
  // },
  {
    title: 'Sale Tax Registration',
    icon: <Dashboard />,
    isShow: true,
    children: [
      {
        title: 'Individual',
        icon: <Dashboard />,
        path: '/individualSaleTax',
        Component: <IndividualSaleTax />,
        isShow: false,
      },
      {
        title: 'partnership And Company ',
        icon: <Dashboard />,
        path: '/partnershipAndCompanySaleTax',
        Component: <PartnershipAndCompanySaleTax />,
        isShow: true,
      },
    ],
  },
  {
    title: 'Register your NTN',
    icon: <Dashboard />,
    isShow: true,
    children: [
      {
        title: 'personal NTN',
        icon: <Dashboard />,
        path: '/personalNTN',
        Component: <PersonalNTN />,
      },
      {
        title: 'Business NTN',
        icon: <Dashboard />,
        children: [
          {
            title: 'sole Proprietor',
            icon: <Dashboard />,
            path: '/soleProprietor',
            Component: <SoleProprietor />,
          },
          {
            title: 'partnership',
            icon: <Dashboard />,
            path: '/partnership',
            Component: <Partnership />,
          },
          {
            title: 'company',
            icon: <Dashboard />,
            path: '/company',
            Component: <Company />,
          },
        ],
      },
    ],
  },

  {
    title: 'Periodic Filing',
    icon: <Dashboard />,
    path: '/periodicFiling',
    Component: <PeriodicFiling />,
    isShow: true,
  },
  {
    title: 'calculate your tax',
    icon: <Dashboard />,
    path: '/ntn/salary-tax-calculator',
    Component: (
      <NtnContainer>
        <NtnMainContainer>
          <SaleTaxCalculator isNtnTax={true} />
        </NtnMainContainer>
      </NtnContainer>
    ),
    isShow: true,
  },
  // {
  //   title: 'periodic Filing',
  //   icon: <Dashboard />,
  //   path: '/periodicFiling',
  //   Component: <PeriodicFiling />,
  //   isShow: true,
  // },
  // {
  //   title: 'salary File',
  //   icon: <Dashboard />,
  //   path: '/salaryFile',
  //   Component: <SalaryFile />,
  //   isShow: false,
  // },
  // {
  //   title: 'service Provider And \n Self Employed Professional',
  //   icon: <Dashboard />,
  //   path: '/serviceProviderAndSelfEmployedProfessional',
  //   Component: <ServiceProviderAndSelfEmployedProfessional />,
  //   isShow: false,
  // },
  {
    title: 'Tax record',
    icon: <Dashboard />,
    path: '/taxRecord',
    Component: <NtnRecord />,
    isShow: true,
  },
  // {
  //   title: 'trader File',
  //   icon: <Dashboard />,
  //   path: '/traderFile',
  //   Component: <TraderFile />,
  //   isShow: false,
  // },
  {
    title: 'Tax Calculator',
    icon: <Dashboard />,
    path: '/tax-calculator',
    Component: <FinalCalculator />,
    isShow: false,
  },
  {
    title: 'Add to cart',
    icon: <Dashboard />,
    path: '/add-to-cart',
    Component: <AddToCart />,
    isShow: false,
  },
  {
    title: 'Wealth Form',
    icon: <Dashboard />,
    path: '/wealth-form',
    Component: <WealthForm />,
    isShow: false,
  },
  {
    title: 'Payment',
    icon: <Dashboard />,
    path: '/payment',
    Component: <PaymentPage />,
    isShow: false,
  },
  {
    title: 'rental Income',
    icon: <Dashboard />,
    path: '/rentalIncome',
    Component: <RentalIncome />,
  },
];
