import styled from 'styled-components';

export const AppointmentContainer = styled.div`
  display: ${(props) => (props.appointment ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #373f68c7;
  z-index: 100;
  overflow: hidden;
`;
