import styled from 'styled-components';

export const ListCardContainer = styled.div`
  margin: 15px;
  flex: 1;
  & > strong {
    font-size: 18px;
    background: #373f68;
    padding: 10px;
    color: #fff;
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
  border: 1px solid #373f68;
  @media (max-width: 767px) {
    & > strong {
      font-size: 16px;
    }
  }
  @media (max-width: 600px) {
    margin: 10px 0px;
  }
`;
export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  & a {
    text-decoration: none;
    color: #000;
  }
  & > img {
    width: 30px;
    margin-right: 20px;
  }
  border-bottom: 1px solid #373f68;

  @media (max-width: 940px) {
    & > img {
      width: 25px;
      margin-right: 10px;
    }
  }
  @media (max-width: 740px) {
    & > img {
      width: 20px;
      margin-right: 10px;
    }
  }
`;
