import React from 'react';
import {
  CategoryBarContainer,
  CategoryBarItems,
} from '../services-and-fee-styled';

const CategoryBar = ({ categories, setActiveCategory, active }) => {
  return (
    <CategoryBarContainer>
      {categories !== undefined &&
        categories.map((category, index) => {
          return (
            <CategoryBarItems
              onClick={() => {
                setActiveCategory(category.title);
              }}
              active={category.title.toUpperCase() === active.toUpperCase()}
              key={index}
            >
              {category.title}
            </CategoryBarItems>
          );
        })}
    </CategoryBarContainer>
  );
};

export default CategoryBar;
