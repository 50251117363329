import React from 'react';
import NtnContainer from '../NtnContainer';
import {
  NtnMainHeader,
  NtnPageDescription,
  NtnMainContainer,
  NtnSubHeader,
  NtnSubmitBtn,
  NtnFormInput,
  NtnFieldContainer,
  NtnFormCheckBox,
  NtnForm,
  NtnFormLabel,
} from '../components/style/styleNtn';
import { useDispatch, useSelector } from 'react-redux';
import { submitNTNFile } from '../../../services/NTNServices';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import AxiosCall from '../../../api/AxiosCall';

const initStat = {
  monthlyRent: '',
  noOfMonth: '',
  totalRentPerYear: 0,
  security: '',
  securityForTenYear: 0,
  rentReceive: 0,
  insurancePaid: '',
  propertyTaxPaid: '',
  loanPaid: false,
  loanAmount: '',
  administrationPaid: false,
  administrationAmount: '',
  unPaidRent: false,
  unPaidRentAmount: '',
  totalRentalIncome: 0,
};

const RentalIncome = () => {
  const user = useSelector((state) => state.users.user);
  const [data, setData] = useState(initStat);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setData(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      totalRentPerYear: parseInt(prev.monthlyRent) * parseInt(data.noOfMonth),
    }));
  }, [data.monthlyRent, data.noOfMonth]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      securityForTenYear: prev.security / 10,
    }));
  }, [data.security]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      rentReceive:
        parseInt(prev.securityForTenYear) + parseInt(prev.totalRentPerYear),
    }));
  }, [data.security]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      totalRentalIncome:
        parseInt(prev.rentReceive) -
        (parseInt(prev.administrationAmount) +
          parseInt(prev.loanAmount) +
          parseInt(prev.unPaidRentAmount) +
          parseInt(prev.insurancePaid) +
          parseInt(prev.propertyTaxPaid)),
    }));
  }, [
    data.administrationAmount,
    data.loanAmount,
    data.unPaidRentAmount,
    data.insurancePaid,
    data.propertyTaxPaid,
    data.rentReceive,
  ]);

  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Rental Income</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            const tempData = { ...data };
            delete tempData['insurancePaid'];
            delete tempData['administrationPaid'];
            delete tempData['loanPaid'];
            dispatch(
              submitNTNFile(
                tempData,
                null,
                setData,
                () => {},
                user,
                initStat,
                'Rental Income',
                false,
                navigate
              )
            );
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
            }}
          >
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                1. Monthly Rent
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                type='number'
                value={data.monthlyRent}
                onChange={(e) => {
                  setData({ ...data, monthlyRent: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                2. Number of Month
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                type='number'
                value={data.noOfMonth}
                onChange={(e) => {
                  setData({ ...data, noOfMonth: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3>Total Rent Received for the year</h3>
              <h3>{data.totalRentPerYear}</h3>
            </div>

            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                Security Deposit Received
              </NtnFormLabel>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <NtnFormInput
                  required
                  width='250px !important'
                  type='number'
                  value={data.security}
                  onChange={(e) => {
                    setData({ ...data, security: e.target.value });
                  }}
                />

                <h3>{data.securityForTenYear}</h3>
              </div>
            </NtnFieldContainer>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3>Total Rent Receive</h3>
              <h3>{data.rentReceive}</h3>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3>Deduction Against rental income</h3>
            </div>

            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                Insurance paid against rented building
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                type='number'
                value={data.insurancePaid}
                onChange={(e) => {
                  setData({ ...data, insurancePaid: e.target.value });
                }}
              />
            </NtnFieldContainer>

            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                Property tax paid on rented building
              </NtnFormLabel>
              <NtnFormInput
                required
                width='350px'
                type='number'
                value={data.propertyTaxPaid}
                onChange={(e) => {
                  setData({ ...data, propertyTaxPaid: e.target.value });
                }}
              />
            </NtnFieldContainer>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3>Other Deductions</h3>
            </div>

            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                Mark up paid on loan against rented property
              </NtnFormLabel>
              <div
                style={{
                  display: 'flex',
                  gap: '1em',
                }}
              >
                <NtnFormInput
                  required
                  type='checkbox'
                  style={{
                    width: '45px',
                    height: '45px',
                    margin: '0',
                  }}
                  checked={data.loanPaid}
                  onChange={(e) => {
                    setData({ ...data, loanPaid: e.target.checked });
                  }}
                />
                <NtnFormInput
                  required
                  width='350px'
                  type='number'
                  disabled={!data.loanPaid}
                  value={data.loanAmount}
                  onChange={(e) => {
                    setData({ ...data, loanAmount: e.target.value });
                  }}
                />
              </div>
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                Administration and collection charges
              </NtnFormLabel>
              <div
                style={{
                  display: 'flex',
                  gap: '1em',
                }}
              >
                <NtnFormInput
                  required
                  type='checkbox'
                  style={{
                    width: '45px',
                    height: '45px',
                    margin: '0',
                  }}
                  checked={data.administrationPaid}
                  onChange={(e) => {
                    setData({ ...data, administrationPaid: e.target.checked });
                  }}
                />
                <NtnFormInput
                  required
                  width='350px'
                  type='number'
                  disabled={!data.administrationPaid}
                  value={data.administrationAmount}
                  onChange={(e) => {
                    setData({ ...data, administrationAmount: e.target.value });
                  }}
                />
              </div>
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                Unpaid rent{' '}
              </NtnFormLabel>
              <div
                style={{
                  display: 'flex',
                  gap: '1em',
                }}
              >
                <NtnFormInput
                  required
                  type='checkbox'
                  style={{
                    width: '45px',
                    height: '45px',
                    margin: '0',
                  }}
                  checked={data.unPaidRent}
                  onChange={(e) => {
                    setData({ ...data, unPaidRent: e.target.checked });
                  }}
                />
                <NtnFormInput
                  required
                  width='350px'
                  type='number'
                  disabled={!data.unPaidRent}
                  value={data.unPaidRentAmount}
                  onChange={(e) => {
                    setData({ ...data, unPaidRentAmount: e.target.value });
                  }}
                />
              </div>
            </NtnFieldContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h3>Total Rental Income</h3>
              <h3>{data.totalRentalIncome}</h3>
            </div>
          </div>
          <NtnSubmitBtn>Proceed</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default RentalIncome;
