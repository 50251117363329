import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Relible } from '../../../../assets/Relible.svg';
import { ReactComponent as Simple } from '../../../../assets/Simple.svg';
import { ReactComponent as Speedy } from '../../../../assets/Speedy.svg';
import CustomSlider from '../../../../global/components/custom/slider/CustomSlider';
import { Btn } from '../../../../global/style/StyleComponent';
import { getBackground } from '../../../../redux-store/home/actionCreator';
import Cards from './components/Cards';
import StatusRulesUpdates from './components/StatusRulesUpdates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './HeroSection.css';
import { HeroSectionContainer } from './HeroSectionStyle';
import { PrevBtn } from '../client-satisfaction/clientStatisfactionStyled';

const content = [
  {
    img: require('../../../../assets/Slider-01-vackground.jpg'),
    title: 'Case Laws',
    text: 'Pakistan’s # 1 portal introducing on demand feature for Court Precedents and Legal citations encompassing all tax and fiscal laws. ',
    btn: 'Registration',
    link: '/registration',
  },

  {
    img: require('../../../../assets/Slider-02-vackground.jpg'),
    title: 'BUSINESS REGISTRATION SERVICES'.toLowerCase(),
    text: 'Liberating people from the burden of being indulged in getting their Businesses registered, regularized and compliant, thus freeing their time and capital for their businesses to invest, grow and thrive.',
    btn: 'Registration',
    link: '/registration',
  },
  {
    img: require('../../../../assets/Slider-03-vackground.jpg'),
    title: 'RETURN FILING'.toLowerCase(),
    text: 'Enabling people in filing their tax returns from the comfort of their homes, sitting across the globe and round the clock with Pakistan’s most secure and reliable online platform.',
    btn: 'Try Free',
    link: '/registration',
  },
];

// var xAxis = 0;
const HeroSection = () => {
  const innerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const background = useSelector((state) => state.home.background);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const [xAxis, setXAxis] = useState(0);
  const [currentXAxis, setCurrentXAxis] = useState(0);
  useEffect(() => {
    dispatch(getBackground());
  }, []);

  useEffect(() => {
    const timer = setInterval(
      () => onNextClickHandler({ xAxis }),
      isMouseHover ? 7000 : 5000
    );
    return () => {
      clearInterval(timer);
    };
  }, [isMouseHover, xAxis]);

  const onNextClickHandler = ({ xAxis }) => {
    let currentXAxis = 0;

    if (xAxis / window.innerWidth < 2) {
      // alert('WORKIGN');
      currentXAxis = xAxis += window.innerWidth;
      setXAxis((prev) => prev + window.innerWidth);
    } else {
      currentXAxis = 0;
      setXAxis(0);
    }
    innerRef.current.style.transform = `translate3d(-${currentXAxis}px, 0, 0)`;
    innerRef.current.style.transition = '1s';
    setCurrentXAxis(
      innerRef.current.style.transform
        .split(',')[0]
        .split('(')[1]
        .replace('-', '')
        .replace('px', '') / window.innerWidth
    );
  };
  const onPreClickHandler = ({ xAxis }) => {
    let currentXAxis = !innerRef.current.style.transform
      ? 0
      : innerRef.current.style.transform
          .split(',')[0]
          .split('(')[1]
          .replace('-', '')
          .replace('px', '') / window.innerWidth;
    console.log(currentXAxis);
    let currentPos = xAxis;
    if (currentXAxis === 0) {
      currentPos = window.innerWidth * 2;
      setXAxis(window.innerWidth * 2);
    } else if (currentXAxis === 2) {
      currentPos = window.innerWidth * 1;
      setXAxis(window.innerWidth * 1);
    } else {
      currentPos = window.innerWidth * 0;
      setXAxis(0);
    }
    innerRef.current.style.transform = `translate3d(-${currentPos}px, 0, 0)`;
    innerRef.current.style.transition = '1s';
    setCurrentXAxis(currentPos / window.innerWidth);
  };

  return (
    <HeroSectionContainer
      onMouseEnter={() => {
        setIsMouseHover(true);
      }}
      onMouseLeave={() => {
        setIsMouseHover(false);
      }}
      className='heroContainer'
    >
      <div
        onClick={() => onPreClickHandler({ xAxis })}
        style={{
          left: '1rem',
        }}
        className='sliderBtn'
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <CustomSlider innerRef={innerRef}>
          {content.map((item, index) => (
            <img
              key={index}
              className='heroBackground'
              src={item.img}
              alt=''
              style={{
                objectFit: 'cover',
                width: '100vw',
                height: '100vh',
              }}
            />
          ))}
        </CustomSlider>
      </div>
      <div
        onClick={() => onNextClickHandler({ xAxis })}
        style={{
          right: '1rem',
        }}
        className='sliderBtn'
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </div>

      {content[currentXAxis] !== undefined && (
        <div className='detailContainer'>
          <strong>{content[currentXAxis].title}</strong>
          <p>{content[currentXAxis].text}</p>
          <Btn onClick={() => navigate(content[currentXAxis].link)}>
            {content[currentXAxis].btn}
          </Btn>
        </div>
      )}
      <div className='heroCardContainer'>
        <Cards
          URL={Simple}
          marginRight={10}
          title='Simple'
          description={
            'Making your tax and corporate compliances simpler and easier than ever'
          }
        />
        <Cards
          URL={Speedy}
          marginRight={10}
          title='Speedy'
          description={
            'Express services provided round the clock, 24/7, 365 days a year.'
          }
        />
        <Cards
          URL={Relible}
          marginRight={10}
          title='Reliable'
          description={
            'An experienced team having cumulative experience of over decades of best client service. '
          }
        />
        {/* <StatusRulesUpdates /> */}
      </div>
    </HeroSectionContainer>
  );
};

export default HeroSection;
