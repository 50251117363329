import React from 'react';
import { IncomeTaxContainer } from '../services-styled';

const IncomeTax = ({ setActiveCategory }) => {
  return (
    <IncomeTaxContainer>
      <div>
        <h1>Income Tax</h1>
        <span>
          Our experts would help you in filing of your tax declarations
          accurately and efficiently and manage all your tax compliances
        </span>
        <a onClick={() => setActiveCategory('Income tax')} href='#service'>
          <img src={require('../../../assets/about-us-icon3.png')} alt='' />
          <div>
            <h3>NTN</h3>
            <p
              style={{
                textAlign: 'justify',
              }}
            >
              Get your National Tax Number generated within minutes
            </p>
          </div>
        </a>
        <a onClick={() => setActiveCategory('Income tax')} href='#service'>
          <img src={require('../../../assets/about-us-icon3.png')} alt='' />
          <div>
            <h3>Tax Return</h3>
            <p
              style={{
                textAlign: 'justify',
              }}
            >
              File your tax return through our simplified portal and become a
              filer without having to leave the comfort of your home
            </p>
          </div>
        </a>
        <a onClick={() => setActiveCategory('Income tax')} href='#service'>
          <img src={require('../../../assets/about-us-icon3.png')} alt='' />
          <div>
            <h3>WithHolding Tax Statement</h3>
            <p
              style={{
                textAlign: 'justify',
              }}
            >
              Tired of remembering different dates! Let a dedicated team take
              the responsibility of managing your periodical statements and go
              hassle free.
            </p>
          </div>
        </a>
      </div>
    </IncomeTaxContainer>
  );
};

export default IncomeTax;
