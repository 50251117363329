import React from 'react';
import {
  Btn,
  Container,
  MainContainer,
} from '../../../global/style/StyleComponent';
import {
  AboutUsImage,
  GridList,
  Heading,
  HowWeWorkListItem,
  SubHeading,
} from '../about-us-styled';
import { useDispatch } from 'react-redux';
import { setAppointment } from '../../../redux-store/home/homeReducer';

const HowWeWork = () => {
  const dispatch = useDispatch();
  return (
    <MainContainer id='how-we-work' bgColor='#373f68' paddingRight={'0px'}>
      <Container>
        <Container
          marginRight={'50px'}
          direction={'column'}
          color='#fff'
          justifyContent={'center'}
        >
          <SubHeading
            style={{
              fontSize: '2em',
              fontWeight: 'bolder',
            }}
          >
            How we Work
          </SubHeading>
          <Heading id='company-history-heading'>
            Leader in Tax Advisory & Financial Consulting.
          </Heading>
          <span
            style={{
              fontSize: '1.2em',
              textAlign: 'justify',
            }}
          >
            We are an association of Accountants, Lawyers and other
            professionals engaged in provision of services encompassing Business
            Advisory, Taxation, Corporate Affairs and other financial and legal
            services under a single umbrella.
            <br />
            <br />
            Our clients deserve only the best, and we deliver nothing less. We
            place a premium on client relationships, building a high level of
            trust and commitment to your success. Our clients look on us as a
            valued resource for advice, ideas, and solutions. If we are chosen
            to partner with you, we will use all of our resources to help you
            succeed.
          </span>
          {/* <GridList>
            <HowWeWorkListItem>
              <img src={require('../../../assets/about-us-icon1.png')} alt='' />
              <strong>Ensure Security</strong>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim
                quidem, asperiores totam nemo ducimus.
              </span>
            </HowWeWorkListItem>
            <HowWeWorkListItem>
              <img src={require('../../../assets/about-us-icon2.png')} alt='' />
              <strong>Highly Dedicated</strong>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim
                quidem, asperiores totam nemo ducimus.
              </span>
            </HowWeWorkListItem>
          </GridList> */}
          <Btn
            id='how-we-work-btn'
            marginTop='20px'
            textColor='#000'
            onClick={() => dispatch(setAppointment())}
          >
            Free Consultation
          </Btn>
        </Container>
        <AboutUsImage
          src={require('../../../assets/about-us-section.png')}
          alt=''
        />
      </Container>
    </MainContainer>
  );
};

export default HowWeWork;
