import React, { useEffect } from 'react';
import './PaymentPage.css';
import NtnContainer from '../NtnContainer';
import { NtnMainContainer } from '../components/style/styleNtn';

const PaymentPage = () => {
  return (
    <NtnContainer>
      <NtnMainContainer>
        <div className='payment-container'>
          <h1>Payment Page</h1>
          <form className='payment-form'>
            <div className='form-group'>
              <label htmlFor='card-number'>Card Number</label>
              <input
                type='text'
                id='card-number'
                placeholder='Enter your card number'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='card-name'>Cardholder Name</label>
              <input
                type='text'
                id='card-name'
                placeholder="Enter the cardholder's name"
              />
            </div>
            <div className='form-group'>
              <label htmlFor='expiry-date'>Expiry Date</label>
              <input type='text' id='expiry-date' placeholder='MM/YY' />
            </div>
            <div className='form-group'>
              <label htmlFor='cvv'>CVV</label>
              <input type='text' id='cvv' placeholder='Enter the CVV' />
            </div>
            <button type='submit'>Pay Now</button>
          </form>
        </div>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default PaymentPage;
