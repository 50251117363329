import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Blogs.css';
import BlogsCard from './components/BlogsCard';
import Slider from 'react-slick';
import { MainContainer } from '../../../../global/style/StyleComponent';
import { useSelector, useDispatch } from 'react-redux';
import { getAllBlog } from '../../../../redux-store/blogs/actionCreator';

const Blogs = () => {
  const navigator = useNavigate();
  const blog = useSelector((state) => state.blog.blogs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBlog());
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer
      alignItem='center'
      className='blogsContainer'
      bgColor='#373f68'
      paddingVertical={'50px'}
    >
      <strong>Blogs</strong>
      <hr />

      {blog !== undefined && blog.length > 0 && (
        <div className='blogsCardContainer'>
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={blog.length > 2 ? 3 : 2}
            slidesToScroll={1}
            className='sliderTeam'
            responsive={[
              {
                breakpoint: 1100,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 830,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerMode: true,
                },
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerMode: false,
                },
              },
            ]}
          >
            {blog.slice(0, 6).map((item, index) => (
              <BlogsCard
                key={index}
                title={item.title}
                date={item.date}
                description={item.short_paragraph}
                img={item.file}
                onClick={() => {
                  navigator('/blogs');
                }}
              />
            ))}
          </Slider>
        </div>
      )}
    </MainContainer>
  );
};

export default Blogs;
