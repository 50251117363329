import React from 'react';
import './CustomerSays.css';

const CustomerSays = () => {
  return (
    <div className='customerSaysContainer'>
      <strong>What our Customers Says</strong>
      <hr />
      <p>
        At Taxpress, we are driven by our very idea “Think Different". We try
        hard to make experience of our every client unique. Clients are never
        identical, therefore we leave no stone unturned to tailor our solutions
        to meet your needs. Regardless if it’s getting your company
        incorporated, filing of your returns, business modelling or creation of
        process flows. Our goal is deliver every service with simplicity, speed
        and reliability.
        <br />
        <br />
        We are a customer first company with great focus on quality and customer
        satisfaction.
      </p>
    </div>
  );
};

export default CustomerSays;
