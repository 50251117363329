import React from 'react';
import './ContactHeroCards.css';

const ContactHeroCards = ({ icon, heading, text, url }) => {
  return (
    <div className='contactHeroCardContainer'>
      <div>
        <img src={icon} alt='' />
      </div>
      <strong>{heading}</strong>
      {text.map((item, i) => (
        <span key={i}>{item}</span>
      ))}
      <a href={url} target='_blank'>
        Visit Now
      </a>
    </div>
  );
};

export default ContactHeroCards;
