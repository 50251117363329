import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeroSections from '../../global/components/hero-section/HeroSections';
import ServicesAndFee from '../../global/components/service-and-fee/ServicesAndFee';
import { Btn, MainContainer } from '../../global/style/StyleComponent';
import {
  getNormalServices,
  getNormalServicesCategory,
} from '../../redux-store/services/actionCreator';
import ConsultAnExpert from './components/ConsultAnExpert';
import IncomeTax from './components/IncomeTax';
import RegistrationServices from './components/RegistrationServices';
import SalesTaxService from './components/SalesTaxService';
import './Services.css';

const Services = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const categories = useSelector((state) => state.services.normalCategories);
  const normalService = useSelector((state) => state.services.normalServices);
  const [activeCategory, setActiveCategory] = useState('All');
  useEffect(() => {
    dispatch(getNormalServicesCategory());
    dispatch(getNormalServices(activeCategory));
  }, [activeCategory, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSections pageName={'Services'} />
      <RegistrationServices setActiveCategory={setActiveCategory} />
      <IncomeTax setActiveCategory={setActiveCategory} />
      <SalesTaxService />
      <ConsultAnExpert />
      <MainContainer bgColor='#fcc028'>
        <div className='topBanner'>
          <strong
            style={{
              color: '#000',
            }}
          >
            Calculate Your Tax Free
          </strong>
          <Btn
            color='#194173'
            textColor='#fff'
            hoverColor='#fcc028'
            hoverTextColor='#000'
            onClick={() => {
              navigator('/salary-tax-calculator');
            }}
          >
            Explore
          </Btn>
        </div>
      </MainContainer>
      <ServicesAndFee
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        categories={categories}
        data={normalService}
      />
    </>
  );
};

export default Services;
