import React from 'react';
import { Btn, LoginRegistrationContainer } from '../../../style/StyleComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton } from '@mui/material';

const ThanksPopup = ({ setThanks }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <LoginRegistrationContainer width={'30%'}>
        <div
          style={{
            display: 'inherit',
            flexDirection: 'inherit',
            alignItems: 'center',
            gap: '1em',
          }}
        >
          <span
            style={{
              fontSize: '35px',
            }}
          >
            Team Taxpress will <br /> contact you shortly
          </span>
          <div
            style={{
              color: '#3be23b',
              display: 'flex',
              gap: '1em',
              alignItems: 'center',
            }}
          >
            <CheckCircleIcon />
            <span
              style={{
                color: '#3be23b',
                fontWeight: 600,
              }}
            >
              Submit successfully
            </span>
          </div>
          <h1
            style={{
              fontSize: '45px',
              margin: 0,
            }}
          >
            THANK YOU
          </h1>
          <Btn
            marginTop={'20px'}
            id='login-btn'
            onClick={() => {
              setThanks(false);
            }}
            fontSize='18px'
          >
            Close
          </Btn>
        </div>
      </LoginRegistrationContainer>
    </div>
  );
};

export default ThanksPopup;
