import React from 'react';
import { useNavigate } from 'react-router-dom';

const Cards = ({ ImageComponent, text, route }) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(route);
  };
  return (
    <div onClick={onClickHandler} className='aboutCategoryCardRotate'>
      <div>
        <ImageComponent />
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Cards;
