import React, { useEffect, useState } from "react";
import AxiosCall from "../../../api/AxiosCall";
import NtnContainer from "../NtnContainer";
import { NtnMainContainer } from "../components/style/styleNtn";
import { useDispatch, useSelector } from "react-redux";
import { Btn } from "../../../global/style/StyleComponent";
import { useNavigate } from "react-router-dom";
import { getAddToCart } from "../../../redux-store/user/actionCreator";
import { Delete, Download, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { removeNtn } from "../../../services/NTNServices";

const AddToCart = () => {
  const navigate = useNavigate();
  const addToCart = useSelector((state) => state.users.addToCart);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAddToCart(user));
  }, []);
  return (
    <NtnContainer>
      <NtnMainContainer>
        <h1>Add To Cart</h1>
        {addToCart.map((item, index) => {
          try {
            const detail = JSON.parse(item.ntnDetails);
            return (
              <>
                <div
                  key={index}
                  style={{
                    boxShadow: "5px 5px 5px  #eeee",
                    borderRadius: "10px",
                    border: "1px solid #eeee",
                    padding: "1em 2em",
                    position: "relative",
                  }}
                >
                  <h3>{item?.ntnTitle}</h3>
                  {Object.keys(detail).map((item) => {
                    console.log(item);
                    return typeof detail[item] === "object" ? (
                      Object.values(detail[item]).every((i) => i) && (
                        <p>
                          <b>
                            {`${item[0].toUpperCase()}${item
                              .slice(1, item.length)
                              .replace(/([A-Z])/g, " $1")}`}
                          </b>
                          :{" "}
                          {Array.isArray(detail[item]) ? (
                            <ol>
                              {detail[item].map((x, idx) => {
                                return (
                                  <div key={idx}>
                                    <li>
                                      {Object.keys(x).map((i, idx) => {
                                        return (
                                          <p
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                            key={idx}
                                          >
                                            <b>
                                              {`${i[0].toUpperCase()}${i
                                                .slice(1, i.length)
                                                .replace(
                                                  /([A-Z])/g,
                                                  " $1"
                                                )}`}{" "}
                                              :
                                            </b>
                                            <span> {x[i]}</span>
                                          </p>
                                        );
                                      })}
                                    </li>
                                  </div>
                                );
                              })}
                            </ol>
                          ) : (
                            Object.keys(detail[item]).map((i, idx) => {
                              return (
                                <p
                                  style={{
                                    marginLeft: "3em",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  key={idx}
                                >
                                  <b>
                                    {`${i[0].toUpperCase()}${i
                                      .slice(1, i.length)
                                      .replace(/([A-Z])/g, " $1")}`}
                                    :
                                  </b>
                                  <span>{detail[item][i]}</span>
                                </p>
                              );
                            })
                          )}
                        </p>
                      )
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <b>
                          {`${item[0].toUpperCase()}${item
                            .slice(1, item.length)
                            .replace(/([A-Z])/g, " $1")}`}
                          :
                        </b>
                        <span>{detail[item]}</span>
                      </p>
                    );
                  })}
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        dispatch(removeNtn(item.id, user));
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "50px",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        navigate(`${item.route}?id=${item.id}`);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "80px",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        const uri = `${process.env.REACT_APP_FILE_DOMAIN}${item.submitFile}`;
                        const name = uri.split("/").slice(-1)[0];
                        var link = document.createElement("a");
                        link.download = name;
                        link.target = "_blank";
                        link.href = uri;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      <Download />
                    </IconButton>
                  </div>
                </div>
                <Btn
                  alignSelf={"center"}
                  onClick={() => {
                    navigate("/tax-calculator");
                  }}
                >
                  Proceed
                </Btn>
              </>
            );
          } catch (e) {
            console.log(e);
            return <span>{e.toString()}</span>;
          }
        })}

        {/* <Btn
          alignSelf={"center"}
          onClick={() => {
            navigate("/tax-calculator");
          }}
        >
          Proceed
        </Btn> */}
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default AddToCart;
