import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  MainContainer,
} from "../../../../global/style/StyleComponent";
import {
  LibraryCardsContainer,
  LibraryListCard,
  LibraryListsContainer,
  ResearchContainer,
  ResearchHeading,
} from "../../../../pages/research-add-insight/ResearchAndInsightStyle";
import { getDashboard } from "../../../../redux-store/library/actionCreator";
import { ReactComponent as file } from "../../../../assets/fileicon.svg";
import Card from "./components/Card";

const Dashboard = () => {
  const data = useSelector((state) => state.library.dashboard);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const dispatch = useDispatch();
  function DownloadFile(fileName) {
    fetch(`${process.env.REACT_APP_FILE_DOMAIN}${fileName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "taxpress.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  }
  useEffect(() => {
    dispatch(getDashboard());
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);
  const user = useSelector((state) => state.users.user);

  const onCheckLogin = (link) => {
    if (!isLoggedIn || user.user_role !== "library") {
      navigate("/login");
    } else {
      if (typeof link === "string") DownloadFile(link);
    }
  };

  function DownloadPdf(fileData) {
    // console.log("on click py mil rha hoon", fileData);
    // const encodedFileData = encodeURIComponent(fileData);
    // console.log(encodedFileData);
    navigate("/library/pdf-generater", {
      state: {
        // ...values
        fileData: fileData,
      },
    });

    // fetch(`${fileName}`)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = "taxpress.pdf";
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   });
  }
  

  function DownloadFilePdf(fileData) {
    // console.log("on click py mil rha hoon", fileData);
    // const encodedFileData = encodeURIComponent(fileData);
    // console.log(encodedFileData);
    navigate("/library/pdf-generater", {
      state: {
        // ...values
        fileData: fileData,
      },
    });

    // fetch(`${fileName}`)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = "file.pdf";
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   });
  }
  return (
    <ResearchContainer>
      <MainContainer
        id="libraryDashboardHeader"
        paddingVertical={"4em"}
        alignItem={"center"}
        flexDirection={"row"}
      >
        <Container
          id="countHeading"
          direction={"column"}
          flex={"1"}
          justifyContent={"center"}
          marginRight={"1em"}
        >
          <ResearchHeading>Our Vast Range of Library</ResearchHeading>
        </Container>
        <Container direction={"column"} flex={"1.5"} justifyContent={"none"}>
          <LibraryCardsContainer id="libraryCardContainer" gap="2em">
            <Card
              Icon={file}
              title={"Total Cases"}
              count={data?.counts !== undefined && data?.counts.caseLaws}
            />
            <Card
              Icon={file}
              title={"Statues"}
              count={data?.counts !== undefined && data?.counts.statutes}
            />
            <Card
              Icon={file}
              title={"Notification"}
              count={data?.counts !== undefined && data?.counts.notifications}
            />
            <Card
              Icon={file}
              title={"Dictionary"}
              count={data?.counts !== undefined && data?.counts.dictionaries}
            />
          </LibraryCardsContainer>
        </Container>
      </MainContainer>
      <MainContainer paddingVertical={"2em"}>
        <LibraryListsContainer>
          <LibraryListCard>
            <strong>Case Laws</strong>
            {data !== undefined &&
              data.caseLaws !== undefined &&
              data?.caseLaws.map((item) => (
                // <div
                //   onClick={() => {
                //     console.log("Mil to rhi hy file", item);
                //     DownloadFilePdf(item);
                //   }}
                //   key={item.id}
                // >
                  <div onClick={() => DownloadPdf(item)} key={item.id}>
                  <strong
                    style={{
                      margin: 0,
                    }}
                  >
                    {item?.id}
                  </strong>
                  <p
                    style={{
                      margin: 0,
                      marginLeft: "50px ",
                    }}
                  >
                    {item?.principleOfCaseLaws}
                  </p>
                  {/* </div> */}
                </div>
              ))}
          </LibraryListCard>
          <LibraryListCard>
            <strong>Statutes</strong>
            {data !== undefined &&
              data.statutes !== undefined &&
              data?.statutes.map((item) => (
                <div onClick={() => onCheckLogin(item.file)} key={item.id}>
                  <span>{item.law_or_statute}</span>
                </div>
              ))}
          </LibraryListCard>
          <LibraryListCard>
            <strong>Notification</strong>
            {data !== undefined &&
              data.notifications !== undefined &&
              data?.notifications.map(
                (item) =>
                  item.subject && (
                    <div onClick={() => onCheckLogin(item.file)} key={item.id}>
                      <span>{item.subject}</span>
                    </div>
                  )
              )}
          </LibraryListCard>
        </LibraryListsContainer>
      </MainContainer>
    </ResearchContainer>
  );
};

export default Dashboard;
