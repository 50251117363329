import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import AxiosCall from '../../../api/AxiosCall';
import { NtnMainContainer } from '../components/style/styleNtn';
import NtnContainer from '../NtnContainer';
import MaterialReactTable from 'material-react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';

const NtnRecord = () => {
  const [ntnRecord, setNtnRecord] = useState([]);
  const user = useSelector((state) => state.users.user);
  useEffect(() => {
    AxiosCall.get(`user/ntn/${user.id}?status=paid`).then((res) => {
      setNtnRecord(
        res.data.data
          .map((item) => ({
            ...item,
            status: item.status ? 'Paid' : 'Pending',
          }))
          .sort((a, b) => b.id - a.id)
      );
    });
  }, [user.id]);

  function downloadAll(files) {
    if (files.length == 0) return;
    const file = files.pop();
    var theAnchor = document.createElement('a');
    theAnchor.href = file;
    theAnchor.target = '_blank';
    let temp = file.split('/');
    theAnchor.download = temp[temp.length - 1];
    console.log(theAnchor);
    theAnchor.click();
    theAnchor.remove();
    downloadAll(files);
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id', //access nested data with dot notation
        header: '#',
        size: 50,
      },
      {
        accessorKey: 'ntnTitle', //access nested data with dot notation
        header: 'Title',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        accessorKey: 'ntnDetails', //normal accessorKey
        header: 'Ntn Details',
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            {Object.keys(JSON.parse(cell.getValue())).map((x) => {
              return (
                <p style={{ margin: 0 }}>
                  <b>{x.toUpperCase()}</b>:{' '}
                  {Array.isArray(JSON.parse(cell.getValue())[x])
                    ? JSON.parse(cell.getValue())[x].map((z) =>
                        Object.keys(z).map((y) => (
                          <p style={{ margin: '0 0 0px 15px' }}>
                            <b>{y?.toUpperCase()}</b>:{z[y]}
                          </p>
                        ))
                      )
                    : typeof JSON.parse(cell.getValue())[x] == 'object'
                    ? Object.keys(JSON.parse(cell.getValue())[x]).map((y) => (
                        <p style={{ margin: '0 0 0px 15px' }}>
                          <b>{y?.toUpperCase()}</b>:
                          {JSON.parse(cell.getValue())[x][y]}
                        </p>
                      ))
                    : JSON.parse(cell.getValue())[x]}
                </p>
              );
            })}
          </Box>
        ),
        //
      },
      {
        accessorKey: 'submitFile',
        header: 'Submit File',
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {cell.getValue() ? (
              <Button
                variant='contained'
                onClick={() => {
                  downloadAll(
                    cell
                      .getValue()
                      .split(',')
                      .map((i) => `https://api.taxpress.com.pk/${i}`)
                  );
                }}
              >
                Download
              </Button>
            ) : (
              '------'
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'responseFile',
        header: 'Response File',
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {cell.getValue() ? (
              <Button
                variant='contained'
                onClick={() => {
                  downloadAll(
                    cell
                      .getValue()
                      .split(',')
                      .map((i) => `https://api.taxpress.com.pk/${i}`)
                  );
                }}
              >
                Download
              </Button>
            ) : (
              '------'
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'detailSubmitTime',
        header: 'Detail Submit Time',
        Cell: ({ cell, row }) => (
          <b>{new Date(cell.getValue()).toLocaleString()}</b>
        ),
      },
      {
        accessorKey: 'responseFileTime',
        header: 'Response File Time',
        Cell: ({ cell, row }) => (
          <b>{new Date(cell.getValue()).toLocaleString()}</b>
        ),
      },
    ],
    [downloadAll]
  );
  return (
    <NtnContainer>
      <NtnMainContainer>
        <MaterialReactTable
          columns={columns}
          data={ntnRecord}
          enablePagination
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10],
            showFirstLastPageButtons: false,
          }}
        />
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default NtnRecord;
