import React, { useEffect } from 'react';
import { SectionHeroContainer } from '../../style/StyleComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getBackground } from '../../../redux-store/home/actionCreator';

const HeroSections = ({ pageName, children }) => {
  const dispatch = useDispatch();
  const background = useSelector((state) => state.home.background);
  useEffect(() => {
    dispatch(getBackground());
  }, []);

  return (
    <SectionHeroContainer
      bgImage={
        background.length > 0 &&
        background.find(
          (i) => i.path.toLowerCase() === pageName.toLowerCase()
        ) !== undefined
          ? `https://api.taxpress.com.pk/${
              background.find(
                (i) => i.path.toLowerCase() === pageName.toLowerCase()
              ).backgrounds[0].filePath
            }`
          : require('../../../assets/contactbackground.png')
      }
    >
      <strong>{pageName}</strong>
      <span>
        Home {'>'} {pageName}
      </span>
      {children}
    </SectionHeroContainer>
  );
};

export default HeroSections;
