import React from 'react';
import './BlogCard.css';

const SideBar = ({ left, right, top }) => {
  return (
    <div
      style={{
        left: left ? '0' : 'auto',
        right: right ? '0' : 'auto',
        top: top ? '0' : '30%',
      }}
      className='sideBarContainer'
    >
      <div className='topBar' />
      <div className='bottomBar' />
    </div>
  );
};

export default SideBar;
