import React from 'react';
import { CounterItem } from '../about-us-styled';
import CountUp from 'react-countup';

const Counter = ({ title, end }) => {
  return (
    <CountUp start={0} end={end} delay={0}>
      {({ countUpRef }) => (
        <CounterItem>
          <strong>
            <span ref={countUpRef} />+
          </strong>
          <hr />
          <span>{title}</span>
        </CounterItem>
      )}
    </CountUp>
  );
};

export default Counter;
