import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../../global/toast';
import { submitNTNFile } from '../../../services/NTNServices';
import NtnContainer from '../NtnContainer';
import {
  NtnAttachBtn,
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';

const initStat = {
  name: '',
  email: '',
  phoneNumber: '',
};

const PersonalNTN = () => {
  const user = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const [data, setData] = useState(initStat);
  const [error, setError] = useState(initStat);
  const [file, setFile] = useState({
    backOfCnic: null,
    frontOfCnic: null,
  });

  const dispatch = useDispatch();
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Personal NTN</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            if (
              Object.values(data).every((i) => i) &&
              Object.values(file).every((i) => i)
            ) {
              dispatch(
                submitNTNFile(
                  data,
                  file,
                  setData,
                  setFile,
                  user,
                  initStat,
                  'Personal NTN',
                  true
                )
              );
            } else {
              errorToast('Please provide all information');
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
            }}
          >
            <span>Generate your ntn within minutes</span>
            <NtnFieldContainer>
              <NtnFormLabel required style={{ width: '200px' }} htmlFor='name'>
                1. Name
              </NtnFormLabel>
              <NtnFormInput
                error={error.name}
                required
                width='350px'
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required style={{ width: '200px' }} htmlFor='name'>
                2. Email
              </NtnFormLabel>
              <NtnFormInput
                required
                error={error.name}
                type='email'
                width='350px'
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required style={{ width: '200px' }} htmlFor='name'>
                3. Phone number
              </NtnFormLabel>
              <NtnFormInput
                required
                error={error.name}
                type='number'
                width='350px'
                value={data.phoneNumber}
                onChange={(e) => {
                  setData({ ...data, phoneNumber: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required style={{}} htmlFor='name'>
                4. Upload your CNIC front side picture
              </NtnFormLabel>
              <input
                required
                type='file'
                id='file1'
                hidden
                onChange={(e) => {
                  setFile({
                    ...file,
                    frontOfCnic: e.target.files[0],
                  });
                }}
              />
              <NtnFormLabel
                style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
                htmlFor='file1'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{file?.frontOfCnic?.name}</p>
              </NtnFormLabel>
            </NtnFieldContainer>{' '}
            <NtnFieldContainer>
              <NtnFormLabel
                required
                style={{
                  width: '200px',
                }}
                htmlFor='name'
              >
                5. Upload your CNIC Back Side picture
              </NtnFormLabel>
              <input
                required
                type='file'
                id='file2'
                hidden
                onChange={(e) => {
                  setFile({
                    ...file,
                    backOfCnic: e.target.files[0],
                  });
                }}
              />
              <NtnFormLabel
                style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
                htmlFor='file2'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{file?.backOfCnic?.name}</p>
              </NtnFormLabel>
            </NtnFieldContainer>
          </div>
          <NtnSubmitBtn type='submit'>Submit</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default PersonalNTN;
