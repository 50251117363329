import React from 'react';
import './CustomSlider.css';

const CustomSlider = ({ children, innerRef }) => {
  return (
    <div className='sliderContainer'>
      <div ref={innerRef} className='sliderInner'>
        {children}
      </div>
    </div>
  );
};

export default CustomSlider;
