import { Box, Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NtnContentContainer,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
} from '../components/style/styleNtn';
import NtnContainer from '../NtnContainer';
import { FileYourTaxReturnList } from '../utils/fileYourTaxReturnList';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: '#373f68',
  boxShadow: 24,
  p: 4,
  color: '#fff',
  borderRadius: '9px',
};

const initState = {
  status: false,
  type: '',
};

const FileYourTaxReturn = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(initState);
  return (
    <NtnContainer>
      <Modal open={open.status} onClose={() => setOpen(initState)}>
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            textTransform={'capitalize'}
          >
            {open.type.replace('_', ' ')}
          </Typography>
          <Box
            component={'ul'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
              padding: 0,
            }}
          >
            {FileYourTaxReturnList[open?.type]?.map(
              ({ title, icon, route }, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    gap: '30px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    paddingBottom: '10px',
                    borderBottom: '2px solid #7c92d7',
                  }}
                  onClick={() => navigate(route)}
                >
                  <img
                    src={icon}
                    alt=''
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'contain',
                    }}
                  />
                  <li
                    style={{
                      flex: 3,
                    }}
                  >
                    {title}
                  </li>
                </div>
              )
            )}
          </Box>
        </Box>
      </Modal>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>File Your Tax Return </NtnSubHeader>
        <span>File your tax return within minutes</span>
        <NtnContentContainer padding='2em 0'>
          <Grid
            sx={{
              width: '60%',
              alignSelf: 'center',
            }}
            container
            spacing={'2em'}
          >
            <Grid item xs='6'>
              <div
                style={{
                  boxShadow: '0px 0px 10px #d3d3d3',
                  padding: '1.4em 1em',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '1em',
                }}
              >
                <h3
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Salary
                </h3>
                <img
                  src={require('../../../assets/ntn/02.png')}
                  alt=''
                  height='200px'
                  style={{
                    marginBottom: '20px',
                  }}
                />
                <div
                  style={{
                    width: '80%',
                    padding: '10px',
                    background: '#fcc028',
                    borderRadius: '10px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  role={'button'}
                  onClick={() => {
                    navigate('/salaryFile');
                  }}
                >
                  Open
                </div>
              </div>
            </Grid>
            <Grid item xs='6'>
              <div
                style={{
                  boxShadow: '0px 0px 10px #d3d3d3',
                  padding: '1.4em 1em',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '1em',
                }}
              >
                <h3
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Business
                </h3>

                <img
                  src={require('../../../assets/ntn/03.png')}
                  alt=''
                  height='200px'
                  style={{
                    marginBottom: '20px',
                  }}
                />
                <div
                  style={{
                    width: '80%',
                    padding: '10px',
                    background: '#fcc028',
                    borderRadius: '10px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  role={'button'}
                  onClick={() => {
                    setOpen({
                      status: true,
                      type: 'business',
                    });
                    // navigate('/businessCompany/company');
                  }}
                >
                  Open
                </div>
              </div>
            </Grid>
            <Grid item xs='6'>
              <div
                style={{
                  boxShadow: '0px 0px 10px #d3d3d3',
                  padding: '1.4em 1em',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '1em',
                }}
              >
                <h3
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Other sources
                </h3>

                <img
                  src={require('../../../assets/ntn/04.png')}
                  alt=''
                  height='200px'
                  style={{
                    marginBottom: '20px',
                  }}
                />
                <div
                  style={{
                    width: '80%',
                    padding: '10px',
                    background: '#fcc028',
                    borderRadius: '10px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  role={'button'}
                  onClick={() => {
                    setOpen({
                      status: true,
                      type: 'other_source',
                    });
                  }}
                >
                  Open
                </div>
              </div>
            </Grid>
            <Grid item xs='6'>
              <div
                style={{
                  boxShadow: '0px 0px 10px #d3d3d3',
                  padding: '1.4em 1em',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '1em',
                }}
              >
                <h3
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Agriculture
                </h3>

                <img
                  src={require('../../../assets/ntn/Asset 1.png')}
                  alt=''
                  height='200px'
                  style={{
                    marginBottom: '20px',
                  }}
                />
                <div
                  style={{
                    width: '80%',
                    padding: '10px',
                    background: '#fcc028',
                    borderRadius: '10px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  role={'button'}
                  onClick={() => {
                    navigate('/agricultural');
                  }}
                >
                  Open
                </div>
              </div>
            </Grid>
          </Grid>
        </NtnContentContainer>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default FileYourTaxReturn;
