import React from 'react';
import { ListCardContainer } from '../act-ordinace-styled';
import ListItem from './ListItem';

const ListCard = ({ title, array }) => {
  return (
    <ListCardContainer>
      <strong>{title}</strong>

      {array !== undefined && array.length > 0 ? (
        array.map((item, index) => <ListItem data={item} key={index} />)
      ) : (
        <ListItem
          data={{
            detail: 'No data found',
          }}
        />
      )}
    </ListCardContainer>
  );
};

export default ListCard;
