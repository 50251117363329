import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Btn,
  Container,
  ErrorText,
  Input,
  LoginRegistrationContainer,
  MainContainer,
} from '../../global/style/StyleComponent';
import { userLogin } from '../../redux-store/user/actionCreator';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    email: '',
    password: '',
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer
      paddingVertical={'90px'}
      justifyContent={'center'}
      alignItem={'center'}
    >
      <LoginRegistrationContainer role='form'>
        <h2>Login</h2>
        <Input
          id='email'
          label='Email'
          placeholder='Email'
          onChange={(e) => setEmail(e.target.value)}
        />
        <ErrorText>{error.email}</ErrorText>
        <Input
          id='password'
          label='Password'
          type={'password'}
          placeholder='Password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <ErrorText>{error.password}</ErrorText>
        <Container alignItem={true} marginBottom='10px'>
          <Container justifyContent='flex-start' alignItem={true} width='auto'>
            <Input
              id='remember'
              width='auto'
              type={'checkbox'}
              marginBottom='0px'
            />
            <label htmlFor='remember'>Remember me</label>
          </Container>
          <span>Forget Password</span>
        </Container>
        <Btn
          type='submit'
          id='login-btn'
          onClick={() => {
            var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (email === '') {
              setError({ ...error, email: 'Email is required', password: '' });
            } else if (!email.match(mailFormat)) {
              setError({ ...error, email: 'Email is not valid', password: '' });
            } else if (password === '') {
              setError({ email: '', password: 'Password is required' });
            } else {
              setError({ email: '', password: '' });
              dispatch(
                userLogin(
                  {
                    username: email,
                    password: password,
                  },
                  navigate
                )
              );
            }
          }}
          fontSize='18px'
        >
          Login
        </Btn>

        <span>Don't have account yet?</span>
        <Link to='/registration'>Sign Up</Link>
      </LoginRegistrationContainer>
    </MainContainer>
  );
};

export default Login;
