import React from 'react';
import { DashboardCard } from '../DashboardStyled';

const Card = ({ Icon, title, count }) => {
  return (
    <DashboardCard>
      <div>
        <Icon />
      </div>
      <strong>{title}</strong>
      <span>{count}</span>
    </DashboardCard>
  );
};

export default Card;
