import { Container, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import ContactUsForm from '../../global/components/contact-us-form/ContactUsForm';
import HeroSections from '../../global/components/hero-section/HeroSections';
import FaqsAccordine from './FaqsAccordine';

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSections pageName={"Faq's"} />
      <Container sx={{ minHeight: '50vh' }}>
        <Grid container>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={8}>
                <h1>Frequently Asked Questions</h1>
                <span>
                  We're here to help, from getting you started, to long after
                  you file. Let us help you with all your tax filing needs.
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={8}>
            <FaqsAccordine />
          </Grid>
          <Grid item md={4}>
            <ContactUsForm />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Faq;
