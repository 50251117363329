import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { submitNTNFile } from '../../../services/NTNServices';
import NtnContainer from '../NtnContainer';
import {
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';
import { useEffect } from 'react';
import AxiosCall from '../../../api/AxiosCall';

const initStat = {
  income: '',
  taxPayment: '',
  isTaxPayment: false,
};

const Agricultural = () => {
  const user = useSelector((state) => state.users.user);
  const [data, setData] = useState(initStat);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setData(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Agricultural - Income</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            delete data['isTaxPayment'];
            dispatch(
              submitNTNFile(
                data,
                null,
                setData,
                () => {},
                user,
                initStat,
                'Agricultural',
                false,
                navigate
              )
            );
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
            }}
          >
            <NtnFieldContainer>
              <NtnFormLabel htmlFor='name'>
                1. Agricultural - Income
              </NtnFormLabel>
              <NtnFormInput
                width='350px'
                value={data.income}
                onChange={(e) => {
                  setData({ ...data, income: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel htmlFor='name'>
                Agricultural tax payment
              </NtnFormLabel>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1em',
                }}
              >
                <NtnFormInput
                  type='checkbox'
                  style={{
                    width: '45px',
                    height: '45px',
                    margin: '0',
                  }}
                  checked={data.isTaxPayment}
                  onChange={(e) => {
                    setData({
                      ...data,
                      isTaxPayment: e.target.checked,
                    });
                  }}
                />
                <NtnFormInput
                  width='350px'
                  disabled={!data.isTaxPayment}
                  value={data.taxPayment}
                  onChange={(e) => {
                    setData({ ...data, taxPayment: e.target.value });
                  }}
                />
              </div>
            </NtnFieldContainer>
          </div>
          <NtnSubmitBtn type='submit'>Proceed</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default Agricultural;
