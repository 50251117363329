import React, { useRef } from 'react';
import { Container, MainContainer } from '../../../global/style/StyleComponent';
import {
  GridList,
  GridListItem,
  Heading,
  SubHeading,
} from '../about-us-styled';
import aboutUs from '../../../assets/aboutUs.webm';
import YouTube from 'react-youtube';

const CompanayHistory = () => {
  const vdo1ref = useRef();
  return (
    <MainContainer id='company-history' paddingVertical={'90px'}>
      <Container justifyContent='center' alignItem='center'>
        {/* <video
          ref={vdo1ref}
          type='video/mp4'
          src={aboutUs}
          controls
          alt=''
          style={{
            width: '50%',
            aspectRatio: '1/1',
            objectFit: 'contain',
            display: 'block',
          }}
        /> */}
        <YouTube
          id='about-us-img'
          videoId='P2s30tI8GtY'
          opts={{
            playerVars: {
              autoplay: 1,
              controls: 0,
              rel: 0,
              loop: 0,
              fs: 0,
              cc_load_policy: 0,
              iv_load_policy: 3,
              mute: 0,
            },
          }}
        />
        {/* <img
          id='about-us-img'
          src={require('../../../assets/about-us-section.jpg')}
          alt='logo'
        /> */}
        <Container marginLeft={'50px'} direction={'column'}>
          <SubHeading>About Us</SubHeading>
          <Heading id='company-history-heading'>
            Try taxpress & forget the rest
          </Heading>
          <span
            style={{
              fontSize: '1.2em',
              textAlign: 'justify',
            }}
          >
            The very existence of taxpress is based upon the concept of
            combining financial, fiscal and legal expertise of various
            professionals with tech infrastructure with the aim of providing
            their clients, the ease of filing their tax and corporate returns
            and managing their statutory compliances from the comfort of their
            homes, sitting across the globe and round the clock.
            <br />
            <br />
            We are now actively providing services to clients virtually through
            our dedicated online portals and our website. We are supported by
            qualified and semi qualified personnel who provide dedicated express
            services to our clients 24/7 on priority basis.
          </span>
          {/* <GridList>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
            <GridListItem>
              <img src={require('../../../assets/about-us-icon3.png')} alt='' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </span>
            </GridListItem>
          </GridList> */}
        </Container>
      </Container>
    </MainContainer>
  );
};

export default CompanayHistory;
