import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast } from '../../../global/toast';
import { submitNTNFile } from '../../../services/NTNServices';
import {
  NtnAttachBtn,
  NtnFieldContainer,
  NtnForm,
  NtnFormInput,
  NtnFormLabel,
  NtnMainContainer,
  NtnMainHeader,
  NtnPageDescription,
  NtnSubHeader,
  NtnSubmitBtn,
} from '../components/style/styleNtn';
import NtnContainer from '../NtnContainer';
import { useNavigate } from 'react-router-dom';

const initStat = {
  name: '',
  email: '',
  phoneNumber: '',
  natureOfBusiness: '',
};

const IndividualSaleTax = () => {
  const user = useSelector((state) => state.users.user);
  const [data, setData] = useState(initStat);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState({
    backOfCnic: null,
    frontOfCnic: null,
    paidBill: null,
  });
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Individual Sale tax</NtnSubHeader>
        <NtnForm
          onSubmit={(e) => {
            e.preventDefault();
            console.log(
              Object.values(data).every((i) => i),
              Object.values(file).every((i) => i)
            );
            if (
              Object.values(data).every((i) => i) &&
              Object.values(file).every((i) => i)
            ) {
              dispatch(
                submitNTNFile(
                  data,
                  file,
                  setData,
                  setFile,
                  user,
                  initStat,
                  'Individual Sale Tax',
                  true,
                  navigate
                )
              );
            } else {
              errorToast('Please provide all information');
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignCenter: 'center',
              gap: '2em',
            }}
          >
            <span>For Individual - FBR & PRA</span>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                1. Name
              </NtnFormLabel>
              <NtnFormInput
                width='350px'
                required
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                2. Email
              </NtnFormLabel>
              <NtnFormInput
                width='350px'
                required
                type='email'
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                3. Phone number
              </NtnFormLabel>
              <NtnFormInput
                width='350px'
                type='number'
                required
                value={data.phoneNumber}
                onChange={(e) => {
                  setData({ ...data, phoneNumber: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required htmlFor='name'>
                4. Nature of business
              </NtnFormLabel>
              <NtnFormInput
                width='350px'
                required
                type='text'
                value={data.natureOfBusiness}
                onChange={(e) => {
                  setData({ ...data, natureOfBusiness: e.target.value });
                }}
              />
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required style={{}} htmlFor='name'>
                5. Upload your CNIC front side picture
              </NtnFormLabel>
              <input
                type='file'
                id='file1'
                hidden
                onChange={(e) => {
                  setFile({
                    ...file,
                    frontOfCnic: e.target.files[0],
                  });
                }}
              />
              <NtnFormLabel
                style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
                htmlFor='file1'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{file?.frontOfCnic?.name}</p>
              </NtnFormLabel>
            </NtnFieldContainer>{' '}
            <NtnFieldContainer>
              <NtnFormLabel required style={{}} htmlFor='name'>
                6. Upload your CNIC Back Side picture
              </NtnFormLabel>
              <input
                type='file'
                id='file2'
                hidden
                onChange={(e) => {
                  setFile({
                    ...file,
                    backOfCnic: e.target.files[0],
                  });
                }}
              />
              <NtnFormLabel
                style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
                htmlFor='file2'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{file?.backOfCnic?.name}</p>
              </NtnFormLabel>
            </NtnFieldContainer>
            <NtnFieldContainer>
              <NtnFormLabel required style={{}} htmlFor='name'>
                7. Latest paid electricity bill
              </NtnFormLabel>
              <input
                type='file'
                id='file3'
                hidden
                onChange={(e) => {
                  setFile({
                    ...file,
                    paidBill: e.target.files[0],
                  });
                }}
              />
              <NtnFormLabel
                style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
                htmlFor='file3'
              >
                <NtnAttachBtn>Select File</NtnAttachBtn>
                <p>{file?.paidBill?.name}</p>
              </NtnFormLabel>
            </NtnFieldContainer>
          </div>
          <NtnSubmitBtn type='submit'>Submit</NtnSubmitBtn>
        </NtnForm>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default IndividualSaleTax;
