import styled from 'styled-components';

export const DownloadStyled = styled.a`
  text-align: ${({ textAlign }) => textAlign || 'right'};
  cursor: pointer;
  text-decoration: none;
  color: #000;
  &:hover {
    text-decoration: underline;
  }
`;
