import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SideBarItemContainer } from './style/styleNtn';
import SubMenu from './SubMenu';

const SidebarItem = ({ subMenu, path, title }) => {
  const [isExpandable, setIsExpandable] = useState(false);
  return (
    <SideBarItemContainer>
      <div
        onClick={() => {
          setIsExpandable(!isExpandable);
        }}
        className='sidebarItem'
      >
        {subMenu ? <span>{title}</span> : <Link to={`${path}`}>{title}</Link>}
        {subMenu && <KeyboardArrowDownIcon />}
      </div>
      <div>
        {isExpandable &&
          subMenu?.map((item, index) => <SubMenu key={index} item={item} />)}
      </div>
    </SideBarItemContainer>
  );
};

export default SidebarItem;
