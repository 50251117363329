import React from 'react';
import Footer from '../../global/components/footer/Footer';
import TopLevelHeader from '../../global/components/header/TopLevelHeader';
import ContentSection from './components/ContentSection';
import Header from './components/Header';
import { IconButton, Modal } from '@mui/material';
import { ThanksPopup } from '../../global/components/popup/services';
import { useDispatch, useSelector } from 'react-redux';
import { setThankYou } from '../../redux-store/user/userReducer';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const NtnContainer = ({ children }) => {
  const thankYou = useSelector((state) => state.users.thankYou);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Modal open={thankYou}>
        <ThanksPopup
          setThanks={(status) => {
            dispatch(setThankYou(status));
          }}
        />
      </Modal>
      <TopLevelHeader />
      <Header />
      <ContentSection>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            margin: '1em',
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
        </div>

        {children}
      </ContentSection>
      <Footer />
    </>
  );
};

export default NtnContainer;
