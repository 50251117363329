import React from 'react';
import { Btn } from '../../../../global/style/StyleComponent';
import DotGrid from '../../../../assets/dot-grid.png';
import './OverSeas.css';
import { useNavigate } from 'react-router-dom';

const OverSeas = () => {
  const navigate = useNavigate();
  return (
    <div className='overSeasContainer'>
      <div className='overSeasInnerContainer'>
        <div className='overSeasContent'>
          <h1>Over Seas Chapter</h1>
          <strong>
            Special Offer for <br /> overseas Pakistanis
          </strong>
          <p>
            As a Special Offer for Overseas Pakistanis, Taxpress is looking
            forward to serve Pakistani Citizens residing abroad in filing of
            their tax declarations abroad and in their own homeland.
          </p>
          <Btn
            onClick={() => {
              navigate('/overseas-chapter');
            }}
          >
            Visit Now
          </Btn>
        </div>
      </div>
      <img src={DotGrid} alt='' />
    </div>
  );
};

export default OverSeas;
