import React from 'react';
import { MainContainer } from '../../../global/style/StyleComponent';
import ReturnServiceCard from '../../overseas/components/ReturnServiceCard';
import {
  RegistrationServiceContainer,
  RegistrationServiceHead,
  RegistrationServiceService,
} from '../services-styled';
import Slider from 'react-slick';

const RegistrationServices = ({ setActiveCategory }) => {
  return (
    <MainContainer
      id='registration-services'
      paddingVertical='90px'
      justifyContent='center'
      alignItem='center'
    >
      <RegistrationServiceContainer>
        <RegistrationServiceHead>
          <h1>Registration Services</h1>
          <hr />
          <span>
            Liberating people from the burden of being indulged in getting their
            Businesses registered, regularized and compliant, thus freeing their
            time and capital for their businesses to invest, grow and thrive.
          </span>
        </RegistrationServiceHead>
        <div id='registrationServiceCard'>
          <RegistrationServiceService>
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/certificate.png')}
              title='Company Incorporation'
              description='An incorporated business as a company would give any business more credibility among potential customers, vendors, partners and employees along with providing separate legal identity and limited liability to shareholders which is limited to the extent of assets of the company.'
            />
            <hr />
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/partnership.png')}
              title='Partnership Business'
              description='A partnership business is easy to establish and its start-up costs are low. A partnership business is subject to least regulatory compliance and facilitates greater legal protection in disputes between partners and third parties. '
            />
          </RegistrationServiceService>
          <hr
            style={{
              width: '100%',
              border: '1px solid #373f68',
              margin: 0,
            }}
          />
          <RegistrationServiceService>
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/NGO.png')}
              title='Register NGO'
              description='The purpose of an NPO is not to earn profits but work for the benefit of the society. Registration of such entities are subject to fulfillment of numerous regulatory compliances.'
            />
            <hr />
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/TM.png')}
              title='Obtain Trade Mark'
              description='Registering your own trademark for the business provides a legal protection against infringement. No competitor or any other person can use the word, mark or logo registered by you as your trademark. Trademark registration provides huge recognition to the quality of your product and builds your trust in the market.'
            />
          </RegistrationServiceService>
        </div>
        <RegistrationServiceService id='registrationServiceCardMobile'>
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            className='sliderTeam'
            responsive={[
              {
                breakpoint: 1100,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 830,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerMode: true,
                },
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerMode: false,
                },
              },
            ]}
          >
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/certificate.png')}
              title='Company Incorporation'
              description='An incorporated business as a company would give any business more credibility among potential customers, vendors, partners and employees along with providing separate legal identity and limited liability to shareholders which is limited to the extent of assets of the company.'
            />
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/partnership.png')}
              title='Partnership Business'
              description='A partnership business is easy to establish and its start-up costs are low. A partnership business is subject to least regulatory compliance and facilitates greater legal protection in disputes between partners and third parties. '
            />
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/NGO.png')}
              title='Register NGO'
              description='The purpose of an NPO is not to earn profits but work for the benefit of the society. Registration of such entities are subject to fulfillment of numerous regulatory compliances.'
            />
            <ReturnServiceCard
              setActiveCategory={setActiveCategory}
              url={require('../../../assets/TM.png')}
              title='Obtain Trade Mark'
              description='Registering your own trademark for the business provides a legal protection against infringement. No competitor or any other person can use the word, mark or logo registered by you as your trademark. Trademark registration provides huge recognition to the quality of your product and builds your trust in the market.'
            />
          </Slider>
        </RegistrationServiceService>
      </RegistrationServiceContainer>
    </MainContainer>
  );
};

export default RegistrationServices;
