import { combineReducers } from 'redux';
import blogReducer from './blogs/blogReducer';
import homeReducer from './home/homeReducer';
import libraryReducer from './library/libraryReducer';
import ordinaceReducer from './ordinace/ordinaceReducer';
import serviceReducer from './services/serviceReducer';
import usersReducer from './user/userReducer';

const rootReducer = combineReducers({
  users: usersReducer,
  library: libraryReducer,
  ordinance: ordinaceReducer,
  services: serviceReducer,
  home: homeReducer,
  blog: blogReducer,
});
export default rootReducer;
