import AxiosCall from '../../api/AxiosCall';
import {
  setAllNotificationType,
  setAllStatutes,
  setDashboard,
  setSearchedCaseLaw,
  setSearchedDictionary,
  setSearchedNotification,
  setSearchedStatute,
  setTopCase,
} from './libraryReducer';

export const getDashboard = () => (dispatch) => {
  AxiosCall.get('/counts/getCounts')
    .then((res) => {
      if (res.data.success) {
        dispatch(setDashboard(res.data.data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSearchCaseLaw = (search) => (dispatch) => {
  AxiosCall.post('/cases/searchCase', search)
    .then((res) => {
      if (res.data.success) {
        dispatch(setSearchedCaseLaw(res.data.data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSearchNotification = (search) => (dispatch) => {
  console.log(search);
  AxiosCall.post('/notifications/searchNotifications', search)
    .then((res) => {
      if (res.data.success) {
        dispatch(setSearchedNotification(res.data.data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSearchDictionary = (search) => (dispatch) => {
  AxiosCall.post('/dictionary/search', search)
    .then((res) => {
      if (res.data.success) {
        dispatch(setSearchedDictionary(res.data.data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSearchStatutes = (search) => (dispatch) => {
  AxiosCall.post('/statutes/searchStatutes', search)
    .then((res) => {
      if (res.data.success) {
        dispatch(setSearchedStatute(res.data.data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllStatutes = () => (dispatch) => {
  AxiosCall.get('/statutes/getStatutesOnly')
    .then((res) => {
      if (res.data.success) {
        dispatch(setAllStatutes(res.data.data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllPrinciples = (cb) => (dispatch) => {
  AxiosCall.get('/cases/principles')
    .then((res) => {
      if (res.data.success) {
        cb((prev) => ({
          ...prev,
          principles: res.data.data,
        }));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllCourt = (cb) => (dispatch) => {
  AxiosCall.get('/cases/courts')
    .then((res) => {
      if (res.data.success) {
        cb((prev) => ({
          ...prev,
          courts: res.data.data,
        }));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllNotificationType = () => (dispatch) => {
  AxiosCall.get('/notifications/getNotificationTypes')
    .then((res) => {
      if (res.data.success) {
        dispatch(setAllNotificationType(res.data.data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTopCase = () => (dispatch) => {
  AxiosCall.get('/cases/getAllCases').then((res) => {
    if (res.data.success) {
      const data = res.data.data;
      data.sort(function (a, b) {
        return new Date(b.dated) - new Date(a.dated);
      });
      dispatch(setTopCase(data));
    } else {
      console.log(res.data.message);
    }
  });
};
