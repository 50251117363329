import React from 'react';
import NtnContainer from '../NtnContainer';
import {
  NtnMainHeader,
  NtnPageDescription,
  NtnMainContainer,
  NtnSubHeader,
  NtnSubmitBtn,
  NtnAttachBtn,
} from '../components/style/styleNtn';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitNTNFile } from '../../../services/NTNServices';
import AxiosCall from '../../../api/AxiosCall';
import { useEffect } from 'react';

const initStat = {
  annualAccounts: '',
};

const BusinessCompany = () => {
  const user = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(initStat);
  const [file, setFile] = useState();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      AxiosCall.get(`/user/ntn/${user.id}/${id}`)
        .then((res) => {
          if (res.data?.data?.ntnDetails) {
            console.log(JSON.parse(res.data.data.ntnDetails));
            setData(JSON.parse(res.data.data.ntnDetails));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchParams, user.id]);
  return (
    <NtnContainer>
      <NtnMainContainer>
        <NtnMainHeader>Tax Portal</NtnMainHeader>
        <NtnPageDescription>
          Filing of tax return in Pakistan, made easier then ever. File your tax
          declarations with us and enjoy our tax portal’s document uploading and
          storage feature for free.
        </NtnPageDescription>
        <NtnSubHeader>Company</NtnSubHeader>
        <div
          style={{
            display: 'flex',
            alignCenter: 'center',
            gap: '2em',
          }}
        >
          <span>Annual accounts of the company</span>
          <div>
            <input
              type='radio'
              name='account'
              id='accountYes'
              value={'yes'}
              checked={data.annualAccounts === 'yes'}
              onChange={(e) => {
                setData({ ...data, annualAccounts: e.target.value });
              }}
            />
            <label htmlFor='accountYes'>Yes</label>
          </div>
          <div>
            <input
              type='radio'
              name='account'
              id='accountNo'
              value='no'
              checked={data.annualAccounts === 'no'}
              onChange={(e) => {
                setData({ ...data, annualAccounts: e.target.value });
              }}
            />
            <label htmlFor='accountNo'>No</label>
          </div>
          {data.annualAccounts === 'yes' && (
            <div>
              <input
                type='file'
                name='file'
                id='file'
                hidden
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
              <label htmlFor='file'>
                <NtnAttachBtn>Attach certificate</NtnAttachBtn>
                <p>{file?.name}</p>
              </label>
            </div>
          )}
        </div>
        <NtnSubmitBtn
          onClick={() => {
            if (data.annualAccounts && file) {
              dispatch(
                submitNTNFile(
                  data,
                  file,
                  setData,
                  setFile,
                  user,
                  initStat,
                  'Business Company',
                  false,
                  navigate
                )
              );
              setData(initStat);
            }
          }}
        >
          Proceed
        </NtnSubmitBtn>
      </NtnMainContainer>
    </NtnContainer>
  );
};

export default BusinessCompany;
