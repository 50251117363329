import axios from 'axios';
import { errorToast } from '../global/toast';

const AxiosCall = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN,
});

AxiosCall.interceptors.request.use((request) => {
  document.querySelector('.overlay').style.display = 'block';
  return request;
});

AxiosCall.interceptors.response.use(
  (response) => {
    document.querySelector('.overlay').style.display = 'none';
    return response;
  },
  (error) => {
    document.querySelector('.overlay').style.display = 'none';
    errorToast(error.response.data.message);
    return Promise.reject(error);
  }
);

export default AxiosCall;
