import React from 'react';

const Cards = ({ URL, marginLeft, marginRight, title, description }) => {
  return (
    <div
      className='cardHero'
      style={{
        background: '#fff',
        marginLeft: marginLeft,
        marginRight: marginRight,
        padding: '20px 10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        alignSelf: 'end',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
        flex: 1,
        zIndex: 1,
        userSelect: 'none',
      }}
    >
      <div className='cardLogoSvg'>
        <URL />
      </div>
      <h4
        style={{
          margin: '0px',
          marginTop: '10px',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </h4>
      <p
        style={{
          textAlign: 'center',
          height: '3.5rem',
        }}
      >
        {description}
      </p>
    </div>
  );
};

export default Cards;
