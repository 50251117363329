import React from 'react';
import { useDispatch } from 'react-redux';
import { Input, MainContainer } from '../../../../global/style/StyleComponent';
import { getSearchDictionary } from '../../../../redux-store/library/actionCreator';
import {
  SearchButtonContainer,
  SearchContainer,
} from '../../styles/LibraryStyled';
import DictionaryList from './DictionaryList';

const DictionarySearch = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState({
    word: '',
  });
  return (
    <MainContainer paddingVertical={'90px'}>
      <h1>Dictionary</h1>
      <SearchContainer>
        <Input
          type='text'
          placeholder='Enter key word'
          onChange={(e) =>
            setSearch({ ...search, word: e.target.value.trim() })
          }
        />
        <SearchButtonContainer>
          <button
            onClick={() => {
              dispatch(getSearchDictionary(search));
            }}
          >
            Search
          </button>
        </SearchButtonContainer>
      </SearchContainer>
      <DictionaryList />
    </MainContainer>
  );
};

export default DictionarySearch;
