import React from 'react';

const ReturnServiceCard = ({
  setActiveCategory,
  url,
  type,
  title,
  description,
}) => {
  return (
    <a
      onClick={() => {
        setActiveCategory(type ? type : 'registration services');
      }}
      href='#service'
    >
      <img src={url} alt='' />
      <h3>{title}</h3>
      <p>{description}</p>
    </a>
  );
};

export default ReturnServiceCard;
