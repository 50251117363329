import React from 'react';
import { OverSeasPakistanisContainer } from '../overseas-styled';

const OverSeasPakistanis = ({ setActiveCategory }) => {
  return (
    <OverSeasPakistanisContainer>
      <div>
        <h1>Special offer for overseas pakistanis</h1>
        <span>
          As a Special Offer for Overseas Pakistanis, Taxpress is looking
          forward to serve Pakistani Citizens residing abroad in filing of their
          tax declarations abroad and in their own homeland.
        </span>
        <a
          onClick={() => setActiveCategory('overseas services')}
          href='#service'
        >
          <img src={require('../../../assets/about-us-icon3.png')} alt='' />
          <div>
            <h3>Trust us with filing of your Tax declarations</h3>
            <p>
              Whether you are living in USA or UK, we have got you covered. Our
              team of experts is available round the clock to assist you in
              filing of your tax declarations.
            </p>
          </div>
        </a>
        <a
          onClick={() => setActiveCategory('overseas services')}
          href='#service'
        >
          <img src={require('../../../assets/about-us-icon3.png')} alt='' />
          <div>
            <h3>
              Advisory on Wealth Management and Repatriation of Funds in
              Pakistan
            </h3>
            <p>
              While you are busy earning abroad, allow us to take care of your
              investments in your home land. Our team would guide you on the
              most efficient and compliant legal process of repatriation of
              funds for investment in your homeland.
            </p>
          </div>
        </a>
      </div>
    </OverSeasPakistanisContainer>
  );
};

export default OverSeasPakistanis;
