import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboard: {},
  allStatutes: [],
  allNotificationType: [],
  searchedCaseLaw: [],
  searchedStatute: [],
  searchedNotification: [],
  searchedDictionary: [],
  topCase: [],
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setDashboard: (state, action) => {
      state.dashboard = action.payload;
    },
    setSearchedCaseLaw: (state, action) => {
      state.searchedCaseLaw = action.payload;
    },
    setSearchedStatute: (state, action) => {
      state.searchedStatute = action.payload;
    },
    setSearchedNotification: (state, action) => {
      state.searchedNotification = action.payload;
    },
    setSearchedDictionary: (state, action) => {
      state.searchedDictionary = action.payload;
    },
    setAllStatutes: (state, action) => {
      state.allStatutes = action.payload;
    },
    setAllNotificationType: (state, action) => {
      state.allNotificationType = action.payload;
    },
    setTopCase: (state, action) => {
      state.topCase = action.payload;
    },
  },
});
export const {
  setDashboard,
  setSearchedCaseLaw,
  setSearchedStatute,
  setSearchedNotification,
  setSearchedDictionary,
  setAllStatutes,
  setAllNotificationType,
  setTopCase,
} = librarySlice.actions;
export default librarySlice.reducer;
